/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class Salutation extends AbstractEnum {
    /**
     * @type{Map<String,Salutation>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{Salutation[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        Salutation._lookupMap.set(id, this);
        Salutation._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'Salutation';
    }

    /**
     * @param {string} id
     * @returns {Salutation|undefined}
     * @public
     */
    static valueOf(id) {
        return Salutation._lookupMap.get(id);
    }

    /**
     * @returns {Salutation[]}
     * @public
     */
    static values() {
        return [...Salutation._values];
    }

    /**
     * @type{Salutation}
     * @public
     */
    static MALE = new Salutation('male');
    /**
     * @type{Salutation}
     * @public
     */
    static FEMALE = new Salutation('female');
    /**
     * @type{Salutation}
     * @public
     */
    static OTHER = new Salutation('other');
}