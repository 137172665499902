import React from "react";
import DropdownInput from "./DropdownInput";
import {languages} from "../../util/Languages";
import {languageLevels} from "../../util/LanguageLevels";
import {Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import LanguageSkill from "../../models/LanguageSkill";

export default class LanguageSkillInput extends React.Component {

    constructor(props) {
        super(props);

        this.setLanguage = this.setLanguage.bind(this);
        this.setLevel = this.setLevel.bind(this);
        this.addLanguageSkill = this.addLanguageSkill.bind(this);
        this.removeLanguageSkill = this.removeLanguageSkill.bind(this);
    }

    setLanguage(index, answer) {
        let languageSkills = this.props.value;

        languageSkills[index].language = answer;

        this.props.setAnswer(this.props.id, languageSkills);
    }

    setLevel(index, answer) {
        let languageSkills = this.props.value;

        languageSkills[index].level = answer;

        this.props.setAnswer(this.props.id, languageSkills);
    }

    addLanguageSkill() {
        let languageSkills = this.props.value;
        languageSkills.push(new LanguageSkill(null, languages[this.props.value.length].key, languageLevels[0].key, null));
        this.props.setAnswer(this.props.id, languageSkills);
    }

    removeLanguageSkill(index) {
        let languageSkills = this.props.value;
        languageSkills.splice(index, 1);
        this.props.setAnswer(this.props.id, languageSkills);
    }

    renderLanguages() {
        let renderedLanguages = [];
        for (let languageSkillIndex in this.props.value) {
            let languageSkill = this.props.value[languageSkillIndex];
            renderedLanguages.push(
                <div className="d-flex" style={{alignItems: "center"}}>
                    {this.renderRemoveLanguageSkill()}
                    <DropdownInput style={{marginRight: 10}}
                                   setAnswer={(id, answer) => this.setLanguage(id, answer)}
                                   id={languageSkillIndex}
                                   question=""
                                   choices={languages}
                                   value={languageSkill.language}
                                   invalid={this.props.invalid}
                                   required
                    />
                    <DropdownInput style={{width: 80}}
                                   setAnswer={(id, answer) => this.setLevel(id, answer)}
                                   id={languageSkillIndex}
                                   question=""
                                   choices={languageLevels}
                                   value={languageSkill.level}
                                   invalid={this.props.invalid}
                                   required
                    />
                </div>
            );
        }

        return renderedLanguages;
    }

    renderAddLanguageSkill() {
        if (this.props.value.length < languages.length) {
            return (
                <FontAwesomeIcon icon={faPlusCircle}
                                 style={{fontSize: 16, marginBottom: 40}}
                                 onClick={this.addLanguageSkill}/>
            )
        } else {
            return (
                <p style={{fontSize: 16, marginBottom: 40}}>Alle möglichen Sprachen wurden bereits ausgewählt.</p>
            )
        }
    }

    renderRemoveLanguageSkill(index) {
        return <FontAwesomeIcon style={{fontSize: 16, marginRight: 10}}
                                icon={faMinusCircle} onClick={() => this.removeLanguageSkill(index)}/>
    }

    renderRequired() {
        if (this.props.required) return '*';
        return '';
    }

    render() {
        return (
            <div ref={this.props.ref}>
                <Form.Group>
                    <Form.Label style={{fontWeight: 'bold'}}>{this.props.question}{this.renderRequired()}</Form.Label>
                    {this.renderLanguages()}
                </Form.Group>
                {this.renderAddLanguageSkill()}
            </div>
        );
    }
}
