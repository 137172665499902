import React from "react";
import Salutation from "../../util/Salutation";
import PropTypes from "prop-types";
import UserInfo from "../../models/UserInfo";
import {formatBirthDate} from "../../util/helpers";

export default class DashboardLanding extends React.Component {
    /**
     * @returns {UserInfo}
     */
    get userInfo() {
        return this.props.userInfo;
    }

    render() {
        const salutation = Salutation.resolve(this.userInfo.gender);

        return (
            <div>
                <h1 className="headerStyle">Herzlich Willkommen</h1>
                <hr className="hrStyle"/>
                <div className="grayBoxStyle separatorMargin">
                    <p className="m-0" hidden={!salutation}>{salutation}</p>
                    <p className="m-0">{this.userInfo.lastName} {this.userInfo.firstName}</p>
                    <p className="m-0">
                        {this.userInfo.streetAndNr}<br/>
                        {this.userInfo.zipcode} {this.userInfo.city}
                    </p>
                    <p className="m-0">{formatBirthDate(this.userInfo.birthDate)}</p>
                </div>
            </div>
        );
    }
}

DashboardLanding.propTypes = {
    userInfo: PropTypes.instanceOf(UserInfo).isRequired
};