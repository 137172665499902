/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class DocumentType extends AbstractEnum {
    /**
     * @type{Map<String,DocumentType>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{DocumentType[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        DocumentType._lookupMap.set(id, this);
        DocumentType._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'DocumentType';
    }

    /**
     * @param {string} id
     * @returns {DocumentType|undefined}
     * @public
     */
    static valueOf(id) {
        return DocumentType._lookupMap.get(id);
    }

    /**
     * @returns {DocumentType[]}
     * @public
     */
    static values() {
        return [...DocumentType._values];
    }

    /**
     * @type{DocumentType}
     * @public
     */
    static RESUME = new DocumentType('resume');
    /**
     * @type{DocumentType}
     * @public
     */
    static EMPLOYMENT_REFERENCE = new DocumentType('employment-reference');
    /**
     * @type{DocumentType}
     * @public
     */
    static CERTIFICATE = new DocumentType('certificate');
    /**
     * @type{DocumentType}
     * @public
     */
    static UNSPECIFIED = new DocumentType('unspecified');
}