import AbstractModel from "./AbstractModel";

export default class ScoreSuperCategory extends AbstractModel {

    constructor(id, description) {
        super();

        this._id = id;
        this._description = description;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    toJSON() {
        let descriptions = [];
        if (this._description) {
            for (let description of this._description) {
                descriptions.push(description.toJSON())
            }
        }

        return ({
            id: this._id,
            description: descriptions
        })
    }

    /**
     * @returns {ScoreSuperCategory}
     */
    clone() {
        return this._clone(new ScoreSuperCategory());
    }
}
