/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class LanguageLevel extends AbstractEnum {
    /**
     * @type{Map<String,LanguageLevel>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{LanguageLevel[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        LanguageLevel._lookupMap.set(id, this);
        LanguageLevel._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'LanguageLevel';
    }

    /**
     * @param {string} id
     * @returns {LanguageLevel|undefined}
     * @public
     */
    static valueOf(id) {
        return LanguageLevel._lookupMap.get(id);
    }

    /**
     * @returns {LanguageLevel[]}
     * @public
     */
    static values() {
        return [...LanguageLevel._values];
    }

    /**
     * @type{LanguageLevel}
     * @public
     */
    static NATIVE = new LanguageLevel('native');
    /**
     * @type{LanguageLevel}
     * @public
     */
    static A1 = new LanguageLevel('level-A1');
    /**
     * @type{LanguageLevel}
     * @public
     */
    static A2 = new LanguageLevel('level-A2');
    /**
     * @type{LanguageLevel}
     * @public
     */
    static B1 = new LanguageLevel('level-B1');
    /**
     * @type{LanguageLevel}
     * @public
     */
    static B2 = new LanguageLevel('level-B2');
    /**
     * @type{LanguageLevel}
     * @public
     */
    static C1 = new LanguageLevel('level-C1');
    /**
     * @type{LanguageLevel}
     * @public
     */
    static C2 = new LanguageLevel('level-C2');
}