import React from "react";
import {Col, Container, Fade, Row} from "react-bootstrap";
import DashboardMenuCaregiver from "../../components/dashboard/DashboardMenuCaregiver";
import DashboardCaregiverRouter from "../../components/dashboard/DashboardCaregiverRouter";
import DashboardClientRouter from "../../components/dashboard/DashboardClientRouter";
import Role from "../../util/Role";
import ApiService from "../../services/ApiService";
import keycloak from "../../keycloak";

/** @typedef {import('../../models/UserInfo').default} UserInfo */

export default class DashboardView extends React.Component {
    state = {
        userInfo: null
    };

    componentDidMount() {
        ApiService.getProfile(keycloak).then(this._setUserInfo.bind(this));
    }

    /**
     * @param {UserInfo} userInfo
     * @private
     */
    _setUserInfo(userInfo) {
        this.setState({
            userInfo: userInfo
        });
    }

    /**
     * @returns {boolean}
     * @private
     */
    _isCaregiver() {
        return !!keycloak?.hasRealmRole(Role.CAREGIVER);
    }

    /**
     * @returns {boolean}
     * @private
     */
    _isClient() {
        return !!keycloak?.hasRealmRole(Role.CLIENT);
    }

    /**
     * @returns {boolean}
     * @private
     */
    _isAuthorized() {
        return this._isAuthenticated() && (this._isCaregiver() || this._isClient());
    }

    /**
     * @returns {boolean}
     * @private
     */
    _isAuthenticated() {
        return !!keycloak?.authenticated;
    }

    /**
     * @returns {boolean}
     * @private
     */
    _hasUserInfo() {
        return !!this.state.userInfo;
    }

    /**
     * @returns {JSX.Element}
     * @private
     */
    _renderDashboardRouter() {
        switch (true) {
            case !this._hasUserInfo() && this._isAuthenticated():
                return (<></>);
            case this._isCaregiver():
                return (<DashboardCaregiverRouter userInfo={this.state.userInfo}/>);
            case this._isClient():
                return (<DashboardClientRouter userInfo={this.state.userInfo}/>);
            default:
                return this._renderLoginRequired();
        }
    }

    /**
     * @returns {JSX.Element}
     * @private
     */
    _renderDashboard() {
        return (
            <Fade in={!!this.state.userInfo}>
                <Container>
                    <Row>
                        <Col xs={3}>
                            <DashboardMenuCaregiver isCaregiver={this._isCaregiver()}/>
                        </Col>
                        <Col>
                            {this._renderDashboardRouter()}
                        </Col>
                    </Row>
                </Container>
            </Fade>
        );
    }

    /**
     * @returns {JSX.Element}
     * @private
     */
    _renderAccessDenied() {
        return (
            <Container>
                <Row>
                    <Col>
                        <h2>Zugriff verweigert</h2>
                        <p>Sie sind eingeloggt, verfügen aber nicht über die notwendigen Berechtigungen.</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    /**
     * @returns {JSX.Element}
     * @private
     */
    _renderLoginRequired() {
        keycloak.login();

        return (<></>);
    }

    render() {
        switch (true) {
            case this._isAuthorized():
                return this._renderDashboard();
            case this._isAuthenticated():
                return this._renderAccessDenied();
            default:
                return this._renderLoginRequired();
        }
    }
}