import i18next from 'i18next';
import * as yup from 'yup';
import {isValidPhoneNumber} from 'react-phone-number-input';
import {electronicFormatIBAN, isValidIBAN} from 'ibantools';

/**
 * @param {string} field
 * @param {string} validation
 * @returns {string}
 * @private
 */
const _t = (field, validation) => i18next.t([`form.${field}.${validation}`, `form.validation.${validation}`, `form.validation.required`]);

export const alwaysValid = () => void (0);

/**
 * @param {string} value
 * @param {string} field
 * @returns {string|undefined}
 */
export const notBlank = (value, field) => `${value ?? ''}`.trim().length === 0 ? _t(field, 'notBlank') : undefined;

/**
 * @param {string} value
 * @param {string} field
 * @returns {string|undefined}
 */
export const notEmpty = (value, field) => !value ? _t(field, 'notEmpty') : undefined;

/**
 * @param {string} value
 * @param {string} field
 * @returns {string|undefined}
 */
export const validPhoneNumber = (value, field) => !!value && !isValidPhoneNumber(value) ? _t(field, 'invalidPhoneNumber') : undefined;

/**
 * @param {string} value
 * @param {string} field
 * @returns {string|undefined}
 */
export const emptyOrValidPhoneNumber = (value, field) => !!value && !isValidPhoneNumber(value) ? _t(field, 'invalidPhoneNumber') : undefined;

/**
 * @param {string} value
 * @param {string} field
 * @returns {string|undefined}
 */
export const validEmail = (value, field) => !yup.string().email().isValidSync(value) ? _t(field, 'invalidEmailAddress') : undefined;

/**
 * @param {string} value
 * @returns {string}
 * @private
 */
const _calculateSocialSecurityNumberChecksum = (value) => {
    const parts = value.split('');
    let checksum = 0;
    for (let i = 11; i >= 0; i--) {
        const part = parseInt(parts[i]);
        const factor = i % 2 ? 3 : 1;

        checksum += part * factor;
    }

    checksum = 10 - (checksum % 10);
    checksum %= 10;

    return checksum.toString()
}

/**
 * @param {string} value
 * @param {string} field
 * @returns {string|undefined}
 */
export const validSocialSecurityNumber = (value, field) => {
    const socialSecurityNumberStr = value.split('').filter(char => char !== '.').join('');
    const socialSecurityNumberInt = parseInt(socialSecurityNumberStr);

    switch (true) {
        case value.length === 0:
            return undefined;
        case (value.length !== 16 && value.length !== 13) || socialSecurityNumberStr.length !== 13:
            return _t(field, 'invalidSocialSecurityNumberLength');
        case isNaN(socialSecurityNumberInt):
            return _t(field, 'invalidSocialSecurityNumber');
        case value.substr(-1) !== _calculateSocialSecurityNumberChecksum(socialSecurityNumberStr):
            return _t(field, 'invalidSocialSecurityNumber');
        default:
            return undefined;
    }
};

/**
 * @param {string} value
 * @param {string} field
 * @returns {string|undefined}
 */
export const validIBAN = (value, field) => {
    if (value.length === 0) {
        return undefined;
    }

    const iban = electronicFormatIBAN(value);

    return !isValidIBAN(iban) ? _t(field, 'invalidIBAN') : undefined;
};