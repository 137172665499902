import AbstractInputField from './AbstractInputField';

export default class TextInputField extends AbstractInputField {
    get type() {
        return 'text';
    }
}

TextInputField.propTypes = {
    ...AbstractInputField.propTypes,
};