import React from "react";
import ApiService from "../../services/ApiService";
import {Button, Form} from "react-bootstrap";
import QuestionTextField from "../questionFields/QuestionTextField";
import CheckboxField from "../questionFields/CheckboxField";
import MultipleCheckboxField from "../questionFields/MultipleCheckboxField";
import RadioButtonField from "../questionFields/RadioButtonField";
import '../../App.css';
import AnswerChoice from "../../models/AnswerChoice";
import QuestionChoice from "../../models/QuestionChoice";
import Local from "../../models/Local";
import {valNotEmpty} from "../../util/FormValidations";

class AssessmentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            //questionId: { answer: {}, question:{} }
            questionAnswerMap: {},
            //superCategory: [ quesitonId1, questionId2]
            scoreSuperCategoryQuestionIdMap: {}
        };

        this.setAnswer = this.setAnswer.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        this.props.setValidate(this.validate);

        if (this.props.assessmentQuestionAnswerMap !== null) {
            let scoreSuperCategoryQuestionIdMap = {};
            for (let assessmentQuestionAnswer of Object.values(this.props.assessmentQuestionAnswerMap)) {
                this.orderByCategory(assessmentQuestionAnswer.question, scoreSuperCategoryQuestionIdMap)
            }
            this.numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, this.props.assessmentQuestionAnswerMap);
            this.setState({
                questionAnswerMap: this.props.assessmentQuestionAnswerMap,
                scoreSuperCategoryQuestionIdMap: scoreSuperCategoryQuestionIdMap
            });
        } else {
            ApiService.createQuestionnaire(this.props.keycloak).then(questionnaire => {
                let caregiverAssessmentQuestions = questionnaire.caregiverAssessmentQuestion;
                let questionAnswerMap = {};
                let scoreSuperCategoryQuestionIdMap = {};
                for (let caregiverAssessmentQuestion of caregiverAssessmentQuestions) {
                    questionAnswerMap[caregiverAssessmentQuestion.id] = {
                        question: caregiverAssessmentQuestion,
                        answer: new AnswerChoice(null, new QuestionChoice(null, [new Local(null, "", null)])),
                        validator: valNotEmpty,
                        invalid: false,
                        ref: React.createRef()
                    };
                    this.orderByCategory(caregiverAssessmentQuestion, scoreSuperCategoryQuestionIdMap);
                }
                this.numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, questionAnswerMap);
                this.orderAnswersChoices(questionAnswerMap);
                this.setState({
                    questionAnswerMap: questionAnswerMap,
                    scoreSuperCategoryQuestionIdMap: scoreSuperCategoryQuestionIdMap
                });
            }).catch(error => console.error(error.message));
        }
    };

    orderByCategory(clientDeclarationQuestion, scoreSuperCategoryQuestionIdMap) {
        let scoreSuperCategory = clientDeclarationQuestion.scoreCategory.superCategory.description[0].text;
        if (!(scoreSuperCategory in scoreSuperCategoryQuestionIdMap))
            scoreSuperCategoryQuestionIdMap[scoreSuperCategory] = [];
        scoreSuperCategoryQuestionIdMap[scoreSuperCategory].push(
            clientDeclarationQuestion.id);
    }

    numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, questionAnswerMap) {
        let scoreSuperCategoryQuestionIdMapTemp = {};
        let superScoreCategoryNumber = 1;
        for (let superScoreCategory of Object.keys(scoreSuperCategoryQuestionIdMap)) {
            let questionIds = scoreSuperCategoryQuestionIdMap[superScoreCategory];
            let questionNumber = 1;
            for (let questionId of questionIds) {
                let question = questionAnswerMap[questionId].question;
                questionAnswerMap[questionId].question.question[0].text =
                    superScoreCategoryNumber + '.' + questionNumber + '. ' + question.question[0].text;
                questionNumber++;
            }
            scoreSuperCategoryQuestionIdMapTemp[superScoreCategoryNumber + '. ' + superScoreCategory] =
                scoreSuperCategoryQuestionIdMap[superScoreCategoryNumber];

            superScoreCategoryNumber++;
        }
        scoreSuperCategoryQuestionIdMap = scoreSuperCategoryQuestionIdMapTemp;
    }

    orderAnswersChoices(questionAnswerMap) {
        for (let questionId of Object.keys(questionAnswerMap)) {
            questionAnswerMap[questionId].question.choices.sort((first, second) => {
                if (!first || !first.viewOrder) {
                    // first is null or undefined
                    return -1;
                }
                if (!second || !second.viewOrder) {
                    // second is null or undefined
                    return 1;
                }
                return first.viewOrder - second.viewOrder;
            });
        }
    }

    setAnswer(questionId, answer) {
        let questionAnswerMap = this.state.questionAnswerMap;
        let choice = null;
        for (let choiceTemp of questionAnswerMap[questionId].question.choices) {
            if (choiceTemp.text[0].text === answer) {
                choice = choiceTemp;
                break;
            }
        }

        questionAnswerMap[questionId].answer.choice = choice;
        this.setState({questionAnswerMap: questionAnswerMap});
    }

    renderCaregiverRegistrationDeclarationForm() {

        if (Object.keys(this.state.questionAnswerMap).length > 0) {
            return (
                <Form>
                    {this.renderQuestions()}
                    <Button style={{marginTop: 40}} className={'button'} onClick={() => {
                        if (this.validate()) {
                            this.goNext();
                        }
                    }}>
                        Beenden
                    </Button>
                </Form>
            )
        }
        return <p>No Assessment Questions available</p>;
    }

    validate() {
        let focusTo = null;
        let allValid = true;
        let questionAnswerMap = this.state.questionAnswerMap;
        for (let questionAnswerKey in questionAnswerMap) {
            let questionAnswer = questionAnswerMap[questionAnswerKey];
            let valid = questionAnswer.validator(questionAnswer.answer.choice.text[0].text);
            questionAnswer.invalid = !valid;
            if (allValid && !valid) {
                focusTo = questionAnswer.ref;
                allValid = false;
            }
        }

        if (allValid) {
            return true;
        } else {
            focusTo.current.scrollIntoView();
            this.setState({
                questionAnswerMap: questionAnswerMap,
            });
            return false;
        }
    }

    goNext() {
        this.props.setAssessmentData(this.state.questionAnswerMap);
        this.props.setStep('assessmentFinish');
    }

    renderQuestions() {
        let renderedCategories = [];

        for (let superScoreCategory of Object.keys(this.state.scoreSuperCategoryQuestionIdMap)) {
            let renderedQuestions = [];
            let questionIds = this.state.scoreSuperCategoryQuestionIdMap[superScoreCategory];
            for (let questionId of questionIds) {
                let question = this.state.questionAnswerMap[questionId].question;
                renderedQuestions.push(
                    <div style={{marginBottom: 40}}>
                        {this.renderQuestionByType(question)}
                    </div>
                );
            }
            renderedCategories.push(
                <div style={{marginTop: 20, marginBottom: 40}}>
                    <h5 style={{marginBottom: 10, fontWeight: 'bold'}}>{superScoreCategory}</h5>
                    {renderedQuestions}
                </div>
            );
        }

        return renderedCategories;
    }

    renderQuestionByType(question) {
        switch (question.answerType) {
            default:
                return <QuestionTextField setAnswer={this.setAnswer}
                                          question={question}
                                          value={this.state.questionAnswerMap[question.id].answer.choice.text[0].text}
                                          invalid={this.state.questionAnswerMap[question.id].invalid}
                                          ref={this.state.questionAnswerMap[question.id].ref}
                                          required
                />;
            case 'single-choice':
                return <RadioButtonField setAnswer={this.setAnswer}
                                         question={question}
                                         value={this.state.questionAnswerMap[question.id].answer.choice.text[0].text}
                                         invalid={this.state.questionAnswerMap[question.id].invalid}
                                         ref={this.state.questionAnswerMap[question.id].ref}
                                         required
                />;
            case 'checkbox':
                return <CheckboxField setAnswer={this.setAnswer}
                                      question={question}
                                      value={this.state.questionAnswerMap[question.id].answer.choice.text[0].text}
                                      invalid={this.state.questionAnswerMap[question.id].invalid}
                                      ref={this.state.questionAnswerMap[question.id].ref}
                                      required
                />;
            case 'multiple-choice':
                return <MultipleCheckboxField setAnswer={this.setAnswer}
                                              question={question}
                                              value={this.state.questionAnswerMap[question.id].answer.choice.text[0].text}
                                              invalid={this.state.questionAnswerMap[question.id].invalid}
                                              ref={this.state.questionAnswerMap[question.id].ref}
                                              required
                />;
        }
    }

    render() {
        return (
            <div>
                {this.renderCaregiverRegistrationDeclarationForm()}
            </div>
        );
    }
}

export default AssessmentForm;
