import React from "react";
import {Navbar, NavLink} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {withKeycloak} from "@react-keycloak/web";
import logo from "../../assets/images/logo.png";
import './Header.css';

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    logout() {
        this.props.history.push('/');
        this.props.keycloak.logout();
    }

    login() {
        this.props.keycloak.login();
    }

    render() {
        return (
            <Navbar className={'navbar'}>
                <Navbar.Brand style={{cursor: 'pointer'}} onClick={() => this.props.history.push('/')}>
                    <img
                        style={{height: 24}}
                        src={logo}
                        className="d-inline-block align-top img-fluid"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                {this.props.keycloak && this.props.keycloakInitialized && this.props.keycloak.authenticated &&
                <NavLink className="ml-auto" onClick={() => this.logout()}>
                    Logout
                </NavLink>
                }
                {this.props.keycloak && this.props.keycloakInitialized && !this.props.keycloak.authenticated &&
                <NavLink className="ml-auto" onClick={() => this.login()}>
                    Login
                </NavLink>
                }
            </Navbar>
        );
    }
}

export default withKeycloak(withRouter(Header));
