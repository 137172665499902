export class ValidationHandler {

    constructor() {
        let _validationFunctions = [];
    }

    addValdationFunction(func) {
        this._validationFunctions.push(func);
    }

    removeValidationFunction(index) {
        this._validationFunctions.splice(index, 1);
    }

    validate() {
        //{ questionId: { valid: bool, ref: htmlRef } }
        let result = {};
        for (let func of this._validationFunctions) {
            func()
        }
        return result;
    }
}
