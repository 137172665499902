import React from "react";
import {Button} from "react-bootstrap";
import '../../App.css';

class ClientRegistrationDeclarationIntro extends React.Component {

    componentDidMount() {
        this.props.setValidate(null);
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: 40}}>
                    Nun folgt eine Selbst- oder Fremddeklaration und die Einschätzung
                    Ihres / der Ihnen anvertrauten Person zum aktuellen Gesundheitszustand.
                    Bitte ergänzen Sie Ihre Antworten wo möglich und vorhanden mit einem Nachweis,
                    den Sie bitte im vorgesehenen Bereich hochladen.
                </div>
                <div>
                    <Button style={{marginRight: 20}} onClick={() => this.props.setStep('profile')}
                            className={'button'}>
                        Zurück
                    </Button>
                    <Button className={'button'} onClick={() => this.props.setStep('declaration')}>Weiter</Button>
                </div>
            </div>
        );
    }
}

export default ClientRegistrationDeclarationIntro;
