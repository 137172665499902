/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class Country extends AbstractEnum {
    /**
     * @type{Map<String,Country>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{Country[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        Country._lookupMap.set(id, this);
        Country._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'Country';
    }

    /**
     * @param {string} id
     * @returns {Country|undefined}
     * @public
     */
    static valueOf(id) {
        return Country._lookupMap.get(id);
    }

    /**
     * @returns {Country[]}
     * @public
     */
    static values() {
        return [...Country._values];
    }

    /**
     * @type{Country}
     * @public
     */
    static CH = new Country('CH');
    /**
     * @type{Country}
     * @public
     */
    static DE = new Country('DE');
    /**
     * @type{Country}
     * @public
     */
    static AT = new Country('AT');
    /**
     * @type{Country}
     * @public
     */
    static IT = new Country('IT');
    /**
     * @type{Country}
     * @public
     */
    static FR = new Country('FR');
    /**
     * @type{Country}
     * @public
     */
    static LI = new Country('LI');
    /**
     * @type{Country}
     * @public
     */
    static AL = new Country('AL');
    /**
     * @type{Country}
     * @public
     */
    static AD = new Country('AD');
    /**
     * @type{Country}
     * @public
     */
    static BE = new Country('BE');
    /**
     * @type{Country}
     * @public
     */
    static BA = new Country('BA');
    /**
     * @type{Country}
     * @public
     */
    static BG = new Country('BG');
    /**
     * @type{Country}
     * @public
     */
    static DK = new Country('DK');
    /**
     * @type{Country}
     * @public
     */
    static EE = new Country('EE');
    /**
     * @type{Country}
     * @public
     */
    static FI = new Country('FI');
    /**
     * @type{Country}
     * @public
     */
    static GR = new Country('GR');
    /**
     * @type{Country}
     * @public
     */
    static IE = new Country('IE');
    /**
     * @type{Country}
     * @public
     */
    static IS = new Country('IS');
    /**
     * @type{Country}
     * @public
     */
    static XK = new Country('XK');
    /**
     * @type{Country}
     * @public
     */
    static HR = new Country('HR');
    /**
     * @type{Country}
     * @public
     */
    static LV = new Country('LV');
    /**
     * @type{Country}
     * @public
     */
    static LT = new Country('LT');
    /**
     * @type{Country}
     * @public
     */
    static LU = new Country('LU');
    /**
     * @type{Country}
     * @public
     */
    static MT = new Country('MT');
    /**
     * @type{Country}
     * @public
     */
    static MD = new Country('MD');
    /**
     * @type{Country}
     * @public
     */
    static MC = new Country('MC');
    /**
     * @type{Country}
     * @public
     */
    static ME = new Country('ME');
    /**
     * @type{Country}
     * @public
     */
    static NL = new Country('NL');
    /**
     * @type{Country}
     * @public
     */
    static MK = new Country('MK');
    /**
     * @type{Country}
     * @public
     */
    static NO = new Country('NO');
    /**
     * @type{Country}
     * @public
     */
    static PL = new Country('PL');
    /**
     * @type{Country}
     * @public
     */
    static PT = new Country('PT');
    /**
     * @type{Country}
     * @public
     */
    static RO = new Country('RO');
    /**
     * @type{Country}
     * @public
     */
    static RU = new Country('RU');
    /**
     * @type{Country}
     * @public
     */
    static SM = new Country('SM');
    /**
     * @type{Country}
     * @public
     */
    static SE = new Country('SE');
    /**
     * @type{Country}
     * @public
     */
    static RS = new Country('RS');
    /**
     * @type{Country}
     * @public
     */
    static SK = new Country('SK');
    /**
     * @type{Country}
     * @public
     */
    static SI = new Country('SI');
    /**
     * @type{Country}
     * @public
     */
    static ES = new Country('ES');
    /**
     * @type{Country}
     * @public
     */
    static CZ = new Country('CZ');
    /**
     * @type{Country}
     * @public
     */
    static TR = new Country('TR');
    /**
     * @type{Country}
     * @public
     */
    static UA = new Country('UA');
    /**
     * @type{Country}
     * @public
     */
    static HU = new Country('HU');
    /**
     * @type{Country}
     * @public
     */
    static VA = new Country('VA');
    /**
     * @type{Country}
     * @public
     */
    static GB = new Country('GB');
    /**
     * @type{Country}
     * @public
     */
    static BY = new Country('BY');
}