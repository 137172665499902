import Country from '../enums/Country';

export const convertToAPIGender = (gender) => {
    let correctGender = "male";
    if (gender === "Frau") correctGender = "female";
    else if (gender === "Andere") correctGender = "other";
    console.log(correctGender);
    return correctGender;
};

export const convertToLocalizedGender = (gender) => {
    let correctGender = "Herr";
    if (gender === "female") correctGender = "Frau";
    else if (gender === "other") correctGender = "Andere";
    return correctGender;
};

/**
 * @type {Map<string, string>}
 */
const countryNameToCodeMap = new Map();
/**
 * @type {Map<string, string>}
 */
const countryCodeToNameMap = new Map();

/**
 * @returns {[Map<string, string>, Map<string, string>]}
 */
const getCountryMaps = () => {
    if (countryNameToCodeMap.size === 0 && countryCodeToNameMap.size === 0) {
        Country.values().forEach(country => {
            countryNameToCodeMap.set(country.label, country.id);
            countryCodeToNameMap.set(country.id, country.label);
        });
    }

    return [countryNameToCodeMap, countryCodeToNameMap];
};

/**
 * @param {string} countryName
 * @returns {string}
 */
export const convertToAPICountry = (countryName) => {
    const [nameToCode,] = getCountryMaps();

    return nameToCode.get(countryName);
};

/**
 * @param {string} countryCode
 * @returns {string}
 */
export const convertToLocalizedCountry = (countryCode) => {
    const [, codeToName] = getCountryMaps();

    return codeToName.get(countryCode);
};