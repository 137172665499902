import React from "react";
import './CaregiverRegistration.css'
import CaregiverRegistrationProfileForm from "../../components/caregiverRegistration/CaregiverRegistrationProfileForm";
import CaregiverRegistrationSubmission from "../../components/caregiverRegistration/CaregiverRegistrationSubmission";
import CaregiverRegistrationDeclarationForm
    from "../../components/caregiverRegistration/CaregiverRegistrationDeclarationForm";
import CaregiverRegistrationStart from "../../components/caregiverRegistration/CaregiverRegistrationStart";
import CaregiverRegistrationDeclarationIntro
    from "../../components/caregiverRegistration/CaregiverRegistrationDeclarationIntro";
import {withKeycloak} from "@react-keycloak/web";
import CaregiverRegistrationDeclarationFiles
    from "../../components/caregiverRegistration/CaregiverRegistrationDeclarationFiles";
import CaregiverRegistrationMeetingTime from "../../components/caregiverRegistration/CaregiverRegistrationMeetingTime";
import UserInfo from "../../models/UserInfo";
import ApiService from "../../services/ApiService";
import Caregiver from "../../models/Caregiver";
import CaregiverDeclarationAnswer from "../../models/CaregiverDeclarationAnswer";
import {Row} from "react-bootstrap";
import {CAREGIVER, checkForRole, NEW_USER} from "../../util/roles";
import BreadCrumb from "../../components/breadcrumb/Breadcrumb";
import Meeting from "../../models/Meeting";

class CaregiverRegistration extends React.Component {
    constructor(props) {
        super(props);
        //steps: profile, declaration, assessment
        this.state = {
            caregiver: null,
            step: 'start',
            partnerCompany: null,
            profileQuestionAnswerMap: null,
            declarationQuestionAnswerMap: null,
            assessmentQuestionAnswerMap: null,
            files: null,
            meetings: [new Meeting(null, null, null, null, null, null)],
            validate: null,
            activeSteps: {
                start: true,
                profile: false,
                declarationIntro: false,
                declaration: false,
                declarationFiles: false,
                meetingTime: false,
                submission: false
            }
        };

        this.setStep = this.setStep.bind(this);
        this.setStepWithValidation = this.setStepWithValidation.bind(this);
        this.setValidate = this.setValidate.bind(this);
        this.setPartnerCompany = this.setPartnerCompany.bind(this);
        this.setProfileData = this.setProfileData.bind(this);
        this.setDeclarationData = this.setDeclarationData.bind(this);
        this.setAssessmentData = this.setAssessmentData.bind(this);
        this.setFiles = this.setFiles.bind(this);
        this.setMeetings = this.setMeetings.bind(this);
        this.submitData = this.submitData.bind(this);
        //this.submitAssessment = this.submitAssessment.bind(this);
    }

    setValidate(validate) {
        if (this.state.validate !== validate) {
            this.setState({validate: validate});
        }
    }

    setStep(step) {
        let activeSteps = this.state.activeSteps;
        activeSteps[step] = true;
        this.setState({activeSteps: activeSteps, step: step});
    }

    setStepWithValidation(step) {
        if (this.state.validate !== null && this.state.validate()) {
            let activeSteps = this.state.activeSteps;
            activeSteps[step] = true;
            this.setState({activeSteps: activeSteps, step: step});
        } else if (this.state.validate === null) {
            this.setState({step: step})
        }
    }

    setPartnerCompany(data) {
        this.setState({partnerCompany: data})
    }

    setProfileData(data) {
        this.setState({profileQuestionAnswerMap: data});
    }

    setDeclarationData(data) {
        this.setState({declarationQuestionAnswerMap: data});
    }

    setAssessmentData(data) {
        this.setState({assessmentQuestionAnswerMap: data});
    }

    setFiles(data, fileResume) {
        this.setState({files: data, fileResume: fileResume})
    }

    setMeetings(data) {
        this.setState({meetings: data});
    }

    submitData() {
        let caregiverProfiles = [];
        for (let questionAnswer of Object.values(this.state.declarationQuestionAnswerMap)) {
            let answer =
                new CaregiverDeclarationAnswer(null, null, questionAnswer.question.id, questionAnswer.answers);
            caregiverProfiles.push(answer);
            console.log(answer);
        }

        let meetings = [];
        for (let meeting of this.state.meetings) {
            if (meeting.date !== null)
                meetings.push(meeting);
        }

        let userInfo = new UserInfo(
            null,
            null,
            this.state.profileQuestionAnswerMap["lastName"].answer,
            this.state.profileQuestionAnswerMap["firstName"].answer,
            null,
            this.state.profileQuestionAnswerMap["streetAndNr"].answer,
            this.state.profileQuestionAnswerMap["zipcode"].answer,
            this.state.profileQuestionAnswerMap["city"].answer,
            this.state.profileQuestionAnswerMap["country"].answer,
            this.state.profileQuestionAnswerMap["gender"].answer,
            this.state.profileQuestionAnswerMap["birthDate"].answer,
            this.state.profileQuestionAnswerMap["phoneNumber"].answer,
            this.state.profileQuestionAnswerMap["mobileNumber"].answer,
            this.state.profileQuestionAnswerMap["nationality"].answer,
            null,
            null
        );

        let caregiver = new Caregiver(null, userInfo, null,
            this.state.partnerCompany ? this.state.partnerCompany.answer : null,
            null, caregiverProfiles, null, null,
            this.state.profileQuestionAnswerMap["languageSkills"].answer);

        return ApiService.createCaregiver(caregiver, this.props.keycloak).then((caregiver) => {
            this.props.keycloak.updateToken(-1).then((token) => {
                for (let meeting of meetings) meeting.userInfoId = caregiver.userInfo.id;
                ApiService.addMeetings(meetings, this.props.keycloak).then(() => {
                    if (this.state.files !== null) {
                        ApiService.addCaregiverFiles(caregiver.id, this.state.files, [], this.props.keycloak).then(() => {
                            this.props.history.push('/');
                        });
                    } else {
                        this.props.history.push('/');
                    }
                });
            });
        });
    }

    /*submitAssessment() {
        let caregiverSelfAssessments = [];
        for(let questionAnswer of Object.values(this.state.assessmentQuestionAnswerMap)) {
            let answer =
                new CaregiverAssessmentAnswer(null, null, questionAnswer.question.id, [questionAnswer.answer]);
            caregiverSelfAssessments.push(answer);
        }

        let meetings = [];
        for(let meeting of this.state.meetings) {
            if(meeting.date !== null)
                meetings.push(meeting);
        }

        return ApiService.addCaregiverAssessmentAnswers(this.state.caregiver.id, caregiverSelfAssessments, this.props.keycloak)
            .then((caregiver) => {
                this.props.keycloak.updateToken(-1).then((token) => {
                    for(let meeting of meetings) meeting.userInfoId = this.state.caregiver.userInfo.id;
                    ApiService.addMeetings(meetings, this.props.keycloak).then(() => {
                        this.setState({caregiver: caregiver});
                    });
                });
            });
    }*/

    renderStep() {
        switch (this.state.step) {
            /*case "assessment":
                return <AssessmentForm setStep={this.setStep}
                                       setValidate={this.setValidate}
                                       setAssessmentData={this.setAssessmentData}
                                       assessmentQuestionAnswerMap={this.state.assessmentQuestionAnswerMap}
                                       keycloak={this.props.keycloak}
                />;
            case "assessmentFinish":
                return <AssessmentFinish setStep={this.setStep}
                                         setValidate={this.setValidate}/>;
            case "assessmentStart":
                return <AssessmentStart setStep={this.setStep}
                                        setValidate={this.setValidate}
                                        keycloak={this.props.keycloak}/>;*/
            case "submission":
                return <CaregiverRegistrationSubmission setStep={this.setStep}
                                                        setValidate={this.setValidate}
                                                        submitData={this.submitData}
                />;
            case "meetingTime":
                return <CaregiverRegistrationMeetingTime setStep={this.setStep}
                                                         setValidate={this.setValidate}
                                                         setMeetings={this.setMeetings}
                                                         meetings={this.state.meetings}
                />;
            case "declarationFiles":
                return <CaregiverRegistrationDeclarationFiles setStep={this.setStep}
                                                              setValidate={this.setValidate}
                                                              setFiles={this.setFiles}
                                                              submitData={this.submitData}
                                                              keycloak={this.props.keycloak}
                />;
            case "declaration":
                return <CaregiverRegistrationDeclarationForm setStep={this.setStep}
                                                             setValidate={this.setValidate}
                                                             setDeclarationData={this.setDeclarationData}
                                                             declarationQuestionAnswerMap={this.state.declarationQuestionAnswerMap}
                                                             keycloak={this.props.keycloak}/>;
            case 'declarationIntro':
                return <CaregiverRegistrationDeclarationIntro setStep={this.setStep}
                                                              setValidate={this.setValidate}/>;
            case 'profile':
                return <CaregiverRegistrationProfileForm setStep={this.setStep}
                                                         setValidate={this.setValidate}
                                                         setPartnerCompany={this.setPartnerCompany}
                                                         setProfileData={this.setProfileData}
                                                         profileQuestionAnswerMap={this.state.profileQuestionAnswerMap}
                />;
            default:
                return <CaregiverRegistrationStart setStep={this.setStep}
                                                   setValidate={this.setValidate}
                />;
        }
    }

    render() {
        if (this.props.keycloak && this.props.keycloakInitialized) {
            if (this.props.keycloak.authenticated) {
                if (checkForRole(NEW_USER, this.props.keycloak) || checkForRole(CAREGIVER, this.props.keycloak)) {
                    return (
                        <div className="d-flex justify-content-center mainDiv">
                            <div style={{maxWidth: 800, width: '100%'}}>
                                <h4 style={{margin: '40px 0'}}>Registrierung Betreuungsperson</h4>
                                <div>
                                    <Row style={{margin: 0, marginBottom: 20}}>
                                        <BreadCrumb name={'Start'}
                                                    currentStep={this.state.step}
                                                    steps={["start"]}
                                                    setStep={this.setStepWithValidation}
                                                    activeSteps={this.state.activeSteps}/>
                                        <p style={this.breadcrumbDivider}>|</p>
                                        <BreadCrumb name={'Personalien'}
                                                    currentStep={this.state.step}
                                                    steps={["profile"]}
                                                    setStep={this.setStepWithValidation}
                                                    activeSteps={this.state.activeSteps}/>
                                        <p style={this.breadcrumbDivider}>|</p>
                                        <BreadCrumb name={'Selbstdeklarationsfragen'}
                                                    currentStep={this.state.step}
                                                    steps={["declaration", "declarationIntro"]}
                                                    setStep={this.setStepWithValidation}
                                                    activeSteps={this.state.activeSteps}/>
                                        <p style={this.breadcrumbDivider}>|</p>
                                        <BreadCrumb name={'Selbstdeklarationsdokumente'}
                                                    currentStep={this.state.step}
                                                    steps={["declarationFiles"]}
                                                    setStep={this.setStepWithValidation}
                                                    activeSteps={this.state.activeSteps}/>
                                        <p style={this.breadcrumbDivider}>|</p>
                                        <BreadCrumb name={'Terminvorschlag'}
                                                    currentStep={this.state.step}
                                                    steps={["meetingTime"]}
                                                    setStep={this.setStepWithValidation}
                                                    activeSteps={this.state.activeSteps}/>
                                        <p style={this.breadcrumbDivider}>|</p>
                                        <BreadCrumb name={'Abschluss'}
                                                    currentStep={this.state.step}
                                                    steps={["submission"]}
                                                    setStep={this.setStepWithValidation}
                                                    activeSteps={this.state.activeSteps}/>
                                    </Row>
                                </div>
                                {this.renderStep()}
                            </div>
                        </div>
                    )
                } else {
                    this.props.history.push('/');
                }
            } else {
                this.props.keycloak.register();
                return (<div>Unable to authenticate!</div>)
            }
        }
        return (<div></div>);
    }

    breadcrumbDivider = {
        marginRight: 5,
        marginLeft: 5
    };
}

export default withKeycloak(CaregiverRegistration);
