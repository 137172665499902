import React from 'react';
import {TextareaAutosize} from '@material-ui/core';
import AbstractField from './AbstractField';
import PropTypes from "prop-types";

export default class TextareaField extends AbstractField {
    /**
     * @returns {string}
     */
    get placeholder() {
        // noinspection JSValidateTypes
        return this.props.placeholder ?? this.t('pleaseEnterText');
    }

    /**
     * @returns {string}
     */
    get autoComplete() {
        return !!this.props.autoComplete ? 'on' : 'new-password';
    }

    /**
     * @returns {number}
     */
    get minRows() {
        return this.props.minRows ?? 3;
    }

    renderField({field, form, meta}) {
        return (
            <TextareaAutosize name={this.name}
                              autoComplete={this.autoComplete}
                              placeholder={this.placeholder}
                              value={field.value ?? ''}
                              onChange={form.handleChange}
                              className="w-100"
                              minRows={this.minRows}
                              disabled={this.disabled(field.value)}/>
        );
    }
}

TextareaField.propTypes = {
    ...AbstractField.propTypes,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.bool,
    minRows: PropTypes.number
}