import AbstractModel from "./AbstractModel";

export default class Availability extends AbstractModel {
    /**
     * @type {string}
     * @private
     */
    _id = undefined;
    /**
     * @type {Set<string>}
     * @private
     */
    _workLoads = undefined;
    /**
     * @type {Set<string>}
     * @private
     */
    _workSlots = undefined;

    /**
     * @param {string} [id]
     * @param {Set<string>} [workLoads]
     * @param {Set<string>} [workSlots]
     */
    constructor(id, workLoads, workSlots) {
        super();

        this._id = id;
        this._workLoads = workLoads ?? new Set();
        this._workSlots = workSlots ?? new Set();
    }

    /**
     * @returns {string}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {string} id
     */
    set id(id) {
        this._id = id;
    }

    /**
     * @returns {Set<string>}
     */
    get workLoads() {
        return this._workLoads;
    }

    /**
     * @param {Set<string>} workLoads
     */
    set workLoads(workLoads) {
        this._workLoads = workLoads;
    }

    /**
     * @returns {Set<string>}
     */
    get workSlots() {
        return this._workSlots;
    }

    /**
     * @param {Set<string>} workSlots
     */
    set workSlots(workSlots) {
        this._workSlots = workSlots;
    }

    /**
     * @returns {{}}
     */
    toJSON() {
        return {
            id: this._id,
            workloads: [...this._workLoads.values()],
            workslots: [...this._workSlots.values()]
        };
    }

    /**
     * @returns {Availability}
     */
    clone() {
        return this._clone(new Availability());
    }
}