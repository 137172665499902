/**
 * @deprecated
 * @type {[{name: string, key: string}]}
 */
export const languages = [
    {name: "Deutsch", key: "language-de"},
    {name: "Englisch", key: "language-en"},
    {name: "Französisch", key: "language-fr"},
    {name: "Italienisch", key: "language-it"},
    {name: "Albanisch", key: "language-sq"},
    {name: "Bosnisch", key: "language-bs"},
    {name: "Bulgarisch", key: "language-bg"},
    {name: "Dänisch", key: "language-da"},
    {name: "Estnisch", key: "language-et"},
    {name: "Finnisch", key: "language-fi"},
    {name: "Griechisch", key: "language-el"},
    {name: "Isländisch", key: "language-is"},
    {name: "Kroatisch", key: "language-hr"},
    {name: "Lettisch", key: "language-lv"},
    {name: "Litauisch", key: "language-lt"},
    {name: "Luxemburgisch", key: "language-lb"},
    {name: "Maltesisch", key: "language-mt"},
    {name: "Montenegrinisch", key: "language-cnr"},
    {name: "Mazedonisch", key: "language-mk"},
    {name: "Niederländisch", key: "language-nl"},
    {name: "Norwegisch", key: "language-no"},
    {name: "Polnisch", key: "language-pl"},
    {name: "Portugiesisch", key: "language-pt"},
    {name: "Rumänisch", key: "language-ro"},
    {name: "Russisch", key: "language-ru"},
    {name: "Schwedisch", key: "language-sv"},
    {name: "Slowakisch", key: "language-sk"},
    {name: "Slowenisch", key: "language-sl"},
    {name: "Spanisch", key: "language-es"},
    {name: "Tschechisch", key: "language-cs"},
    {name: "Türkisch", key: "language-tr"},
    {name: "Ukrainisch", key: "language-uk"},
    {name: "Ungarisch", key: "language-hu"},
    {name: "Weissrussisch", key: "language-be"}
];