import React from 'react';
import AbstractData from './AbstractData';
import PropTypes from "prop-types";

/** @typedef {import('../../../enums/AbstractEnum').default} AbstractEnum */

export default class EnumData extends AbstractData {
    /**
     * @returns {AbstractEnum}
     */
    get enum() {
        return this.props.enum;
    }

    renderData({field, form, meta}) {
        // noinspection JSCheckFunctionSignatures
        return this.enum.valueOf(field.value)?.label ?? <span>&nbsp;</span>;
    }
}

EnumData.propTypes = {
    ...AbstractData.propTypes,
    enum: PropTypes.any.isRequired
}