import AbstractModel from "./AbstractModel";

export default class Meeting extends AbstractModel {

    constructor(id, date, creationDate, status, userInfo, userInfoId, userInfoFor, userInfoIdFor) {
        super();

        this._id = id;
        this._date = date;
        this._creationDate = creationDate;
        this._status = status;
        this._userInfo = userInfo;
        this._userInfoId = userInfoId;
        this._userInfoFor = userInfoFor;
        this._userInfoIdFor = userInfoIdFor;
    }

    get id() {
        return this._id;
    }

    get date() {
        return this._date;
    }

    set date(value) {
        this._date = value;
    }

    get creationDate() {
        return this._creationDate;
    }

    set creationDate(value) {
        this._creationDate = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }

    get userInfo() {
        return this._userInfo;
    }

    set userInfo(value) {
        this._userInfo = value;
    }

    get userInfoId() {
        return this._userInfoId;
    }

    set userInfoId(value) {
        this._userInfoId = value;
    }

    get userInfoFor() {
        return this._userInfoFor;
    }

    set userInfoFor(value) {
        this._userInfoFor = value;
    }


    get userInfoIdFor() {
        return this._userInfoIdFor;
    }

    set userInfoIdFor(value) {
        this._userInfoIdFor = value;
    }

    toJSON() {
        return {
            id: this._id,
            date: this._date,
            creationDate: this._creationDate,
            status: this._status,
            userInfo: this._userInfo ? this._userInfo.toJSON() : this._userInfoId,
            userInfoFor: this._userInfoFor ? this._userInfoFor.toJSON() : this._userInfoIdFor
        }
    }

    /**
     * @returns {Meeting}
     */
    clone() {
        return this._clone(new Meeting());
    }
}
