/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class Workslot extends AbstractEnum {
    /**
     * @type{Map<String,Workslot>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{Workslot[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        Workslot._lookupMap.set(id, this);
        Workslot._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'Workslot';
    }

    /**
     * @param {string} id
     * @returns {Workslot|undefined}
     * @public
     */
    static valueOf(id) {
        return Workslot._lookupMap.get(id);
    }

    /**
     * @returns {Workslot[]}
     * @public
     */
    static values() {
        return [...Workslot._values];
    }

    /**
     * @type{Workslot}
     * @public
     */
    static FLEXIBLE = new Workslot('flexible');
    /**
     * @type{Workslot}
     * @public
     */
    static MONDAY = new Workslot('monday');
    /**
     * @type{Workslot}
     * @public
     */
    static MONDAY_MORNING = new Workslot('monday-morning');
    /**
     * @type{Workslot}
     * @public
     */
    static MONDAY_AFTERNOON = new Workslot('monday-afternoon');
    /**
     * @type{Workslot}
     * @public
     */
    static MONDAY_NIGHT = new Workslot('monday-night');
    /**
     * @type{Workslot}
     * @public
     */
    static TUESDAY = new Workslot('tuesday');
    /**
     * @type{Workslot}
     * @public
     */
    static TUESDAY_MORNING = new Workslot('tuesday-morning');
    /**
     * @type{Workslot}
     * @public
     */
    static TUESDAY_AFTERNOON = new Workslot('tuesday-afternoon');
    /**
     * @type{Workslot}
     * @public
     */
    static TUESDAY_NIGHT = new Workslot('tuesday-night');
    /**
     * @type{Workslot}
     * @public
     */
    static WEDNESDAY = new Workslot('wednesday');
    /**
     * @type{Workslot}
     * @public
     */
    static WEDNESDAY_MORNING = new Workslot('wednesday-morning');
    /**
     * @type{Workslot}
     * @public
     */
    static WEDNESDAY_AFTERNOON = new Workslot('wednesday-afternoon');
    /**
     * @type{Workslot}
     * @public
     */
    static WEDNESDAY_NIGHT = new Workslot('wednesday-night');
    /**
     * @type{Workslot}
     * @public
     */
    static THURSDAY = new Workslot('thursday');
    /**
     * @type{Workslot}
     * @public
     */
    static THURSDAY_MORNING = new Workslot('thursday-morning');
    /**
     * @type{Workslot}
     * @public
     */
    static THURSDAY_AFTERNOON = new Workslot('thursday-afternoon');
    /**
     * @type{Workslot}
     * @public
     */
    static THURSDAY_NIGHT = new Workslot('thursday-night');
    /**
     * @type{Workslot}
     * @public
     */
    static FRIDAY = new Workslot('friday');
    /**
     * @type{Workslot}
     * @public
     */
    static FRIDAY_MORNING = new Workslot('friday-morning');
    /**
     * @type{Workslot}
     * @public
     */
    static FRIDAY_AFTERNOON = new Workslot('friday-afternoon');
    /**
     * @type{Workslot}
     * @public
     */
    static FRIDAY_NIGHT = new Workslot('friday-night');
    /**
     * @type{Workslot}
     * @public
     */
    static SATURDAY = new Workslot('saturday');
    /**
     * @type{Workslot}
     * @public
     */
    static SATURDAY_MORNING = new Workslot('saturday-morning');
    /**
     * @type{Workslot}
     * @public
     */
    static SATURDAY_AFTERNOON = new Workslot('saturday-afternoon');
    /**
     * @type{Workslot}
     * @public
     */
    static SATURDAY_NIGHT = new Workslot('saturday-night');
    /**
     * @type{Workslot}
     * @public
     */
    static SUNDAY = new Workslot('sunday');
    /**
     * @type{Workslot}
     * @public
     */
    static SUNDAY_MORNING = new Workslot('sunday-morning');
    /**
     * @type{Workslot}
     * @public
     */
    static SUNDAY_AFTERNOON = new Workslot('sunday-afternoon');
    /**
     * @type{Workslot}
     * @public
     */
    static SUNDAY_NIGHT = new Workslot('sunday-night');
}