import React from 'react';
import {Spinner as BootstrapSpinner} from "react-bootstrap";
import PropTypes from 'prop-types';

export default function Spinner({minHeight}) {
    return (
        <div className="d-flex align-items-center justify-content-center" style={{minHeight: `${minHeight ?? '80'}vh`}}>
            <BootstrapSpinner animation="border"/>
        </div>
    );
}

Spinner.propTypes = {
    minHeight: PropTypes.number
}