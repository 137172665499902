import React from "react";
import {Button, Form} from "react-bootstrap";
import '../../App.css';
import MeetingPicker from "../meetingPicker/MeetingPicker";

class CaregiverRegistrationMeetingTime extends React.Component {

    componentDidMount() {
        this.props.setValidate(null);
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: 40}}>
                    Anbei können Sie uns Terminvorschläge oder Zeitfenster mitteilen,
                    wann wir eine persönliches Gespräch mit Ihnen durchführen könnten.
                    Dies kann per Videokonferenz sein.
                </div>
                <Form.Group style={{marginBottom: 40}} controlId="exampleForm.ControlTextarea1">
                    <h2>Terminvorschläge</h2>
                    <MeetingPicker setMeetings={this.props.setMeetings} meetings={this.props.meetings}/>
                </Form.Group>
                <div>
                    <Button style={{marginRight: 20}} onClick={() => this.props.setStep('assessmentFinish')}
                            className={'button'}>
                        Zurück
                    </Button>
                    <Button className={'button'} onClick={() => this.props.setStep('submission')}>Weiter</Button>
                </div>
            </div>
        );
    }
}

export default CaregiverRegistrationMeetingTime;
