import React from "react";
import {Button} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import '../../App.css';

class ClientRegistrationSubmission extends React.Component {

    componentDidMount() {
        this.props.setValidate(null);
    }

    render() {
        return (
            <div>
                <p>Vielen Dank für die selbstständige und wahrheitsgetreue Bearbeitung der Inhalte.</p>
                <p>
                    Sie erhalten von uns in den nächsten Tagen eine Auswertung mit einer
                    Einschätzung und Empfehlung zu Ihrer Betreuung.
                </p>
                <div style={{marginTop: 40}}>
                    <Button style={{marginRight: 20}} onClick={() => this.props.setStep('meetingTime')}
                            className={'button'}>
                        Zurück
                    </Button>
                    <Button className={'button'} onClick={
                        () => {
                            this.props.submitData();
                        }}>Weiter</Button>
                </div>
            </div>
        );
    }
}

export default withRouter(ClientRegistrationSubmission);
