import React from "react";
import {Button, Card} from "react-bootstrap";

class RegistrationView extends React.Component {

    render() {
        return (
            <div style={{textAlign: 'center', padding: 10}}>
                <h2 style={{marginBottom: 30}}>Ich möchte mich anmelden als...</h2>
                <div className="d-flex justify-content-center">
                    <Card style={{width: '18rem', marginRight: 10}}>
                        <Card.Body>
                            <Card.Title>Kunde</Card.Title>
                            <Card.Text>
                                Ich möchte Betreuungsdienste beziehen
                            </Card.Text>
                            <Button variant="primary" onClick={() => this.props.history.push("registration/client")}>
                                Kunde
                            </Button>
                        </Card.Body>
                    </Card>
                    <Card style={{width: '18rem', marginLeft: 10}}>
                        <Card.Body>
                            <Card.Title>Betreuungsperson</Card.Title>
                            <Card.Text>
                                Ich möchte meine Dienste anbieten
                            </Card.Text>
                            <Button variant="primary" onClick={() => this.props.history.push("registration/caregiver")}>
                                Betreuungsperson
                            </Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

export default RegistrationView;
