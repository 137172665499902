import React from "react";
import '../../App.css';
import image from '../../assets/images/privacy-policy-legal-notice-header.jpg'

class LegalNotice extends React.Component {
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row pb-xl-5">
                        <div className="col-md-12">
                            <img src={image}
                                 alt="Privacy policy and legal notice" className="img-fluid mb-5"/>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-12 ml-auto mr-auto">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1>Impressum</h1>
                                </div>
                                <div className="col-md-6">
                                    <div className="entry">
                                        <p>Verantwortlich für den Inhalt der Seiten</p>

                                        <p>International Association for Healthy Aging<br/>
                                            Seefeldstrasse 62<br/>
                                            8008 Zürich<br/>
                                            Schweiz</p>

                                        <p><a href="tel:+41442088844">+41 44 208 88 44</a><br/>
                                            <a href="mailto:info@iaha.ch">info@iaha.ch</a></p>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-5">
                                    <div className="entry">
                                        <h2>Haftungshinweis</h2>

                                        <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für
                                            die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
                                            ausschliesslich deren Betreiber verantwortlich. Sämtliche angebotenen
                                            Informationen dürfen – auch auszugsweise – nur mit schriftlicher Genehmigung
                                            der International Association for Healthy Aging&nbsp;weiterverbreitet oder
                                            anderweitig veröffentlicht werden. Keinesfalls ist es gestattet, die
                                            abgerufenen Informationen online oder in anderen Medien weiterzuverbreiten
                                            oder kommerziell zu nutzen!</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LegalNotice;
