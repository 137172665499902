import Keycloak from 'keycloak-js'

let keycloakConfig;
if (window._env_.REACT_APP_HOST_ENV === 'production') {
    keycloakConfig = '/keycloak.prod.json';
} else if (window._env_.REACT_APP_HOST_ENV === 'staging') {
    keycloakConfig = '/keycloak.staging.json';
} else {
    keycloakConfig = '/keycloak.dev.json';
}

/**
 * @type {Keycloak.KeycloakInstance}
 */
const keycloak = new Keycloak(keycloakConfig);

export default keycloak
