export default class Role {
    /**
     * @returns {string}
     */
    static get NEW_USER() {
        return 'new_user';
    }

    /**
     * @returns {string}
     */
    static get CLIENT() {
        return 'client';
    }

    /**
     * @returns {string}
     */
    static get CLIENTOBSERVER() {
        return 'clientobserver';
    }

    /**
     * @returns {string}
     */
    static get CAREGIVER() {
        return 'caregiver';
    }
}