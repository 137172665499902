import AbstractModel from "./AbstractModel";

export default class Caregiver extends AbstractModel {

    constructor(id, userInfo, partnerCompany, partnerCompanyId, registrationCompleted, caregiverProfiles,
                caregiverSelfAssessments, caregiverScores, languageSkills, availability) {
        super();

        this._id = id;
        this._userInfo = userInfo;
        this._partnerCompany = partnerCompany;
        this._partnerCompanyId = partnerCompanyId;
        this._registrationCompleted = registrationCompleted;
        this._caregiverProfiles = caregiverProfiles;
        this._caregiverSelfAssessments = caregiverSelfAssessments;
        this._caregiverScores = caregiverScores;
        this._languageSkills = languageSkills;
        this._availability = availability;
    }

    get id() {
        return this._id;
    }

    get userInfo() {
        return this._userInfo;
    }

    set userInfo(value) {
        this._userInfo = value;
    }

    get partnerCompany() {
        return this._partnerCompany;
    }

    set partnerCompany(value) {
        this._partnerCompany = value;
    }

    get partnerCompanyId() {
        return this._partnerCompanyId;
    }

    set partnerCompanyId(value) {
        this._partnerCompanyId = value;
    }

    get registrationCompleted() {
        return this._registrationCompleted;
    }

    set registrationCompleted(value) {
        this._registrationCompleted = value;
    }

    get caregiverProfiles() {
        return this._caregiverProfiles;
    }

    set caregiverProfiles(value) {
        this._caregiverProfiles = value;
    }

    get caregiverSelfAssessments() {
        return this._caregiverSelfAssessments;
    }

    set caregiverSelfAssessments(value) {
        this._caregiverSelfAssessments = value;
    }

    get caregiverScores() {
        return this._caregiverScores;
    }

    set caregiverScores(value) {
        this._caregiverScores = value;
    }

    /**
     * @returns {LanguageSkill[]}
     */
    get languageSkills() {
        return this._languageSkills;
    }

    set languageSkills(value) {
        this._languageSkills = value;
    }

    /**
     * @returns {Availability}
     */
    get availability() {
        return this._availability;
    }

    /**
     * @param {Availability} availability
     */
    set availability(availability) {
        this._availability = availability;
    }

    toJSON() {
        let caregiverProfiles = (this._caregiverProfiles ?? []).map(declarationAnswer => declarationAnswer.toJSON());
        let caregiverSelfAssessments = (this._caregiverSelfAssessments ?? []).map(caregiverSelfAssessment => caregiverSelfAssessment.toJSON());
        let caregiverScores = (this._caregiverScores ?? []).map(caregiverScore => caregiverScore.toJSON());
        let languageSkills = (this._languageSkills ?? []).map(languageSkills => languageSkills.toJSON());

        return {
            id: this._id,
            userInfo: this._userInfo ? this._userInfo.toJSON() : "",
            partnerCompany: this._partnerCompany ? this._partnerCompany.id : this._partnerCompanyId,
            registrationCompleted: this._registrationCompleted,
            caregiverProfiles: caregiverProfiles,
            caregiverSelfAssessments: caregiverSelfAssessments,
            caregiverScores: caregiverScores,
            languageSkills: languageSkills,
            availability: this._availability?.toJSON()
        }
    }

    /**
     * @returns {Caregiver}
     */
    clone() {
        return this._clone(new Caregiver());
    }
}
