import AbstractModel from "./AbstractModel";

export default class ClientDeclarationQuestion extends AbstractModel {

    constructor(id, scoreCategory, question, answerType, choices) {
        super();

        this._id = id;
        this._scoreCategory = scoreCategory;
        this._question = question;
        this._answerType = answerType;
        this._choices = choices;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get scoreCategory() {
        return this._scoreCategory;
    }

    set scoreCategory(value) {
        this._scoreCategory = value;
    }

    get question() {
        return this._question;
    }

    set question(value) {
        this._question = value;
    }

    get answerType() {
        return this._answerType;
    }

    set answerType(value) {
        this._answerType = value;
    }

    get choices() {
        return this._choices;
    }

    set choices(value) {
        this._choices = value;
    }

    toJSON() {
        let choices = [];
        for (let choice of choices) {
            choices.push(choice.toJSON());
        }

        return ({
            id: this._id,
            scoreCategory: this._scoreCategory ? this._scoreCategory.toJSON() : "",
            question: this._question,
            answerType: this._answerType,
            choices: choices
        })
    }

    /**
     * @returns {ClientDeclarationQuestion}
     */
    clone() {
        return this._clone(new ClientDeclarationQuestion());
    }
}
