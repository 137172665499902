import AbstractInputField from './AbstractInputField';
import {validIBAN} from "./validators";
import {formatIBAN} from "./formaters";

export default class BankNumberField extends AbstractInputField {
    get type() {
        return 'text';
    }

    get validate() {
        return this.props.validate ?? [validIBAN];
    }

    get formatter() {
        return this.props.format ?? [formatIBAN];
    }
}