import {validEmail} from "./validators";
import AbstractInputField from "./AbstractInputField";

export default class EmailInputField extends AbstractInputField {
    get validate() {
        return this.props.validate ?? [validEmail];
    }

    /**
     * @returns {string}
     */
    get type() {
        return 'email';
    }
}

EmailInputField.propTypes = {
    ...AbstractInputField.propTypes,
};