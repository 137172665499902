import i18next from 'i18next';
import {lcFirst} from "../util/helpers";

/**
 * @abstract
 */
export default class AbstractEnum {
    /**
     * @type {string}
     * @private
     */
    _id = undefined;
    /**
     * @type {string}
     * @private
     */
    _translationKey = undefined;

    /**
     * @param {string} id
     */
    constructor(id) {
        this._id = id;
        this._translationKey = this._getTranslationKey();
    }

    /**
     * @abstract
     * @returns {string}
     * @protected
     */
    get _name() {
        throw new Error('not implemented');
    }

    /**
     * @returns {string}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     */
    get label() {
        return i18next.t(this._translationKey);
    }

    /**
     * @returns {string}
     */
    toJSON() {
        return this.id;
    }

    /**
     * @returns {string}
     * @protected
     */
    _getTranslationKey() {
        // noinspection JSUnresolvedVariable
        const enumName = lcFirst(this._name);

        return `enum.${enumName}.${this.id}`;
    }
}