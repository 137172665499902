import React from "react";
import {Form} from "react-bootstrap";

class DropdownField extends React.Component {

    renderOptions() {
        let renderedOptions = [];
        let choices = this.props.choices;
        for (let i in choices) {
            let choice = choices[i];
            renderedOptions.push(<option key={choice.key} value={i}>{choice.name}</option>)
        }
        return renderedOptions;
    }

    render() {
        return (
            <Form.Group controlId={this.props.question} {...this.props}>
                {this.props.question ? <Form.Label
                    style={{fontWeight: 'bold'}}>{this.props.question}{this.renderRequired()}</Form.Label> : ""}
                <Form.Control name={this.props.name}
                              ref={this.props.ref}
                              as="select"
                              value={this.matchStringToIndex(this.props.value)}
                              isInvalid={this.props.invalid}
                              onChange={(e) =>
                                  this.props.setAnswer(this.props.id, this.props.choices[e.target.value].key)
                              }>
                    {this.renderOptions()}
                </Form.Control>
            </Form.Group>
        )
    }

    matchStringToIndex(string) {
        for (let i in this.props.choices) {
            let choice = this.props.choices[i];
            if (choice.key === this.props.value) {
                return i;
            }
        }
        return 0;
    }

    renderRequired() {
        if (this.props.required) return '*';
        return '';
    }
}

export default DropdownField;
