import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import deCH from './i18n/de-CH.json';


i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: true, // TODO set in environment variable
        fallbackLng: 'de-CH',
        supportedLngs: [
            'de',
            'de-CH'
        ],
        resources: {
            'de': {
                translation: deCH
            },
            'de-CH': {
                translation: deCH
            }
        },
    })
    .then(() => ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
    ));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
