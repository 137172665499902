import AbstractInputField from './AbstractInputField';
import {validSocialSecurityNumber} from './validators';
import {formatSocialSecurityNumber} from './formaters';

export default class SocialSecurityNumberField extends AbstractInputField {
    get validate() {
        return this.props.validate ?? [validSocialSecurityNumber];
    }

    get formatter() {
        return this.props.formatter ?? [formatSocialSecurityNumber];
    }

    get type() {
        return 'text';
    }
}