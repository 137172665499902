import React from "react";
import {Button} from "react-bootstrap";
import '../../App.css';

class AssessmentStart extends React.Component {

    componentDidMount() {
        this.props.setValidate(null);
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: 40}}>
                    Nun folgen die angekündigten Testfragen zu den Kompetenzen im Bereich der Pflegehilfe. Bitte
                    beantworten
                    Sie die Fragen selbstständig und ohne Hilfsmittel zu verwenden. Nur so können wir
                    für Sie eine realistische Auswertung erstellen.

                </div>
                <Button className={'button'} onClick={() => this.props.setStep('assessment')}>Start</Button>
            </div>
        )
    }
}

export default AssessmentStart;
