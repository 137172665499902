import React from 'react';
import {v5 as uuid} from 'uuid';
import {notBlank} from './validators';
import {Field} from 'formik';
import PropTypes from 'prop-types';
import FormRow from './FormRow';
import i18next from 'i18next';
import {Fade} from 'react-bootstrap';
import AbstractForm from './AbstractForm';
import {identity} from "./formaters";

/** @typedef {import('formik').FieldInputProps} FieldInputProps */
/** @typedef {import('formik').FieldMetaProps} FieldMetaProps */
/** @typedef {import('formik').FieldProps} FieldProps */
/** @typedef {import('formik').FormikProps} FormikProps */

/**
 * @abstract
 */
export default class AbstractField extends React.Component {
    /**
     * @type {string}
     */
    static ID_NAMESPACE_UUID = 'ab657458-c474-447d-8c4b-a8c44a8623bf';

    /**
     * @returns {string}
     */
    get name() {
        return this.props.name;
    }

    /**
     * @returns {string}
     */
    get label() {
        return this.props.label;
    }

    /**
     * @returns {string}
     */
    get id() {
        return this.props.id ?? uuid(this.name, AbstractField.ID_NAMESPACE_UUID);
    }

    /**
     * @returns {(function(any):(string|undefined))[]}
     */
    get validate() {
        return this.props.validate ?? [];
    }

    /**
     * @template T
     * @returns {(function(T):T)[]}
     */
    get formatter() {
        return this.props.formatter ?? [identity];
    }

    /**
     * @returns {boolean}
     */
    get required() {
        return !!this.props.required;
    }

    /**
     * @returns {(function(any): boolean)}
     */
    get disabled() {
        const prop = this.props.disabled ?? (() => false);

        return typeof prop === 'function' ? prop : (() => !!prop);
    }

    /**
     * @returns {(function(any): boolean)}
     * @private
     */
    get hidden() {
        const prop = this.props.hidden ?? (() => false);

        return typeof prop === 'function' ? prop : (() => !!prop);
    }

    /**
     * @params {...any} args
     * @returns {JSX.Element}
     */
    t(...args) {
        // noinspection JSCheckFunctionSignatures
        return i18next.t(...args);
    }

    /**
     * @param {any} value
     * @returns {string|undefined}
     */
    onValidate = (value) => {
        const validations = this.validate;

        if (this.required) {
            validations.push(notBlank);
        }

        return validations
            .map(validationFunc => validationFunc(value ?? '', this.name))
            .find(result => !!result);
    }

    /**
     * @param {string} rawValue
     * @returns {string}
     */
    onFormat = (rawValue) => {
        let value = rawValue;

        this.formatter.forEach(formatter => value = formatter(value));

        return value;
    }

    /**
     * @param {FieldInputProps} field
     * @param {FormikProps} form
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    onChange = (field, form, event) => {
        const rawValue = event.target.value;
        const value = this.onFormat(rawValue);

        form.setFieldValue(field.name, value);
    }

    render() {
        return (
            <Field name={this.name} validate={this.onValidate}>
                {props => (
                    <>
                        <FormRow left={this._renderLabel(props)}
                                 right={this._renderField(props)}
                                 hidden={this.hidden(props.field.value)}/>
                        {this._renderErrorMessage(props)}
                    </>
                )}
            </Field>
        );
    }

    /**
     * @param {FieldInputProps} field
     * @param {FormikProps} form
     * @param {FieldMetaProps} meta
     * @returns {JSX.Element}
     * @abstract
     */
    renderField({field, form, meta}) {
        throw new Error('not implemented');
    }

    /**
     * @param {FieldInputProps} field
     * @param {FormikProps} form
     * @param {FieldMetaProps} meta
     * @returns {JSX.Element}
     */
    renderLabel({field, form, meta}) {
        return (<label htmlFor={this.id} className="d-block mb-0">{this.label}</label>);
    }

    /**
     * @param {FieldInputProps} field
     * @param {FormikProps} form
     * @param {FieldMetaProps} meta
     * @returns {JSX.Element}
     */
    renderErrorMessage({field, form, meta}) {
        return (
            <FormRow right={
                <div className="errorBoxStyle">{meta.error}</div>
            }/>
        );
    }

    /**
     * @param {FieldProps} props
     * @returns {JSX.Element}
     * @private
     */
    _renderLabel(props) {
        return (
            <div className="grayBoxStyle">
                <Fade in={!props.form[AbstractForm.FORM_INITIAL_LOAD]}>
                    {this.renderLabel(props)}
                </Fade>
            </div>
        );
    }

    /**
     * @param {FieldProps} props
     * @returns {JSX.Element}
     * @private
     */
    _renderField(props) {
        return (
            <div className="whiteBoxStyle">
                <Fade in={!props.form[AbstractForm.FORM_INITIAL_LOAD]}>
                    {this.renderField(props)}
                </Fade>
            </div>
        );
    }

    /**
     * @param {FieldProps} props
     * @returns {JSX.Element}
     * @private
     */
    _renderErrorMessage(props) {
        return props.meta.error && props.meta.touched && this.renderErrorMessage(props);
    }
}

AbstractField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    validate: PropTypes.arrayOf(PropTypes.func),
    formatter: PropTypes.arrayOf(PropTypes.func),
    required: PropTypes.bool,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    hidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};