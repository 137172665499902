import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * @param {JSX.Element} left
 * @param {JSX.Element} right
 * @param {boolean} hidden
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormRow({left, right, hidden}) {
    return (
        <Row hidden={!!hidden}>
            <Col xs={4}>
                {left ?? null}
            </Col>
            <Col>
                {right ?? null}
            </Col>
        </Row>
    );
};

FormRow.propTypes = {
    left: PropTypes.element,
    right: PropTypes.element,
    hidden: PropTypes.bool
};