import React from "react";
import './Footer.css';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class Footer extends React.Component {
    render() {
        return (
            <div className='footer mt-xl-5'>
                <div className={'container'}>
                    <Row className={'py-5'} style={{margin: '0'}}>
                        <Col md={4} sm={7} className={'mb-4'}>
                            <h3 className={'mb-3'}>Kontakt</h3>
                            <p>IAHA<br/>
                                Seefeldstrasse 62<br/>
                                8008 Zürich<br/>
                                Schweiz<br/>
                                <br/>
                                +41 44 208 88 44<br/>
                                info@iaha.ch</p>
                        </Col>
                        <Col md={12}>
                            <span className={'mr-3'}>
                                © 2021 International Asscociation for Healthy Aging
                            </span>
                            <Link to={{pathname: "/legal-notice"}} className={'a ml-md-5'}>Impressum</Link>
                            <Link to={{pathname: "/privacy-policy"}} className={'a ml-3'}>Datenschutz</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Footer;



