import React from 'react';
import {Route} from 'react-router-dom';
import Spinner from './Spinner';
import PropTypes from 'prop-types';
import Keycloak from 'keycloak-js';

export default class KeycloakAwareRoute extends React.Component {
    /**
     * @returns {JSX.Element}
     * @private
     */
    _renderRoute() {
        return (<Route path={this.props.path}
                       exact={!!this.props.exact}
                       render={this._renderComponent.bind(this)}/>);
    }

    /**
     * @param routeProps
     * @returns {JSX.Element}
     * @private
     */
    _renderComponent(routeProps) {
        const props = {
            keycloak: this.props.keycloak,
            keycloakInitialized: !!this.props.keycloak,
            ...(this.props.props ?? {}),
            ...routeProps
        };

        return React.createElement(this.props.component, props);
    }

    render() {
        return !!this.props.keycloak ? this._renderRoute() : (<Spinner/>);
    }
}

KeycloakAwareRoute.propTypes = {
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    props: PropTypes.object,
    keycloak: PropTypes.objectOf(Keycloak),
    component: PropTypes.func.isRequired
};