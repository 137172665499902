import React from "react";
import DashboardLanding from "./DashboardLanding";
import PropTypes from "prop-types";
import UserInfo from "../../models/UserInfo";

/**
 * @param {UserInfo} userInfo
 * @returns {JSX.Element}
 * @constructor
 */
export default function DashboardLandingCaregiver({userInfo}) {
    return (
        <div>
            <DashboardLanding userInfo={userInfo}/>
            <h2 className="headerStyle">Verfügbarkeit</h2>
            <hr className="hrStyle"/>
            <div className="grayBoxStyle separatorMargin">
                <p className="m-0">Auswahl der Verfügbarkeiten</p>
            </div>
            <h2 className="headerStyle">News</h2>
            <hr className="hrStyle"/>
            <div className="grayBoxStyle separatorMargin">
                <p className="m-0">News</p>
            </div>
        </div>
    );
};

DashboardLandingCaregiver.propTypes = {
    userInfo: PropTypes.instanceOf(UserInfo).isRequired
};