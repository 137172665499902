import React from "react";
import {Button, Form} from "react-bootstrap";
import '../../App.css';

class CaregiverRegistrationStart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false
        }
    }

    componentDidMount() {
        this.props.setValidate(null);
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: 20}}>
                    Besten Dank für das Interesse an unseren Services.
                    Der Registrierungsprozess für neue Betreuungspersonen läuft in den folgenden Schritten ab:
                </div>
                <div style={{fontWeight: 'bold'}}>Ablauf Registrierungsprozess</div>
                <div style={{marginBottom: 40}}>
                    <ol>
                        <li>Erstellung Benutzerprofil</li>
                        <li>Angaben Personalien</li>
                        <li>Selbstdeklarationsfragen</li>
                        <li>Persönliches Interview</li>
                    </ol>
                </div>
                <Form style={{marginTop: 40}} noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Form.Check
                            required
                            name="terms"
                            label="Ich stimme zu, dass meine Angaben von der IAHA und deren Partner für den Zweck der
                            Einschätzung in den Themengebieten Pflege und Betreuung verwendet werden können."
                            id="validationFormik0"
                        />
                    </Form.Group>
                    <Button style={{marginTop: 40}} className={'button'} type={"submit"}>Weiter</Button>
                </Form>
            </div>
        );
    }

    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            this.props.setStep('profile')
        }
        this.setState({validated: true});
    };
}

export default CaregiverRegistrationStart;
