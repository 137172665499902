import {
    jsonToCaregiver,
    jsonToCaregiverAssessmentQuestion,
    jsonToCaregiverDeclarationQuestion,
    jsonToClient,
    jsonToClientDeclarationQuestion,
    jsonToClientObserver,
    jsonToMeeting,
    jsonToPartnerCompany,
    jsonToQuestionnaire,
    jsonToScoreCategory,
    jsonToScoreSuperCategory,
    jsonToUploadedDocument,
    jsonToUserInfo,
    jsonToValidationResult
} from "../util/Mapper"
import APIResponseError from "./APIResponseError";
import APIValidationError from "./APIValidationError";
import APIBaseError from "./APIBaseError";

/** @typedef {import('../models/Email').default} Email */

class ApiService {

    api = window._env_.API_URL;

    createClient = (client, keycloak) => {
        return this.postRequest(this.api + '/client', client, keycloak).then(clientJson => {
            return jsonToClient(clientJson);
        });
    };

    createClientObserverWithClient = (clientObserver, keycloak) => {
        return this.postRequest(this.api + '/clientObserver', clientObserver, keycloak).then(clientObserverJson => {
            return jsonToClientObserver(clientObserverJson);
        });
    };

    updateClient = (client, keycloak) => {
        return this.putRequest(this.api + '/client/' + client.id, client, keycloak)
            .then(jsonToClient);
    };

    addClientFiles = (id, files, documents, keycloak) => {
        return this.postRequestFormData(this.api + "/client/" + id + "/document", files, documents, keycloak)
            .then(uploadedFiles => uploadedFiles.map(jsonToUploadedDocument));
    };

    createCaregiver = (caregiver, keycloak) => {
        return this.postRequest(this.api + '/caregiver', caregiver, keycloak).then(caregiverJson => {
            return jsonToCaregiver(caregiverJson);
        });
    };

    updateCaregiver = (caregiver, keycloak) => {
        return this.putRequest(this.api + '/caregiver/' + caregiver.id, caregiver, keycloak)
            .then(jsonToCaregiver);
    };

    addCaregiverFiles = (id, files, documents, keycloak) => {
        return this.postRequestFormData(this.api + "/caregiver/" + id + "/document", files, documents, keycloak)
            .then(uploadedFiles => uploadedFiles.map(jsonToUploadedDocument));
    };

    addCaregiverAssessmentAnswers = (id, assessmentAnswers, keycloak) => {
        return this.postRequestArray(this.api + '/caregiver/' + id + '/assessmentAnswer', assessmentAnswers, keycloak).then(assessmentAnswers => {
            return assessmentAnswers; //TODO: map to model
        });
    };

    getCaregivers = (keycloak) => {
        return this.getRequest(this.api + '/caregiver', keycloak).then(caregiversJson => {
            let caregivers = [];
            for (let caregiverJson of caregiversJson) {
                caregivers.push(jsonToCaregiver(caregiverJson));
            }
            return caregivers;
        });
    };

    getCaregiversJson = (keycloak) => {
        return this.getRequest(this.api + '/caregiver', keycloak)
    }

    getClients = (keycloak) => {
        return this.getRequest(this.api + '/client', keycloak).then(clientsJson => {
            let clients = [];
            for (let clientJson of clientsJson) {
                clients.push(jsonToCaregiver(clientJson));
            }
            return clients;
        });
    };

    getCaregiver = (id, keycloak) => {
        return this.getRequest(this.api + '/caregiver/' + id, keycloak).then(caregiverJson => {
            return jsonToCaregiver(caregiverJson);
        });
    };

    getPartnerCompanies = (keycloak) => {
        return this.getRequest(this.api + '/partnerCompany', keycloak).then(partnerCompaniesJson => {
            let partnerCompanies = [];
            for (let partnerCompanyJson of partnerCompaniesJson) partnerCompanies.push(jsonToPartnerCompany(partnerCompanyJson));
            return partnerCompanies;
        });
    };

    getQuestionnaires = (id) => {
        return this.getRequest(this.api + '/questionnaire');
    };

    getQuestionnaire = (id) => {
        return this.getRequest(this.api + '/questionnaire/' + id);
    };

    createQuestionnaire = (keycloak) => {
        return this.postRequest(this.api + '/questionnaire', null, keycloak).then(questionnaireJson => {
            return jsonToQuestionnaire(questionnaireJson);
        })
    };

    getCaregiverDeclarationQuestions = (keycloak) => {
        return this.getRequest(this.api + '/caregiverDeclarationQuestion', keycloak).then(caregiverDeclarationQuestionsJson => {
            let caregiverDeclarationQuestions = [];
            for (let caregiverDeclarationQuestionJson of caregiverDeclarationQuestionsJson) {
                caregiverDeclarationQuestions.push(jsonToCaregiverDeclarationQuestion(caregiverDeclarationQuestionJson))
            }
            return caregiverDeclarationQuestions;
        });
    };

    getCaregiverDeclarationQuestion = (id) => {
        return this.getRequest(this.api + '/caregiverDeclarationQuestion/' + id).then(caregiverDeclarationQuestionJson => {
            return jsonToCaregiverDeclarationQuestion(caregiverDeclarationQuestionJson);
        });
    };

    getCaregiverAssessmentQuestions = () => {
        return this.getRequest(this.api + '/caregiverAssessmentQuestion').then(caregiverAssessmentQuestionsJson => {
            let caregiverAssessmentQuestions = [];
            for (let caregiverAssessmentQuestionJson of caregiverAssessmentQuestionsJson) {
                caregiverAssessmentQuestions.push(jsonToCaregiverAssessmentQuestion(caregiverAssessmentQuestionJson))
            }
            return caregiverAssessmentQuestions;
        });
    };

    getCaregiverAssessmentQuestion = (id) => {
        return this.getRequest(this.api + '/caregiverAssessmentQuestion/' + id).then(caregiverAssessmentQuestionJson => {
            return jsonToCaregiverAssessmentQuestion(caregiverAssessmentQuestionJson);
        });
    };

    /**
     * @param {number} id
     * @param {Keycloak.KeycloakInstance} keycloak
     */
    getCaregiverUploadedDocuments = (id, keycloak) => {
        return this.getRequest(`${this.api}/caregiver/${id}/document`, keycloak)
            .then(jsonDocuments => jsonDocuments.map(jsonToUploadedDocument));
    }

    /**
     * @param {number} caregiverId
     * @param {number} documentId
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<Blob>}
     */
    getCaregiverUploadedDocument = (caregiverId, documentId, keycloak) => {
        return this.getRequest(`${this.api}/caregiver/${caregiverId}/document/${documentId}`, keycloak, false)
            .then(response => response.blob());
    };

    getClientDeclarationQuestions = (keycloak) => {
        return this.getRequest(this.api + '/clientDeclarationQuestion', keycloak).then(clientDeclarationQuestionsJson => {
            let caregiverDeclarationQuestions = [];
            for (let clientDeclarationQuestionJson of clientDeclarationQuestionsJson) {
                caregiverDeclarationQuestions.push(jsonToClientDeclarationQuestion(clientDeclarationQuestionJson))
            }
            return caregiverDeclarationQuestions;
        });
    };

    getClientDeclarationQuestion = (id) => {
        return this.getRequest(this.api + '/clientDeclarationQuestion/' + id).then(caregiverDeclarationQuestionJson => {
            return jsonToClientDeclarationQuestion(caregiverDeclarationQuestionJson);
        });
    };

    getScoreCategories = () => {
        return this.getRequest(this.api + '/scoreCategory').then(scoreCategoryJson => {
            return jsonToScoreCategory(scoreCategoryJson);
        });
    };

    getScoreCategory = (id) => {
        return this.getRequest(this.api + '/scoreCategory/' + id);
    };

    getScoreSuperCategories = () => {
        return this.getRequest(this.api + '/scoreSuperCategory').then(scoreSuperCategoryJson => {
            return jsonToScoreSuperCategory(scoreSuperCategoryJson);
        });
    };

    getScoreSuperCategory = (id) => {
        return this.getRequest(this.api + '/scoreSuperCategory/' + id);
    };

    getProfile = (keycloak) => {
        return this.getRequest(`${this.api}/user/whoami`, keycloak).then(jsonToUserInfo);
    }

    addClientObserver = (clientObserver, keycloak) => {
        return this.postRequest(this.api + "/clientObserver", clientObserver, keycloak)
    };

    getClientObservers = (keycloak) => {
        return this.getRequest(this.api + '/clientObserver', keycloak);
    };

    getClientFromId = (id, keycloak) => {
        return this.getRequest(this.api + '/client/' + id, keycloak);
    };

    /**
     * @param {UserInfo} userInfo
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<UserInfo>}
     */
    updateProfile = (userInfo, keycloak) => {
        return this.putRequest(`${this.api}/user/${userInfo.id}`, userInfo, keycloak)
            .then(jsonToUserInfo);
    };

    changePassword = (password, keycloak) => {
        return this.putRequest(`${this.api}/user/password`, password, keycloak, false);
    }

    testPassword = (password, keycloak) => {
        return this.postRequest(`${this.api}/user/password`, password, keycloak, false);
    }

    /**
     * @param {Email} email
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<Response>}
     */
    initEmailChange = (email, keycloak) => {
        return this.putRequest(`${this.api}/user/email`, email, keycloak, false);
    }

    /**
     * @param {string} token
     * @param {Keycloak.KeycloakInstance} keycloak
     * @returns {Promise<Response>}
     */
    sendEmailChangeToken = (token, keycloak) => {
        return this.getRequest(`${this.api}/user/email/${token}`, keycloak, false);
    }

    addMeetings = (meetings, keycloak) => {
        return this.postRequestArray(this.api + '/meeting', meetings, keycloak).then(meetings => {
            meetings.forEach(meeting => jsonToMeeting(meeting));
            return meetings;
        });
    };

    defaultHeaders = (keycloak) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (keycloak) {
            headers.append('Authorization', 'Bearer ' + keycloak.token);
        }

        return headers;
    };

    formDataHeader = (keycloak) => {
        const headers = new Headers();
        if (keycloak) {
            headers.append('Authorization', 'Bearer ' + keycloak.token);
        }
        return headers;
    };

    /**
     * @param {string} url
     * @param {Keycloak.KeycloakInstance} keycloak
     * @param {boolean} [expectJson=true]
     * @returns {Promise<any>|Promise<Response>}
     */
    getRequest = (url, keycloak, expectJson) => {
        const isJsonExpected = expectJson === undefined ? true : !!expectJson;
        return fetch(url, {
            method: 'GET',
            headers: this.defaultHeaders(keycloak)
        })
            .then(this._checkHttpStatus)
            .then(response => isJsonExpected ? response.json() : response)
            .catch(this._handleError);
    };

    /**
     * @param {string} url
     * @param {object|undefined|null} body
     * @param {Keycloak} keycloak
     * @param {boolean} [expectJson=true]
     * @returns {Promise<any>}
     */
    postRequest = (url, body, keycloak, expectJson) => {
        let json = JSON.stringify({});
        if (body) json = JSON.stringify(body);
        console.log(json);
        return this.postFetch(url, json, keycloak, expectJson);
    };

    /**
     * @param {string} url
     * @param {{}[]} bodies
     * @param {Keycloak} keycloak
     * @param {boolean} [expectJson=true]
     * @returns {Promise<any>}
     */
    postRequestArray = (url, bodies, keycloak, expectJson) => {
        let json = JSON.stringify({});
        if (bodies) {
            let list = [];
            for (let body of bodies)
                list.push(body);
            json = JSON.stringify(list);
        }
        return this.postFetch(url, json, keycloak, expectJson);
    };

    /**
     * @param {string} url
     * @param {string} json
     * @param {Keycloak} keycloak
     * @param {boolean} [expectJson=true]
     * @returns {Promise<any>|Promise<Response>}
     */
    postFetch = (url, json, keycloak, expectJson) => {
        const isJsonExpected = expectJson === undefined ? true : !!expectJson;
        return fetch(url, {
            method: 'POST',
            headers: this.defaultHeaders(keycloak),
            body: json
        })
            .then(this._checkHttpStatus)
            .then(response => isJsonExpected ? response.json() : response)
            .catch(this._handleError);
    };

    /**
     * @param {string} url
     * @param {{}} body
     * @param {Keycloak} keycloak
     * @param {boolean} [expectJson=true]
     * @returns {Promise<any>|Promise<Response>}
     */
    putRequest = (url, body, keycloak, expectJson) => {
        const isJsonExpected = expectJson === undefined ? true : !!expectJson;
        return fetch(url, {
            method: 'PUT',
            headers: this.defaultHeaders(keycloak),
            body: JSON.stringify(body)
        })
            .then(this._checkHttpStatus)
            .then(response => isJsonExpected ? response.json() : response)
            .catch(this._handleError);
    };

    patchRequest = (url, body, keycloak) => {
        return fetch(url, {
            method: 'PATCH',
            headers: this.defaultHeaders(keycloak),
            body: JSON.stringify(body)
        })
            .then(this._checkHttpStatus)
            .then(response => response.json())
            .catch(this._handleError);
    };

    /**
     * @param {string} url
     * @param {File[]} files
     * @param {UploadedDocument[]} documents
     * @param {Keycloak} keycloak
     * @returns {Promise<any>}
     */
    postRequestFormData = (url, files, documents, keycloak) => {
        let body = new FormData();
        files.forEach(file => body.append('files', file));
        documents.map(document => document.toJSON())
            .map(document => JSON.stringify(document))
            .forEach(document => body.append('documents', document));
        return fetch(url, {
            method: 'POST',
            headers: this.formDataHeader(keycloak),
            body: body
        })
            .then(this._checkHttpStatus)
            .then(response => response.json())
            .catch(this._handleError);
    };

    /**
     * @param {Response} response
     * @returns {Response}
     * @private
     */
    _checkHttpStatus = (response) => {
        if (!response.ok) {
            throw new APIResponseError(response)
        } else {
            return response;
        }
    };

    /**
     * @param {Error|APIResponseError} error
     * @returns {Promise<any>}
     * @private
     */
    _handleError = async (error) => {
        if (!(error instanceof APIResponseError)) {
            throw error;
        }

        const statusCode = error.response.status;

        switch (statusCode) {
            case 400:
            case 401:
            case 403:
            case 404:
                return this._handleStandardErrorCodes(error);
            case 422:
                return this._handleUnprocessableEntityError(error);
            default:
                throw error;
        }
    };

    /**
     * @param {APIResponseError} error
     * @returns {Promise<any>}
     * @private
     */
    _handleStandardErrorCodes = error => error.response.json().then(body => {
        const response = error.response;
        const message = body.message;
        const correlationId = body.correlationId;

        throw new APIBaseError(response, message, correlationId);
    });

    /**
     * @param {APIResponseError} error
     * @returns {Promise<any>}
     * @private
     */
    _handleUnprocessableEntityError = error => error.response.json().then(body => {
        const validationResult = jsonToValidationResult(body)

        throw new APIValidationError(validationResult);
    });
}

export default new ApiService();
