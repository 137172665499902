/**
 * @deprecated
 * @type {[{name: string, key: string}]}
 */
export const languageLevels = [
    {name: "Muttersprache", key: "native"},
    {name: "A1", key: "level-A1"},
    {name: "A2", key: "level-A2"},
    {name: "B1", key: "level-B1"},
    {name: "B2", key: "level-B2"},
    {name: "C1", key: "level-C1"},
    {name: "C2", key: "level-C2"},
];