import AbstractInputField from './AbstractInputField';

export default class PasswordInputField extends AbstractInputField {
    get type() {
        return 'password';
    }
}

PasswordInputField.propTypes = {
    ...AbstractInputField.propTypes,
};