import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import CaregiverRegistration from "./views/CaregiverRegistration/CaregiverRegistration";
import LandingView from "./views/LandingView/LandingView";
import DashboardView from "./views/DashboardView/DashboardView";
import RegistrationView from "./views/RegistrationView";
import AssessmentView from "./views/AssessmentView";
import ClientRegistration from "./views/ClientRegistration/ClientRegistration";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {ReactKeycloakProvider} from '@react-keycloak/web';
import keycloak from './keycloak';
import LegalNotice from "./views/LegalNotice/LegalNotice";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import {registerLocale, setDefaultLocale} from "react-datepicker"
import KeycloakAwareRoute from "./util/KeycloakAwareRoute";

/**
 * @param {boolean} loading
 */
export const setLoading = (loading) => {
    window.__setLoading(!!loading);
}

/**
 * @returns {boolean}
 */
export const getLoading = () => window.__getLoading();

export default class App extends React.Component {
    state = {
        keycloak: undefined,
        keycloakInitialized: false,
        _loading: false
    };

    constructor(props) {
        super(props);

        registerLocale('de', de);
        registerLocale('fr', fr);
        registerLocale('it', it);
        setDefaultLocale(de);

        document.title = "IAHA";

        window.__setLoading = this.setLoading.bind(this);
        window.__getLoading = this.getLoading.bind(this);
    }

    /**
     * @param {boolean} loading
     */
    setLoading(loading) {
        this.setState({
            _loading: !!loading
        })
    }

    /**
     * @returns {boolean}
     */
    getLoading() {
        return !!this.state._loading;
    }

    /**
     * @param {string} event
     * @param {Error|undefined} error
     * @private
     */
    _onKeycloakEvent(event, error) {
        switch (event) {
            case 'onReady':
                this._onKeycloakReady(error);
                break;
            default:
                // NOP
                break;
        }
    }

    /**
     * @param {Error|undefined} error
     * @private
     */
    _onKeycloakReady(error) {
        this.setState({
            keycloakInitialized: true,
            keycloak: keycloak
        });
    }

    /**
     * @returns {JSX.Element}
     * @private
     */
    _renderSwitch() {
        return (
            <Switch>
                <Route exact path="/" component={LandingView}/>
                <KeycloakAwareRoute path="/dashboard" component={DashboardView} keycloak={this.state.keycloak}/>
                <Route exact path="/registration" component={RegistrationView}/>
                <Route path="/registration/caregiver" component={CaregiverRegistration}/>
                <Route path="/registration/client" component={ClientRegistration}/>
                <Route path="/assessment" component={AssessmentView}/>
                <Route path="/privacy-policy" component={PrivacyPolicy}/>
                <Route path="/legal-notice" component={LegalNotice}/>
            </Switch>
        )
    }

    _renderLoader() {
        return (
            <div id="loader-overlay"/>
        );
    }

    render() {
        return (
            <ReactKeycloakProvider authClient={keycloak} onEvent={this._onKeycloakEvent.bind(this)}>
                <div className="App">
                    {this.getLoading() && this._renderLoader()}
                    <Router>
                        <Header/>
                        <div style={{minHeight: '80vh'}}>
                            {this._renderSwitch()}
                        </div>
                        <footer>
                            <Footer/>
                        </footer>
                    </Router>
                </div>
            </ReactKeycloakProvider>
        );
    }
}
