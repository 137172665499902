import AbstractModel from "./AbstractModel";

export default class ValidationResultItem extends AbstractModel {
    /**
     * @type {string}
     * @private
     */
    _property = undefined;
    /**
     * @type {*}
     * @private
     */
    _invalidValue = undefined;
    /**
     * @type {string}
     * @private
     */
    _message = undefined;

    /**
     * @param {string} property
     * @param {*} invalidValue
     * @param {string} message
     */
    constructor(property, invalidValue, message) {
        super();

        this._property = property;
        this._invalidValue = invalidValue;
        this._message = message;
    }

    /**
     * @returns {string}
     */
    get property() {
        return this._property;
    }

    /**
     * @param {string} property
     */
    set property(property) {
        this._property = property;
    }

    /**
     * @returns {*}
     */
    get invalidValue() {
        return this._invalidValue;
    }

    /**
     * @param {*} invalidValue
     */
    set invalidValue(invalidValue) {
        this._invalidValue = invalidValue;
    }

    /**
     * @returns {string}
     */
    get message() {
        return this._message;
    }

    /**
     * @param {string} message
     */
    set message(message) {
        this._message = message;
    }

    toJSON() {
        return {
            property: this._property,
            invalidValue: this._invalidValue,
            message: this._message
        };
    }

    /**
     * @returns {ValidationResultItem}
     */
    clone() {
        return this._clone(new ValidationResultItem());
    }
}