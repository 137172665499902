import {Route, Switch} from "react-router-dom";
import DashboardProfile from "./DashboardProfile";
import DashboardMagazine from "./DashboardMagazine";
import DashboardPassword from "./DashboardPassword";
import DashboardLandingCaregiver from "./DashboardLandingCaregiver";
import DashboardUpload from "./DashboardUpload";
import DashboardAvailability from "./DashboardAvailability";
import React from "react";
import PropTypes from "prop-types";
import UserInfo from "../../models/UserInfo";
import DashboardLanguages from "./DashboardLanguages";
import DashboardEmail from "./DashboardEmail";

/**
 * @param {UserInfo} userInfo
 * @returns {JSX.Element}
 */
export default function DashboardCaregiverRouter({userInfo}) {
    return (
        <Route path="/dashboard">
            <Switch>
                <Route exact={true}
                       path="/dashboard"
                       render={() => <DashboardLandingCaregiver userInfo={userInfo}/>}/>
                <Route exact={true}
                       path="/dashboard/profile"
                       render={() => <DashboardProfile isClient={false} userId={userInfo.relatedEntityId}/>}/>
                <Route exact={true}
                       path="/dashboard/staff-magazine"
                       render={() => <DashboardMagazine userId={userInfo.relatedEntityId}/>}/>
                <Route exact={true}
                       path="/dashboard/languages"
                       render={() => <DashboardLanguages isClient={false} userId={userInfo.relatedEntityId}/>}/>
                <Route path="/dashboard/password" render={() => <DashboardPassword userInfo={userInfo}/>}/>
                <Route path="/dashboard/email" render={() => <DashboardEmail userInfo={userInfo}/>}/>
                <Route path="/dashboard/upload" render={() => <DashboardUpload userInfo={userInfo}/>}/>
                <Route path="/dashboard/availability" render={() => <DashboardAvailability userInfo={userInfo}/>}/>
            </Switch>
        </Route>
    );
};

DashboardCaregiverRouter.propTypes = {
    userInfo: PropTypes.instanceOf(UserInfo).isRequired
}