import React from 'react';
import Feedback from './Feedback';
import {useFormikContext} from 'formik';
import AbstractForm from './AbstractForm';
import i18next from "i18next";
import APIValidationError from "../../services/APIValidationError";
import APIBaseError from "../../services/APIBaseError";

/**
 * @param {Error|APIBaseError|APIValidationError} error
 * @returns {JSX.Element|string|undefined}
 */
function getMessageByError(error) {
    switch (true) {
        case error instanceof APIValidationError:
            return error.message.length > 0 ? error.message : i18next.t('form.error.validation');
        case error instanceof APIBaseError:
            const correlationId = error.correlationId;
            const message = error.message ?? i18next.t('form.error.default');

            return correlationId ? (
                <>
                    <p>{message}</p>
                    <hr/>
                    <p className="mb-0">{i18next.t('form.error.correlationId', {id: correlationId})}</p>
                </>
            ) : message;
        default:
            return undefined;
    }
}

export default function Failed({children}) {
    const formik = useFormikContext();
    const defaultMessage = children ?? i18next.t('form.submit.failed');
    const error = formik[AbstractForm.FORM_SUBMIT_SUCCESS];
    const message = getMessageByError(error) ?? defaultMessage;

    return (<Feedback type="error">{message}</Feedback>);
}