import {Route, Switch} from "react-router-dom";
import DashboardProfile from "./DashboardProfile";
import {DashBoardClientRelated} from "./DashBoardClientRelated";
import DashboardPassword from "./DashboardPassword";
import DashboardLandingClient from "./DashboardLandingClient";
import React from "react";
import PropTypes from "prop-types";
import UserInfo from "../../models/UserInfo";

/**
 * @param {UserInfo} userInfo
 * @returns {JSX.Element}
 */
export default function DashboardClientRouter({userInfo}) {
    return (
        <Route path="/dashboard">
            <Switch>
                <Route exact={true}
                       path="/dashboard"
                       render={() => <DashboardLandingClient userInfo={userInfo}/>}/>
                <Route exact={true}
                       path="/dashboard/profile"
                       render={() => <DashboardProfile isClient={true} userId={userInfo.relatedEntityId}/>}/>
                <Route path="/dashboard/client-related" component={DashBoardClientRelated}/>
                <Route path="/dashboard/password" render={() => <DashboardPassword userInfo={userInfo}/>}/>
                <Route path="/dashboard/self-declaration" render={() => (<></>)}/>
            </Switch>
        </Route>
    )
};

DashboardClientRouter.propTypes = {
    userInfo: PropTypes.instanceOf(UserInfo).isRequired
}
