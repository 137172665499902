import React from 'react';
import AbstractField from './AbstractField';
import PropTypes from 'prop-types';

/**
 * @abstract
 */
export default class AbstractInputField extends AbstractField {
    /**
     * @type {string}
     */
    static ENTER_KEY = 'Enter';

    /**
     * @abstract
     * @returns {string}
     */
    get type() {
        throw new Error('not implemented');
    }

    /**
     * @returns {string}
     */
    get placeholder() {
        return this.props.placeholder ?? '';
    }

    /**
     * @returns {string}
     */
    get autoComplete() {
        return this.props.autoComplete ?? 'new-password'; // see https://stackoverflow.com/questions/60689757/how-to-disable-chrome-autofill-after-2020
    }

    renderField({field, form, meta}) {
        return (
            <input type={this.type}
                   name={field.name}
                   id={this.id}
                   value={field.value ?? ''}
                   onChange={event => this.onChange(field, form, event)}
                   onBlur={field.onBlur}
                   onKeyDown={this._onKeyDown.bind(this)}
                   autoComplete={this.autoComplete}
                   disabled={this.disabled(field.value)}/>
        );
    }

    /**
     * @param {React.KeyboardEvent<HTMLInputElement>} event
     * @private
     */
    _onKeyDown(event) {
        if (event.key === AbstractInputField.ENTER_KEY) {
            event.preventDefault();
        }
    }
}

AbstractInputField.propTypes = {
    ...AbstractField.propTypes,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string
};