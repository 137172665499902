import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {Fade} from "react-bootstrap";
import AbstractForm from '../AbstractForm';

/** @typedef {import('formik').FieldInputProps} FieldInputProps */
/** @typedef {import('formik').FieldMetaProps} FieldMetaProps */
/** @typedef {import('formik').FormikProps} FormikProps */

/**
 * @abstract
 */
export default class AbstractData extends React.Component {
    /**
     * @returns {string}
     */
    get name() {
        return this.props.name;
    }

    /**
     * @returns {(function(any): boolean)}
     */
    get hidden() {
        const prop = this.props.hidden ?? (() => false);
        return typeof prop === 'function' ? prop : (() => !!prop);
    }

    /**
     * @param {FieldInputProps} field
     * @param {FormikProps} form
     * @param {FieldMetaProps} meta
     * @returns {JSX.Element}
     * @abstract
     */
    renderData({field, form, meta}) {
        throw new Error('not implemented');
    }

    render() {
        return (
            <Field name={this.name}>
                {props => (
                    <Fade in={!props.form[AbstractForm.FORM_INITIAL_LOAD]}>
                        <span hidden={this.hidden(props.field.value)}>{this.renderData(props)}</span>
                    </Fade>
                )}
            </Field>
        );
    }
}

AbstractData.propTypes = {
    name: PropTypes.string.isRequired,
    hidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}