import React from "react";
import {Col, Row} from "react-bootstrap";
import "../../views/DashboardView/DashboardView.css";
import {Link} from "react-router-dom";
import keycloak from "../../keycloak";
import ApiService from "../../services/ApiService";
import UserInfo from "../../models/UserInfo";

export class DashBoardClientRelated extends React.Component {
    state = {
        observers: [],
        ListView: null,
        selectedObserver: new UserInfo(),
        CurrentUser: new UserInfo(),
        CurrentUserAnrede: null,
    };

    async componentWillMount() {
        var keycloak_id = keycloak.subject
        ApiService.getClients(keycloak).then
        ((clients) => {
            clients.forEach(client => {
                if (keycloak_id == client.userInfo.userId) {
                    this.setState({CurrentUser: client.userInfo})
                    switch (this.state.CurrentUser.gender) {
                        case "male":
                            this.setState({CurrentUserAnrede: "Herr"})
                            break;
                        case "female":
                            this.setState({CurrentUserAnrede: "Frau"})
                            break;
                        case "other":
                            this.setState({CurrentUserAnrede: "Andere"})
                            break;
                    }
                    ApiService.getClientFromId(client.id, keycloak).then((client => {
                        this.setState({observers: client.clientObservers})
                    }))
                }
            })
        })
    }

    SelectUser(SelectedUser) {
        this.setState({selectedObserver: SelectedUser.userInfo})
    }


    _handleChangeGender = (event) => {
        let SelectedUser = this.state.selectedObserver;
        SelectedUser.gender = event.target.value
        this.setState({selectedObserver: SelectedUser})
    }

    _handleChange = (event, number) => {
        let SelectedUser = this.convertToDictionary(this.state.selectedObserver);
        SelectedUser[number]["value"] = event.target.value;
        let test = Object.values(SelectedUser);
        console.log(test)
    }

    convertToDictionary(obj) {
        return Object.keys(obj).map(key => ({
            key: key,
            value: obj[key],
        }));
    }


    async PushChanges() {
        let clientObserverPayload = {
            "client": {"id": this.state.CurrentUser.id},
            "userInfo": this.state.selectedObserver
        }
        console.log(clientObserverPayload)
        await ApiService.addClientObserver(clientObserverPayload, keycloak)
    }

    render() {
        let buttonAdd
        if (this.state.selectedObserver.id != null) {
            buttonAdd = <button className="grayBoxStyle" style={{borderRadius: "10px"}}
                                onClick={() => this.PushChanges()}>Bestätigen</button>
        } else {
            buttonAdd = <button className="grayBoxStyle" style={{borderRadius: "10px"}}
                                onClick={() => this.PushChanges()}>Hinzufügen</button>
        }
        return (
            <div>
                <h2>Angehörige Person</h2>
                <hr/>
                {this.state.selectedObserver != null ?
                    <div>
                        <div className="grayBoxStyle">
                            <p style={{margin: 0}}>{this.state.CurrentUserAnrede}</p>
                            <p style={{margin: 0}}>{this.state.CurrentUser.firstName} {this.state.CurrentUser.lastName}</p>
                        </div>
                        <ProfileRow title={"Rolle"} data={"Rolle"}/>
                        <Row>
                            <Col xs={4}>
                                <div className="grayBoxStyle">
                                    Geschlecht
                                </div>
                            </Col>
                            <Col>
                                <div className="whiteBoxStyle">
                                    <select style={{border: "solid transparent", width: "350px"}}
                                            value={this.state.selectedObserver.gender}
                                            onChange={this._handleChangeGender}>
                                        <option value="male">Männlich</option>
                                        <option value="female">Weiblich</option>
                                        <option value="other">Sonstiges</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <ProfileRow title={"Vorame"} data={this.state.selectedObserver.firstName} method={e => {
                            e.persist();
                            this.setState(() => {
                                let Observer = Object.assign({}, this.state.selectedObserver);
                                Observer.firstName = e.target.value;
                                this.setState({selectedObserver: Observer});
                            })
                        }}/>
                        <ProfileRow title={"Name"} data={this.state.selectedObserver.lastName} method={e => {
                            e.persist();
                            this.setState(() => {
                                let Observer = Object.assign({}, this.state.selectedObserver);
                                Observer.lastName = e.target.value;
                                this.setState({selectedObserver: Observer});
                            })
                        }}/>
                        <ProfileRow title={"Adresse"} data={this.state.selectedObserver.streetAndNr} method={e => {
                            e.persist();
                            this.setState(() => {
                                let Observer = Object.assign({}, this.state.selectedObserver);
                                Observer.streetAndNr = e.target.value;
                                this.setState({selectedObserver: Observer});
                            })
                        }}/>
                        <ProfileRow title={"PLZ"} data={this.state.selectedObserver.zipcode} method={e => {
                            e.persist();
                            this.setState(() => {
                                let Observer = Object.assign({}, this.state.selectedObserver);
                                Observer.zipcode = e.target.value;
                                this.setState({selectedObserver: Observer});
                            })
                        }}/>
                        <ProfileRow title={"Ort"} data={this.state.selectedObserver.city} method={e => {
                            e.persist();
                            this.setState(() => {
                                let Observer = Object.assign({}, this.state.selectedObserver);
                                Observer.city = e.target.value;
                                this.setState({selectedObserver: Observer});
                            })
                        }}/>
                        <ProfileRow title={"Telefon"} data={this.state.selectedObserver.phoneNumber} method={e => {
                            e.persist();
                            this.setState(() => {
                                let Observer = Object.assign({}, this.state.selectedObserver);
                                Observer.phoneNumber = e.target.value;
                                this.setState({selectedObserver: Observer});
                            })
                        }}/>
                        <ProfileRow title={"Mobile"} data={this.state.selectedObserver.mobileNumber} method={e => {
                            e.persist();
                            this.setState(() => {
                                let Observer = Object.assign({}, this.state.selectedObserver);
                                Observer.mobileNumber = e.target.value;
                                this.setState({selectedObserver: Observer});
                            })
                        }}/>
                        <ProfileRow title={"E-Mail"} data={this.state.selectedObserver.email} method={e => {
                            e.persist();
                            this.setState(() => {
                                let Observer = Object.assign({}, this.state.selectedObserver);
                                Observer.email = e.target.value;
                                this.setState({selectedObserver: Observer});
                            })
                        }}/>
                        {buttonAdd}
                    </div> : null
                }
                <br/>
                <br/>
                <h3 className="headerStyle">Angehörige Personen</h3>
                <hr/>
                {this.state.observers.map(clientObserver =>
                    <div>
                        <Row style={{marginLeft: "0px"}}>
                            <Col className="grayBoxStyle separatorMargin separatorMarginRight" xs={2}>
                                <p className="RelatedText">{clientObserver.userInfo.lastName}</p>
                            </Col>
                            <Col className="grayBoxStyle separatorMargin separatorMarginRight" xs={2}>
                                <p className="RelatedText">{clientObserver.userInfo.firstName}</p>
                            </Col>
                            <Col className="grayBoxStyle separatorMargin separatorMarginRight" xs={2}>
                                <p className="RelatedText">Rolle</p>
                            </Col>
                            <Col xs={2} className={"RelatedButton"}>
                                <Link onClick={() => this.SelectUser(clientObserver)}>
                                    Bearbeiten
                                </Link>
                            </Col>
                            <Col xs={1} className={"RelatedButton"}>
                                |
                            </Col>
                            <Col xs={1} className={"RelatedButton"}>
                                <Link>
                                    Löschen
                                </Link>
                            </Col>
                        </Row>
                    </div>)}
            </div>
        );
    }
}

const ProfileRow = (props) => {
    return (
        <Row>
            <Col xs={4}>
                <div className="grayBoxStyle">
                    {props.title}
                </div>
            </Col>
            <Col>
                <div className="whiteBoxStyle">
                    <input value={props.data} style={{border: "solid transparent", width: "350px"}}
                           onChange={props.method}/>
                </div>
            </Col>
        </Row>
    )
};
