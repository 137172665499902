/**
 * @deprecated
 */
export default class Salutation {
    /**
     * @type {Map<string,string>}
     * @private
     */
    static _genderMap = new Map([
        ['male', Salutation.MALE],
        ['female', Salutation.FEMALE],
        ['other', Salutation.OTHER],
    ]);

    /**
     * @returns {string}
     */
    static get MALE() {
        return 'Herr';
    }

    /**
     * @returns {string}
     */
    static get FEMALE() {
        return 'Frau';
    }

    /**
     * @returns {undefined}
     */
    static get OTHER() {
        return undefined;
    }

    /**
     * @param {string} gender
     * @returns {string|undefined}
     */
    static resolve(gender) {
        return Salutation._genderMap.get(gender);
    }
}