import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';

export default function Button({children, disabled, onClick}) {
    return (
        <Row>
            <Col>
                <button type="button" disabled={!!(disabled ?? false)} className="grayBoxStyle" onClick={onClick}>
                    {children}
                </button>
            </Col>
        </Row>
    );
}

Button.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};