import 'react-phone-number-input/style.css'
import './PhoneNumberField.css';

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'
import de from 'react-phone-number-input/locale/de';
import fr from 'react-phone-number-input/locale/fr';
import it from 'react-phone-number-input/locale/it';
import AbstractField from './AbstractField';
import {validPhoneNumber} from './validators';

export default class PhoneNumberField extends AbstractField {
    /**
     * @type {Map<string,{}>}
     */
    static I18N = new Map([
        ['de', de],
        ['de-CH', de],
        ['fr', fr],
        ['fr-CH', fr],
        ['it', it],
        ['it-CH', it]
    ]);

    get placeholder() {
        return this.props.placeholder ?? i18next.t('pleaseEnterPhoneNumber');
    }

    get defaultCountry() {
        return this.props.defaultCountry ?? 'CH';
    }

    get validate() {
        return this.props.validate ?? [validPhoneNumber];
    }

    renderField({field, form, meta}) {
        return (
            <PhoneInput name={field.name}
                        flags={flags}
                        placeholder={this.placeholder}
                        defaultCountry={this.defaultCountry}
                        international={true}
                        value={field.value ?? ''}
                        onChange={value => form.setFieldValue(field.name, value ?? '')}
                        onBlur={form.handleBlur}
                        autoComplete="new-password"
                        labels={this._getTranslatedLabels()}
                        disabled={this.disabled(field.value)}/>
        );
    }

    _getTranslatedLabels() {
        const language = i18next.language;

        return PhoneNumberField.I18N.get(language) ?? de;
    }
}

PhoneNumberField.propTypes = {
    ...AbstractField.propTypes,
    placeholder: PropTypes.string,
    defaultCountry: PropTypes.string
}