import AbstractField from './AbstractField';
import DatePicker from 'react-datepicker';
import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

export default class DateField extends AbstractField {
    /**
     * @type {Map<string,{}>}
     */
    static I18N = new Map([
        ['de', 'de'],
        ['de-CH', 'de'],
        ['fr', 'fr'],
        ['fr-CH', 'fr'],
        ['it', 'it'],
        ['it-CH', 'it']
    ]);

    /**
     * @returns {string}
     */
    get placeholderText() {
        return this.props.placeholderText ?? i18next.t('pleaseSelectDate');
    }

    get locale() {
        return DateField.I18N.get(i18next.language) ?? 'de';
    }

    renderField({field, form, meta}) {
        return (
            <DatePicker selected={field.value}
                        onBlur={form.handleBlur}
                        onChange={date => form.setFieldValue(field.name, date)}
                        dateFormat="dd.MM.yyyy"
                        locale={this.locale}
                        placeholderText={this.placeholderText}
                        disabled={this.disabled(field.value)}/>
        );
    }
}

DateField.propTypes = {
    ...AbstractField.propTypes,
    placeholderText: PropTypes.string
}