import React from "react";
import AssessmentStart from "../components/assessment/AssessmentStart";
import AssessmentForm from "../components/assessment/AssessmentForm";
import AssessmentFinish from "../components/assessment/AssessmentFinish";
import {withKeycloak} from "@react-keycloak/web";

class AssessmentView extends React.Component {

    constructor(props) {
        super(props);
        //steps: profile, declaration, assessment
        this.state = {
            step: 'start',
        };

        this.setStep = this.setStep.bind(this);
    }

    setStep(step) {
        this.setState({step: step});
    }

    renderStep() {
        switch (this.state.step) {
            case "assessment":
                return <AssessmentForm setStep={this.setStep}/>;
            case "assessmentFinish":
                return <AssessmentFinish setStep={this.setStep}/>;
            default:
                return <AssessmentStart setStep={this.setStep}/>;
        }
    }

    render() {
        if (this.props.keycloak && this.props.keycloakInitialized) {
            if (this.props.keycloak.authenticated) {
                return (
                    <div className="d-flex justify-content-center mainDiv">
                        <div style={{width: 800}}>
                            <h5 style={{margin: '40px 0'}}>Online Assessment</h5>
                            {this.renderStep()}
                        </div>
                    </div>
                )
            } else {
                this.props.keycloak.login();
                return (<div>Unable to authenticate!</div>)
            }
        }
        return (<div></div>);
    }
}

export default withKeycloak(AssessmentView);
