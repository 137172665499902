/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class CivilStatus extends AbstractEnum {
    /**
     * @type{Map<String,CivilStatus>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{CivilStatus[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        CivilStatus._lookupMap.set(id, this);
        CivilStatus._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'CivilStatus';
    }

    /**
     * @param {string} id
     * @returns {CivilStatus|undefined}
     * @public
     */
    static valueOf(id) {
        return CivilStatus._lookupMap.get(id);
    }

    /**
     * @returns {CivilStatus[]}
     * @public
     */
    static values() {
        return [...CivilStatus._values];
    }

    /**
     * @type{CivilStatus}
     * @public
     */
    static SINGLE = new CivilStatus('single');
    /**
     * @type{CivilStatus}
     * @public
     */
    static MARRIED = new CivilStatus('married');
    /**
     * @type{CivilStatus}
     * @public
     */
    static DIVORCED = new CivilStatus('divorced');
    /**
     * @type{CivilStatus}
     * @public
     */
    static WIDOWED = new CivilStatus('widowed');
}