import React from "react";
import '../../App.css';
import {Button} from "react-bootstrap";

class CaregiverRegistrationDeclarationIntro extends React.Component {

    componentDidMount() {
        this.props.setValidate(null);
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: 40}}>
                    Nun folgt eine Selbstdeklaration und Einschätzung Ihre erworbenen Kompetenzen im
                    Bereich der Pflege und Betreuung. Bitte ergänzen Sie Ihre Antworten wo möglich und vorhanden
                    mit einem Nachweis, den Sie bitte im vorgesehenen Bereich hochladen.
                </div>
                <div>
                    <Button style={{marginRight: 20}} onClick={() => this.props.setStep('profile')}
                            className={'button'}>
                        Zurück
                    </Button>
                    <Button className={'button'} onClick={() => this.props.setStep('declaration')}>Weiter</Button>
                </div>
            </div>
        );
    }
}

export default CaregiverRegistrationDeclarationIntro;
