import React from "react";
import {Form} from "react-bootstrap";

class CheckboxField extends React.Component {

    render() {
        return (
            <Form.Group style={{fontWeight: 'bold'}}>
                <Form.Check
                    required={this.props.required}
                    name={this.props.name}
                    label={this.props.question.question[0].text + this.renderRequired()}
                    //onChange={(answer) => this.props.setAnswer(this.props.question.id, answer)}
                    id="validationFormik0"
                />
            </Form.Group>
        );
    }

    renderRequired() {
        if (this.props.required) return '*';
        return '';
    }
}

export default CheckboxField;
