import React from 'react';
import '../../App.css';
import imageDatenschutz from '../../assets/images/privacy-policy-legal-notice-header.jpg'

class PrivacyPolicy extends React.Component {

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row pb-xl-5">
                        <div className="col-md-12">
                            <img src={imageDatenschutz}
                                 alt="Privacy policy and legal notice" className="img-fluid mb-5"/>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-12 ml-auto mr-auto">
                            <h1>Datenschutzerklärung</h1>

                            <div className="entry mb-5">
                                <p>Mit dieser <strong>Datenschutzerklärung</strong> informieren wir, welche
                                    Personendaten
                                    wir wofür, wie und wo bearbeiten, insbesondere im Zusammenhang mit
                                    unserer <strong>https://www.iaha.ch-Website</strong> und unserem sonstigen Angebot.
                                    Wir
                                    informieren mit dieser Datenschutzerklärung ausserdem über die Rechte von Personen,
                                    deren Daten wir bearbeiten.</p>

                                <p>Für einzelne oder zusätzliche Angebote und Leistungen können besondere, ergänzende
                                    oder
                                    weitere Datenschutzerklärungen sowie sonstige rechtliche Dokumente wie Allgemeine
                                    Geschäftsbedingungen (AGB), Nutzungsbedingungen oder Teilnahmebedingungen
                                    gelten.</p>

                                <p>Unser Angebot unterliegt dem schweizerischen Datenschutzrecht sowie allenfalls
                                    anwendbarem ausländischem Datenschutzrecht wie insbesondere jenem der Europäischen
                                    Union
                                    (EU) mit der Datenschutz-Grundverordnung (DSGVO). Die <a
                                        href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:32000D0518"
                                        target="_blank" rel="noopener noreferrer">Europäische Kommission</a> anerkennt,
                                    dass das schweizerische
                                    Datenschutzrecht einen angemessenen Datenschutz gewährleistet.</p>

                                <h2 id="kontaktadressen">1. Kontaktadressen</h2>

                                <p>Verantwortung für die Bearbeitung von Personendaten:</p>

                                <p><strong id="verantwortlicher">Barbara Radtke</strong>
                                    <br/>
                                    Seefeldstrasse 62 <br/>
                                    8008 Zürich <br/>
                                    Schweiz
                                </p>

                                <p><a href="mailto:info@iaha.ch">info@iaha.ch</a></p>

                                <p>Wir weisen darauf hin, wenn es im Einzelfall andere Verantwortliche für die
                                    Bearbeitung
                                    von Personendaten gibt.</p>

                                <h2 id="bearbeitung">2. Bearbeitung von Personendaten</h2>

                                <h3 id="begriffe">2.1 Begriffe</h3>

                                <p><strong>Personendaten</strong> sind alle Angaben, die sich auf eine bestimmte oder
                                    bestimmbare Person beziehen. Eine <strong>betroffene Person</strong> ist eine
                                    Person,
                                    über die Personendaten bearbeitet
                                    werden. <strong>Bearbeiten</strong> umfasst <em>jeden</em> Umgang mit
                                    Personendaten, <em>unabhängig</em> von den angewandten Mitteln und Verfahren,
                                    insbesondere das Aufbewahren, Bekanntgeben, Beschaffen, Erheben, Löschen, Speichern,
                                    Verändern, Vernichten und Verwenden von Personendaten.</p>

                                <p>Der <strong>Europäische Wirtschaftsraum (EWR)</strong> umfasst die Europäische Union
                                    (EU)
                                    sowie das Fürstentum Liechtenstein, Island und Norwegen. Die
                                    Datenschutz-Grundverordnung
                                    (DSGVO) bezeichnet die Bearbeitung von Personendaten als Verarbeitung von
                                    personenbezogenen Daten.</p>

                                <h3 id="rechtsgrundlagen">2.2 Rechtsgrundlagen</h3>

                                <p>Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht wie
                                    insbesondere dem <a
                                        href="https://www.admin.ch/opc/de/classified-compilation/19920153/index.html"
                                        target="_blank" id="38171" rel="noopener noreferrer">Bundesgesetz über den
                                        Datenschutz</a> (DSG) und der <a
                                        href="https://www.admin.ch/opc/de/classified-compilation/19930159/index.html"
                                        target="_blank" rel="noopener noreferrer">Verordnung zum Bundesgesetz über den
                                        Datenschutz</a> (VDSG).</p>

                                <p><span id="dsgvo">Wir bearbeiten – sofern und soweit die Datenschutz-Grundverordnung (DSGVO) anwendbar ist – Personendaten gemäss <em>mindestens</em> einer der folgenden Rechtsgrundlagen:</span>
                                </p>

                                <ul>

                                    <li><a href="https://steigerlegal.ch/dsgvo/dsgvo-6/"
                                           rel="nofollow noopener noreferrer"
                                           target="_blank">Art. 6 Abs. 1 lit. b DSGVO</a> für die erforderliche
                                        Bearbeitung
                                        von Personendaten zur Erfüllung eines Vertrages mit der betroffenen Person sowie
                                        zur
                                        Durchführung vorvertraglicher Massnahmen.
                                    </li>

                                    <li>Art. 6 Abs. 1 lit. f DSGVO für die erforderliche Bearbeitung von Personendaten,
                                        um
                                        die berechtigten Interessen von uns oder von Dritten zu wahren, sofern nicht die
                                        Grundfreiheiten und Grundrechte sowie Interessen der betroffenen Person
                                        überwiegen.
                                        Berechtigte Interessen sind insbesondere unser Interesse, unser Angebot
                                        dauerhaft,
                                        nutzerfreundlich, sicher und zuverlässig bereitstellen sowie dafür bei Bedarf
                                        werben
                                        zu können, die Informationssicherheit sowie der Schutz vor Missbrauch und
                                        unbefugter
                                        Nutzung, die Durchsetzung von eigenen rechtlichen Ansprüchen und die Einhaltung
                                        von
                                        schweizerischem Recht.
                                    </li>

                                    <li>Art. 6 Abs. 1 lit. c DSGVO für die erforderliche Bearbeitung von Personendaten
                                        zur
                                        Erfüllung einer rechtlichen Verpflichtung, der wir gemäss allenfalls anwendbarem
                                        Recht von Mitgliedstaaten im Europäischen Wirtschaftsraum (EWR) unterliegen.
                                    </li>

                                    <li>Art. 6 Abs. 1 lit. e DSGVO für die erforderliche Bearbeitung von Personendaten
                                        zur
                                        Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt.
                                    </li>

                                    <li>Art. 6 Abs. 1 lit. a DSGVO für die Bearbeitung von Personendaten mit
                                        Einwilligung
                                        der betroffenen Person.
                                    </li>

                                    <li>Art. 6 Abs. 1 lit. d DSGVO für die erforderliche Bearbeitung von Personendaten,
                                        um
                                        lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen
                                        Person zu schützen.
                                    </li>

                                </ul>

                                <h3 id="art-umfang-zweck">2.3 Art, Umfang und Zweck</h3>

                                <p>Wir bearbeiten jene Personendaten, die <strong>erforderlich</strong> sind, um unser
                                    Angebot dauerhaft, nutzerfreundlich, sicher und zuverlässig bereitstellen zu können.
                                    Solche Personendaten können in die Kategorien von Bestandes- und Kontaktdaten,
                                    Browser-
                                    und Gerätedaten, Inhaltsdaten, Meta- beziehungsweise Randdaten und Nutzungsdaten,
                                    Standortdaten, Verkaufs-, Vertrags- und Zahlungsdaten fallen.</p>

                                <p>Wir bearbeiten Personendaten während jener <strong>Dauer</strong>, die für den
                                    jeweiligen
                                    Zweck beziehungsweise die jeweiligen Zwecke oder gesetzlich erforderlich ist.
                                    Personendaten, deren Bearbeitung nicht mehr erforderlich ist, werden anonymisiert
                                    oder
                                    gelöscht. Personen, deren Daten wir bearbeiten, haben <em>grundsätzlich</em> ein
                                    Recht
                                    auf Löschung.</p>

                                <p>Wir bearbeiten Personendaten <em>grundsätzlich</em> nur nach Einwilligung der
                                    betroffenen
                                    Person, es sei denn, die Bearbeitung ist aus anderen rechtlichen Gründen zulässig,
                                    beispielsweise zur Erfüllung eines Vertrages mit der betroffenen Person und für
                                    entsprechende vorvertragliche Massnahmen, um unsere überwiegenden berechtigten
                                    Interessen zu wahren, weil die Bearbeitung aus den Umständen ersichtlich ist oder
                                    nach
                                    vorgängiger Information.</p>

                                <p>In diesem Rahmen bearbeiten wir insbesondere Angaben, die eine betroffene Person bei
                                    der
                                    Kontaktaufnahme – beispielsweise per Briefpost, E-Mail, Kontaktformular, Social
                                    Media
                                    oder Telefon – oder bei der Registrierung für ein Nutzerkonto <em>freiwillig und
                                        selbst</em> an uns übermittelt. Wir können solche Angaben beispielsweise in
                                    einem
                                    Adressbuch oder mit vergleichbaren Hilfsmitteln speichern. Sofern Sie Personendaten
                                    über
                                    Dritte an uns übermitteln, sind Sie verpflichtet, den Datenschutz gegenüber solchen
                                    Dritten zu gewährleisten sowie die Richtigkeit solcher Personendaten
                                    sicherzustellen.
                                </p>

                                <p>Wir bearbeiten ausserdem Personendaten, die wir von Dritten erhalten, aus öffentlich
                                    zugänglichen Quellen beschaffen oder bei der Bereitstellung unseres Angebotes
                                    erheben,
                                    sofern und soweit eine solche Bearbeitung aus rechtlichen Gründen zulässig ist.</p>

                                <h3 id="dritte">2.4 Bearbeitung von Personendaten durch Dritte, auch im Ausland</h3>

                                <p>Wir können Personendaten durch beauftragte Dritte bearbeiten lassen oder gemeinsam
                                    mit
                                    Dritten sowie mit Hilfe von Dritten bearbeiten oder an Dritte übermitteln. Bei
                                    solchen
                                    Dritten handelt es sich insbesondere um Anbieter, deren Leistungen wir in Anspruch
                                    nehmen. Wir gewährleisten auch bei solchen Dritten einen angemessenen
                                    Datenschutz.</p>

                                <p>Solche Dritte befinden sich <em>grundsätzlich</em> in der Schweiz sowie im
                                    Europäischen
                                    Wirtschaftsraum (EWR). Solche Dritte können sich aber auch in sonstigen Staaten und
                                    Territorien auf der Erde sowie anderswo im Universum befinden, sofern deren
                                    Datenschutzrecht nach <a
                                        href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html"
                                        rel="nofollow noopener noreferrer" target="_blank">Einschätzung des
                                        Eidgenössischen
                                        Datenschutz- und Öffentlichkeitsbeauftragten</a> (EDÖB) und – sofern und soweit
                                    die
                                    Datenschutz-Grundverordnung (DSGVO) anwendbar ist – nach <a
                                        href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_de"
                                        rel="nofollow noopener noreferrer" target="_blank">Einschätzung der Europäischen
                                        Kommission</a> – einen angemessenen Datenschutz gewährleistet, oder wenn aus
                                    anderen
                                    Gründen, wie beispielsweise durch eine entsprechende vertragliche Vereinbarung,
                                    insbesondere auf Grundlage von Standardvertragsklauseln, oder durch eine
                                    entsprechende
                                    Zertifizierung, ein angemessener Datenschutz gewährleistet ist. Ausnahmsweise kann
                                    sich
                                    ein solcher Dritter in einem Land ohne angemessenen Datenschutz befinden, sofern
                                    dafür
                                    die datenschutzrechtlichen Voraussetzungen wie beispielsweise die ausdrückliche
                                    Einwilligung der betroffenen Person erfüllt werden.</p>

                                <h2 id="rechte">3. Rechte von betroffenen Personen</h2>

                                <p>Betroffene Personen, deren Personendaten wir bearbeiten, verfügen über die Rechte
                                    gemäss
                                    schweizerischem Datenschutzrecht. Dazu zählen das Recht auf Auskunft sowie das Recht
                                    auf
                                    Berichtigung, Löschung oder Sperrung der bearbeiteten Personendaten.</p>

                                <p>Betroffene Personen, deren Personendaten wir bearbeiten, können – sofern und soweit
                                    die
                                    Datenschutz-Grundverordnung (DSGVO) anwendbar ist – unentgeltlich eine Bestätigung,
                                    ob
                                    wir ihre Personendaten bearbeiten und, falls ja, Auskunft über die Bearbeitung ihrer
                                    Personendaten verlangen, die Bearbeitung ihrer Personendaten einschränken lassen,
                                    ihr
                                    Recht auf Datenübertragbarkeit wahrnehmen sowie ihre Personendaten berichtigen,
                                    löschen
                                    («Recht auf Vergessen»), sperren oder vervollständigen lassen.</p>

                                <p>Betroffene Personen, deren Personendaten wir bearbeiten, können – sofern und soweit
                                    die
                                    DSGVO anwendbar ist – eine erteilte Einwilligung jederzeit mit Wirkung für die
                                    Zukunft
                                    widerrufen und jederzeit Widerspruch gegen die Bearbeitung ihrer Personendaten
                                    erheben.</p>

                                <p>Betroffene Personen, deren Personendaten wir bearbeiten, verfügen über ein
                                    Beschwerderecht bei einer zuständigen Aufsichtsbehörde. Aufsichtsbehörde für den
                                    Datenschutz in der Schweiz ist der <a
                                        href="https://www.edoeb.admin.ch/edoeb/de/home/der-edoeb/kontakt.html"
                                        rel="nofollow noopener noreferrer" target="_blank">Eidgenössische Datenschutz-
                                        und
                                        Öffentlichkeitsbeauftragte</a> (EDÖB).</p>

                                <h2 id="sicherheit">4. Datensicherheit</h2>

                                <p>Wir treffen angemessene sowie geeignete technische und organisatorische Massnahmen,
                                    um
                                    den Datenschutz und insbesondere die Datensicherheit zu gewährleisten. Allerdings
                                    kann
                                    die Bearbeitung von Personendaten im Internet trotz solcher Massnahmen immer
                                    Sicherheitslücken aufweisen. Wir können deshalb keine absolute Datensicherheit
                                    gewährleisten.</p>

                                <p>Der Zugriff auf unser Online-Angebot erfolgt mittels Transportverschlüsselung (SSL /
                                    TLS,
                                    insbesondere mit dem Hypertext Transfer Protocol Secure, abgekürzt HTTPS). Die
                                    meisten
                                    Browser kennzeichnen Transportverschlüsselung mit einem Vorhängeschloss in der
                                    Adressleiste.</p>

                                <p>Der Zugriff auf unser Online-Angebot unterliegt – wie <em>grundsätzlich</em> jede
                                    Internet-Nutzung – der anlasslosen und verdachtsunabhängigen Massenüberwachung sowie
                                    sonstigen Überwachung durch Sicherheitsbehörden in der Schweiz, in der Europäischen
                                    Union (EU), in den Vereinigten Staaten von Amerika (USA) und in anderen Staaten. Wir
                                    können keinen direkten Einfluss auf die entsprechende Bearbeitung von Personendaten
                                    durch Geheimdienste, Polizeistellen und andere Sicherheitsbehörden nehmen.</p>

                                <h2 id="website">5. Nutzung der Website</h2>

                                <h3 id="cookies">5.1 Cookies</h3>

                                <p>Wir können Cookies für unsere Website verwenden. Bei Cookies – bei eigenen Cookies
                                    (First-Party-Cookies) als auch bei Cookies von Dritten, deren Dienste wir nutzen
                                    (Cookies von Dritten beziehungsweise Third-Party-Cookies) – handelt es sich um
                                    Daten,
                                    die in Ihrem Browser gespeichert werden. Solche gespeicherten Daten müssen nicht auf
                                    traditionelle Cookies in Textform beschränkt sein. Cookies können keine Programme
                                    ausführen oder Schadsoftware wie Trojaner und Viren übertragen.</p>

                                <p>Cookies können beim Besuch unserer Website in Ihrem Browser temporär als «Session
                                    Cookies» oder für einen bestimmten Zeitraum als sogenannte permanente Cookies
                                    gespeichert werden. «Session Cookies» werden automatisch gelöscht, wenn Sie Ihren
                                    Browser schliessen. Permanente Cookies haben eine bestimmte Speicherdauer. Sie
                                    ermöglichen insbesondere, Ihren Browser beim nächsten Besuch unserer Website
                                    wiederzuerkennen und dadurch beispielsweise die Reichweite unserer Website zu
                                    messen.
                                    Permanente Cookies können aber beispielsweise auch für Online-Marketing verwendet
                                    werden.</p>

                                <p>Sie können Cookies in Ihren Browser-Einstellungen jederzeit ganz oder teilweise
                                    deaktivieren sowie löschen. Ohne Cookies steht unsere Website allenfalls nicht mehr
                                    in
                                    vollem Umfang zur Verfügung. Wir ersuchen Sie – sofern und soweit erforderlich –
                                    aktiv
                                    um Ihre ausdrückliche Einwilligung für die Verwendung von Cookies.</p>

                                <p>Bei Cookies, die für die Erfolgs- und Reichweitenmessung oder für Werbung verwendet
                                    werden, ist für zahlreiche Dienste ein allgemeiner Widerspruch («Opt-out») über
                                    die <a
                                        href="https://optout.networkadvertising.org/" target="_blank"
                                        rel="noopener noreferrer">Network Advertising Initiative</a> (NAI), <a
                                        href="https://optout.aboutads.info/" target="_blank"
                                        rel="noopener noreferrer">YourAdChoices</a> (Digital Advertising Alliance)
                                    oder <a
                                        href="https://www.youronlinechoices.com/ch-de/praferenzmanagement"
                                        target="_blank"
                                        rel="noopener noreferrer">Your Online Choices</a> (European Interactive Digital
                                    Advertising Alliance, EDAA) möglich.</p>

                                <h3 id="logdateien">5.2 Server-Logdateien</h3>

                                <p>Wir können für jeden Zugriff auf unsere Website nachfolgende Angaben erfassen, sofern
                                    diese von Ihrem Browser an unsere Server-Infrastruktur übermittelt werden oder von
                                    unserem Webserver ermittelt werden können: Datum und Zeit einschliesslich Zeitzone,
                                    Internet Protocol (IP)-Adresse, Zugriffsstatus (HTTP-Statuscode), Betriebssystem
                                    einschliesslich Benutzeroberfläche und Version, Browser einschliesslich Sprache und
                                    Version, aufgerufene einzelne Unter-Seite unserer Website einschliesslich
                                    übertragener
                                    Datenmenge, zuletzt im gleichen Browser-Fenster aufgerufene Webseite (Referer
                                    beziehungsweise Referrer).</p>

                                <p>Wir speichern solche Angaben, die auch Personendaten darstellen können, in
                                    Server-Logdateien. Die Angaben sind erforderlich, um unser Online-Angebot dauerhaft,
                                    nutzerfreundlich und zuverlässig bereitstellen sowie um die Datensicherheit und
                                    damit
                                    insbesondere den Schutz von Personendaten sicherstellen zu können – auch durch
                                    Dritte
                                    oder mit Hilfe von Dritten.</p>

                                <h3 id="zaehlpixel">5.3 Zählpixel</h3>

                                <p>Wir können Zählpixel auf unserer Website verwenden. Zählpixel werden auch als
                                    Web-Beacons
                                    bezeichnet. Bei Zählpixeln – auch von Dritten, deren Dienste wir nutzen – handelt es
                                    sich um kleine, üblicherweise nicht sichtbare Bilder, die beim Besuch unserer
                                    Website
                                    automatisch abgerufen werden. Mit Zählpixeln können die gleichen Angaben wie in
                                    Server-Logdateien erfasst werden.</p>

                                <h2 id="analytics">6. Erfolgs- und Reichweitenmessung</h2>

                                <h3 id="google-analytics">Google Analytics</h3>

                                <p>Wir verwenden <strong>Google Analytics</strong>, um zu analysieren, wie unsere
                                    Website
                                    genutzt wird, wobei wir beispielsweise auch die Reichweite unser Website und den
                                    Erfolg
                                    von Verlinkungen Dritter auf unsere Website messen können. Es handelt sich um einen
                                    Dienst der amerikanischen Google LLC. Für Nutzerinnen und Nutzer im Europäischen
                                    Wirtschaftsraum (EWR) und in der Schweiz ist die irische <a
                                        href="https://www.blog.google/around-the-globe/google-europe/some-changes-our-service-model-europe/"
                                        rel="nofollow noopener noreferrer" target="_blank">Google Ireland
                                        Limited</a> verantwortlich.</p>

                                <p>Google versucht einzelne Besucherinnen und Besucher unserer Website auch zu erfassen,
                                    wenn sie verschiedene Browser oder Geräte nutzen <strong>(Cross-Device
                                        Tracking)</strong>. Dabei kommen auch Cookies zum Einsatz. Für Google Analytics
                                    wird
                                    Ihre Internet Protocol (IP)-Adresse benötigt, die aber nicht mit anderen Daten von
                                    Google zusammengeführt wird.</p>

                                <p>In jedem Fall lassen wir Ihre Internet Protocol (IP)-Adresse vor der Analyse durch
                                    Google
                                    anonymisieren. Im Ergebnis wird Ihre vollständige
                                    IP-Adresse <em>grundsätzlich</em> nicht an Google in den USA übertragen.</p>

                                <p>Weitere Angaben über Art, Umfang und Zweck der Datenbearbeitung finden sich in den <a
                                    href="https://safety.google/principles/?hl=de" rel="nofollow noopener noreferrer"
                                    target="_blank">Grundsätzen für Datenschutz und Sicherheit</a> und in der <a
                                    href="https://policies.google.com/privacy?hl=de" rel="nofollow noopener noreferrer"
                                    target="_blank">Datenschutzerklärung</a> jeweils von Google, im <a
                                    href="https://policies.google.com/technologies/product-privacy?hl=de"
                                    rel="nofollow noopener noreferrer" target="_blank">Leitfaden zum Datenschutz in
                                    Google-Produkten</a> (einschliesslich Google Analytics), in den <a
                                    href="https://policies.google.com/technologies/partner-sites?hl=de"
                                    rel="nofollow noopener noreferrer" target="_blank">Informationen, wie Google Daten
                                    von
                                    Websites verwendet, auf denen Google-Dienste genutzt werden</a> und in den <a
                                    href="https://policies.google.com/technologies/cookies?hl=de"
                                    rel="nofollow noopener noreferrer" target="_blank">Informationen über Cookies bei
                                    Google</a>. Ausserdem besteht die Möglichkeit, das <a
                                    href="https://tools.google.com/dlpage/gaoptout" rel="nofollow noopener noreferrer"
                                    target="_blank">«Browser Add-on zur Deaktivierung von Google Analytics»</a> zu
                                    verwenden
                                    sowie <a href="https://adssettings.google.com/" rel="nofollow noopener noreferrer"
                                             target="_blank">Widerspruch gegen personalisierte Werbung</a> zu erheben.
                                </p>

                                <h2 id="dienste">7. Dienste von Dritten</h2>

                                <p>Wir verwenden Dienste von Dritten, um unser Angebot dauerhaft, nutzerfreundlich,
                                    sicher
                                    und zuverlässig bereitstellen zu können. Solche Dienste dienen auch dazu, Inhalte in
                                    unsere Website einbetten zu können. Solche Dienste – beispielsweise Hosting- und
                                    Speicherdienste, Video-Dienste und Zahlungsdienste – benötigen Ihre Internet
                                    Protocol
                                    (IP)-Adresse, da solche Dienste die entsprechenden Inhalte ansonsten nicht
                                    übermitteln
                                    können. Solche Dienste können sich ausserhalb der Schweiz und des Europäischen
                                    Wirtschaftsraumes (EWR) befinden, sofern ein angemessener Datenschutz gewährleistet
                                    ist.</p>

                                <p>Für ihre eigenen sicherheitsrelevanten, statistischen und technischen Zwecke können
                                    Dritte, deren Dienste wir nutzen, auch Daten im Zusammenhang mit unserem Angebot
                                    sowie
                                    aus anderen Quellen – unter anderem mit Cookies, Logdateien und Zählpixeln –
                                    aggregiert,
                                    anonymisiert oder pseudonymisiert bearbeiten.</p>

                                <h3 id="infrastruktur">7.1 Digitale Infrastruktur</h3>

                                <p>Wir nutzen Dienste von Dritten, um benötigte digitale Infrastruktur für unser Angebot
                                    in
                                    Anspruch nehmen zu können. Dazu zählen beispielsweise Hosting- und Speicherdienste
                                    von
                                    spezialisierten Anbietern.</p>

                                <h3 id="schriftarten">7.2 Schriftarten und Icons</h3>

                                <p>7.2.1 Wir verwenden <strong>Font Awesome</strong>, um ausgewählte Icons in unsere
                                    Website
                                    einbetten zu können. Dabei kommen auch Cookies zum Einsatz. Es handelt sich um ein
                                    Angebot der amerikanischen Fonticons Inc., die nach eigenen Angaben das europäische
                                    Datenschutzrecht respektiert. Weitere Angaben über Art, Umfang und Zweck der
                                    Datenbearbeitung finden sich in der <a href="https://fontawesome.com/privacy">Datenschutzerklärung
                                        von Font Awesome</a>.</p>

                                <p>7.2.2 Wir verwenden <strong>Google Fonts</strong>, um ausgewählte Schriftarten in
                                    unsere
                                    Website einbetten zu können. Dabei kommen <a
                                        href="https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users"><em>keine</em> Cookies</a> zum
                                    Einsatz. Es handelt sich um einen Dienst der amerikanischen Google LLC, der
                                    unabhängig
                                    von anderen Google-Diensten angeboten wird. Für Nutzerinnen und Nutzer im
                                    Europäischen
                                    Wirtschaftsraum (EWR) und in der Schweiz ist die irische <a
                                        href="https://www.blog.google/around-the-globe/google-europe/some-changes-our-service-model-europe/">Google
                                        Ireland Limited</a> verantwortlich. Weitere Angaben über Art, Umfang und Zweck
                                    der
                                    Datenbearbeitung finden sich in den <a
                                        href="https://safety.google/principles/?hl=de">Grundsätzen
                                        für Datenschutz und Sicherheit</a> sowie in der <a
                                        href="https://policies.google.com/privacy?hl=de">Datenschutzerklärung</a> von
                                    Google.</p>

                                <h2 id="erweiterungen">8. Erweiterungen für die Website</h2>

                                <p>Wir verwenden <strong>jQuery</strong>, eine freie JavaScript-Bibliothek der JS
                                    Foundation. Wir binden die Bibliothek mit Hilfe von <strong>jQuery.com</strong> ein,
                                    damit wir die Geschwindigkeit unserer Website verbessern können. jQuery wird in
                                    diesem
                                    Rahmen über das Content Delivery Network (CDN) StackPath, einem Dienst der
                                    amerikanischen StackPath LLC, ausgeliefert. Dabei können auch Cookies zum Einsatz
                                    kommen. Weitere Angaben über Art, Umfang und Zweck der Datenbearbeitung finden sich
                                    in
                                    der <a href="https://www.stackpath.com/legal/privacy-statement/"
                                           rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung von
                                        StackPath</a>.</p>

                                <h2 id="schlussbestimmungen">9. Schlussbestimmungen</h2>

                                <p>Wir haben diese Datenschutzerklärung mit dem <a
                                    href="https://www.datenschutzpartner.ch/angebot-datenschutz-generator/"
                                    rel="nofollow noopener noreferrer" target="_blank">Datenschutz-Generator</a> von <a
                                    href="https://datenschutzpartner.ch/" rel="nofollow noopener noreferrer"
                                    target="_blank">Datenschutzpartner</a> erstellt.</p>

                                <p>Wir können diese Datenschutzerklärung jederzeit anpassen und ergänzen. Wir werden
                                    über
                                    solche Anpassungen und Ergänzungen in geeigneter Form informieren, insbesondere
                                    durch
                                    Veröffentlichung der jeweils aktuellen Datenschutzerklärung auf unserer Website.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
