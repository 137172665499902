import React from "react";
import DashboardLanding from "./DashboardLanding";
import PropTypes from "prop-types";
import UserInfo from "../../models/UserInfo";

/**
 * @param {UserInfo} userInfo
 * @returns {JSX.Element}
 * @constructor
 */
export default function DashboardLandingClient({userInfo}) {
    return (
        <div>
            <DashboardLanding userInfo={userInfo}/>
            <br/>
            <h2>News</h2>
            <hr/>
            <div className="grayBoxStyle separatorMargin">
                <p className="m-0">Newstext</p>
            </div>
        </div>
    );
};

DashboardLandingClient.propTypes = {
    userInfo: PropTypes.instanceOf(UserInfo).isRequired
};