import React from 'react';
import {Link} from "react-router-dom";

export default class BreadCrumb extends React.Component {

    render() {
        if (this.props.activeSteps[this.props.steps[0]]) {
            return (
                <Link style={this.getBreadcrumbStyle(this.props.steps)}
                      onClick={() => this.props.setStep(this.props.steps[0])}>{this.props.name}</Link>
            )
        } else {
            return (
                <p style={{color: 'grey'}}>{this.props.name}</p>
            )
        }
    }

    getBreadcrumbStyle(stepNames) {
        let fontWeight = "unset";
        for (let stepName of stepNames) {
            if (stepName === this.props.currentStep)
                fontWeight = "bold";
        }
        return {fontWeight: fontWeight};
    }
}
