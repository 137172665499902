export default class APIBaseError extends Error {
    /**
     * @type {Response}
     * @private
     */
    _response = undefined;
    /**
     * @type {string|undefined}
     * @private
     */
    _correlationId = undefined;

    /**
     * @param {Response} response
     * @param {string} message
     * @param {string|undefined} correlationId
     */
    constructor(response, message, correlationId) {
        super(message);

        this._correlationId = correlationId;
    }

    /**
     * @returns {Response}
     */
    get response() {
        return this._response;
    }

    /**
     * @returns {string|undefined}
     */
    get correlationId() {
        return this._correlationId;
    }
}