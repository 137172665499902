import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import '../../views/DashboardView/DashboardView.css';
import ApiService from '../../services/ApiService';
import keycloak from '../../keycloak';
import Availability from '../../models/Availability';
import Form from '../form/Form';
import Section from '../form/Section';
import Box from '../form/Box';
import BoxRow from '../form/BoxRow';
import Salutation from '../../enums/Salutation';
import EnumData from '../form/data/EnumData';
import Data from '../form/data/Data';
import {formatBirthDate, ucFirst} from '../../util/helpers';
import UserInfo from '../../models/UserInfo';
import Caregiver from '../../models/Caregiver';
import SubSection from '../form/SubSection';
import FieldSet from '../form/Fieldset';
import CheckboxGroupField from '../form/CheckboxGroupField';
import Workslot from '../../enums/Workslot';
import DropdownField from '../form/DropdownField';
import Workload from '../../enums/Workload';
import Submit from '../form/Submit';
import Success from '../form/Success';
import Failed from '../form/Failed';

export default class DashboardAvailability extends React.Component {
    state = {
        caregiver: undefined,
    };

    /**
     * @returns {UserInfo}
     * @private
     */
    get _userInfo() {
        return this.props.userInfo;
    }

    /**
     * @returns {Caregiver}
     * @private
     */
    get _caregiver() {
        return this.state.caregiver;
    }

    /**
     * @param {Caregiver} caregiver
     * @private
     */
    set _caregiver(caregiver) {
        this.setState({
            caregiver: caregiver
        });
    }

    /**
     * @returns {{}}
     * @private
     */
    get _initialValues() {
        const userInfo = this._userInfo?.clone() ?? new UserInfo();
        const caregiver = this._caregiver?.clone() ?? new Caregiver();
        const workSlots = [...(caregiver.availability?.workSlots ?? new Set()).values()];
        const workLoads = [...(caregiver.availability?.workLoads ?? new Set()).values()];

        const workLoadHalfDay = workLoads.find(workLoad => workLoad.startsWith('halfDay'));
        const workLoadFullDay = workLoads.find(workLoad => workLoad.startsWith('fullDay'));
        const workLoadNights = workLoads.find(workLoad => workLoad.startsWith('nights'));

        return {
            userInfo: userInfo,
            workSlots: workSlots,
            workLoadHalfDay: workLoadHalfDay,
            workLoadFullDay: workLoadFullDay,
            workLoadNights: workLoadNights,
        };
    }

    /**
     * @returns {[]}
     * @private
     */
    get _availableDays() {
        const days = [];
        const values = Workslot.values();
        const labels = [i18next.t('morning'), i18next.t('afternoon'), i18next.t('night')];

        for (let i = 1; i < 4 * 7; i += 4) {
            /**
             * @type {[]}
             */
            const day = [values[i].label];
            const slots = [];

            for (let j = 0; j < 3; j++) {
                const slot = values[i + j + 1];
                const label = labels[j];
                slots.push([slot.id, label]);
            }

            day.push(slots);
            days.push(day);
        }

        return days;
    }

    /**
     * @returns {[]}
     * @private
     */
    get _availableWorkloads() {
        const workLoads = [];
        const values = Workload.values();

        for (let i = 0; i < 8 * 3; i += 8) {
            /**
             * @type {[]}
             */
            const workLoad = [values[i].id];
            const options = values.slice(i, i + 8).map(option => [option.id, option.label]);

            workLoad.push(options);
            workLoads.push(workLoad);
        }

        return workLoads;
    }

    render = () => (
        <Form initialValues={this._initialValues} onLoad={this._onLoad} onSubmit={this._onSubmit}>
            {({t}) => (
                <>
                    <Section>{t('availability')}</Section>
                    <FieldSet>
                        <Box>
                            <BoxRow><EnumData hidden={v => v === Salutation.OTHER.id}
                                              enum={Salutation}
                                              name="userInfo.gender"/></BoxRow>
                            <BoxRow><Data name="userInfo.firstName"/> <Data name="userInfo.lastName"/></BoxRow>
                            <BoxRow><Data name="userInfo.birthDate" formatter={formatBirthDate}/></BoxRow>
                        </Box>
                    </FieldSet>
                    <SubSection>{t('liveInModel')}</SubSection>
                    <SubSection>{t('dailyBasisService')}</SubSection>
                    <FieldSet>
                        {this._availableDays.map((day, index) => (
                            <CheckboxGroupField key={index} label={day[0]} name="workSlots" options={day[1]}/>
                        ))}
                    </FieldSet>
                    <SubSection>{t('workLoadPerWeek')}</SubSection>
                    <FieldSet>
                        {this._availableWorkloads.map((service, index) => (
                            <DropdownField key={index}
                                           label={t(`${service[0]}Service`)}
                                           name={`workLoad${ucFirst(service[0])}`}
                                           options={service[1]}/>
                        ))}
                    </FieldSet>
                    <Submit>{t('confirm')}</Submit>
                    <Success/>
                    <Failed/>
                </>
            )}
        </Form>
    );

    _onLoad = () => {
        return ApiService.getCaregiver(this._userInfo.relatedEntityId, keycloak)
            .then(caregiver => this._caregiver = caregiver);
    }

    _onSubmit = (values) => {
        const caregiver = this._caregiver.clone();
        const availability = caregiver.availability ?? new Availability();

        availability.workSlots = new Set([...values.workSlots]);
        availability.workLoads = new Set([values.workLoadHalfDay, values.workLoadFullDay, values.workLoadNights]);

        caregiver.availability = availability;

        return ApiService.updateCaregiver(caregiver, keycloak).then(caregiver => this._caregiver = caregiver);
    }
}

DashboardAvailability.propTypes = {
    userInfo: PropTypes.instanceOf(UserInfo).isRequired
}