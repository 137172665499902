import AbstractModel from './AbstractModel';
import {v4 as uuid} from "uuid";

export default class UserInfoDetail extends AbstractModel {
    /**
     * @type {string}
     * @private
     */
    _id = undefined;
    /**
     * @type {UserInfoChild[]}
     * @private
     */
    _children = undefined;
    /**
     * @type {string}
     * @private
     */
    _residencePermit = undefined;
    /**
     * @type {string}
     * @private
     */
    _civilStatus = undefined;
    /**
     * @type {boolean}
     * @private
     */
    _withholdingTax = undefined;
    /**
     * @type {boolean}
     * @private
     */
    _criminalRecord = undefined;
    /**
     * @type {string}
     * @private
     */
    _otherEmployers = undefined;
    /**
     * @type {boolean}
     * @private
     */
    _additionalIncomeUnemploymentInsurance = undefined;
    /**
     * @type {boolean}
     * @private
     */
    _disabilityAndSocialInsuranceDeductions = undefined;
    /**
     * @type {string}
     * @private
     */
    _socialSecurityNumber = undefined;
    /**
     * @type {string}
     * @private
     */
    _bankAccountNumber = undefined;
    /**
     * @type {Date}
     * @private
     */
    _employmentStartDate = undefined;
    /**
     * @type {Date}
     * @private
     */
    _employmentEndDate = undefined;
    /**
     * @type {string}
     * @private
     */
    _notice = undefined;

    /**
     * @param {string} [id]
     * @param {UserInfoChild[]} [children]
     * @param {string} [residencePermit]
     * @param {string} [civilStatus]
     * @param {boolean} [withholdingTax]
     * @param {boolean} [criminalRecord]
     * @param {string} [otherEmployers]
     * @param {boolean} [additionalIncomeUnemploymentInsurance]
     * @param {boolean} [disabilityAndSocialInsuranceDeductions]
     * @param {string} [socialSecurityNumber]
     * @param {string} [bankAccountNumber]
     * @param {Date} [employmentStartDate]
     * @param {Date} [employmentEndDate]
     * @param {string} [notice]
     */
    constructor(
        id,
        children,
        residencePermit,
        civilStatus,
        withholdingTax,
        criminalRecord,
        otherEmployers,
        additionalIncomeUnemploymentInsurance,
        disabilityAndSocialInsuranceDeductions,
        socialSecurityNumber,
        bankAccountNumber,
        employmentStartDate,
        employmentEndDate,
        notice
    ) {
        super();

        this._id = id ?? uuid();
        this._children = children ?? [];
        this._residencePermit = residencePermit;
        this._civilStatus = civilStatus;
        this._withholdingTax = withholdingTax;
        this._criminalRecord = criminalRecord;
        this._otherEmployers = otherEmployers;
        this._additionalIncomeUnemploymentInsurance = additionalIncomeUnemploymentInsurance;
        this._disabilityAndSocialInsuranceDeductions = disabilityAndSocialInsuranceDeductions;
        this._socialSecurityNumber = socialSecurityNumber;
        this._bankAccountNumber = bankAccountNumber;
        this._employmentStartDate = employmentStartDate;
        this._employmentEndDate = employmentEndDate;
        this._notice = notice;
    }

    /**
     * @returns {string}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {string} id
     */
    set id(id) {
        this._id = id;
    }

    /**
     * @returns {UserInfoChild[]}
     */
    get children() {
        return this._children;
    }

    /**
     * @param {UserInfoChild[]} children
     */
    set children(children) {
        this._children = children;
    }

    /**
     * @returns {string}
     */
    get residencePermit() {
        return this._residencePermit;
    }

    /**
     * @param {string} residencePermit
     */
    set residencePermit(residencePermit) {
        this._residencePermit = residencePermit;
    }

    /**
     * @returns {string}
     */
    get civilStatus() {
        return this._civilStatus;
    }

    /**
     * @param {string} civilStatus
     */
    set civilStatus(civilStatus) {
        this._civilStatus = civilStatus;
    }

    /**
     * @returns {boolean}
     */
    get withholdingTax() {
        return this._withholdingTax;
    }

    /**
     * @param {boolean} withholdingTax
     */
    set withholdingTax(withholdingTax) {
        this._withholdingTax = withholdingTax;
    }

    /**
     * @returns {boolean}
     */
    get criminalRecord() {
        return this._criminalRecord;
    }

    /**
     * @param {boolean} criminalRecord
     */
    set criminalRecord(criminalRecord) {
        this._criminalRecord = criminalRecord;
    }

    /**
     * @returns {string}
     */
    get otherEmployers() {
        return this._otherEmployers;
    }

    /**
     * @param {string|null} otherEmployers
     */
    set otherEmployers(otherEmployers) {
        this._otherEmployers = otherEmployers;
    }

    /**
     * @returns {boolean}
     */
    get additionalIncomeUnemploymentInsurance() {
        return this._additionalIncomeUnemploymentInsurance;
    }

    /**
     * @param {boolean} additionalIncomeUnemploymentInsurance
     */
    set additionalIncomeUnemploymentInsurance(additionalIncomeUnemploymentInsurance) {
        this._additionalIncomeUnemploymentInsurance = additionalIncomeUnemploymentInsurance;
    }

    /**
     * @returns {boolean}
     */
    get disabilityAndSocialInsuranceDeductions() {
        return this._disabilityAndSocialInsuranceDeductions;
    }

    /**
     * @param {boolean} disabilityAndSocialInsuranceDeductions
     */
    set disabilityAndSocialInsuranceDeductions(disabilityAndSocialInsuranceDeductions) {
        this._disabilityAndSocialInsuranceDeductions = disabilityAndSocialInsuranceDeductions;
    }

    /**
     * @returns {string}
     */
    get socialSecurityNumber() {
        return this._socialSecurityNumber;
    }

    /**
     * @param {string} socialSecurityNumber
     */
    set socialSecurityNumber(socialSecurityNumber) {
        this._socialSecurityNumber = socialSecurityNumber;
    }

    /**
     * @returns {string}
     */
    get bankAccountNumber() {
        return this._bankAccountNumber;
    }

    /**
     * @param {string} bankAccountNumber
     */
    set bankAccountNumber(bankAccountNumber) {
        this._bankAccountNumber = bankAccountNumber;
    }

    /**
     * @returns {Date}
     */
    get employmentStartDate() {
        return this._employmentStartDate;
    }

    /**
     * @param {Date} employmentStartDate
     */
    set employmentStartDate(employmentStartDate) {
        this._employmentStartDate = employmentStartDate;
    }

    /**
     * @returns {Date}
     */
    get employmentEndDate() {
        return this._employmentEndDate;
    }

    /**
     * @param {Date} employmentEndDate
     */
    set employmentEndDate(employmentEndDate) {
        this._employmentEndDate = employmentEndDate;
    }

    /**
     * @returns {string}
     */
    get notice() {
        return this._notice;
    }

    /**
     * @param {string} notice
     */
    set notice(notice) {
        this._notice = notice;
    }

    toJSON() {
        return {
            children: (this._children ?? []).map(child => child.toJSON()),
            residencePermit: this._residencePermit,
            civilStatus: this._civilStatus,
            withholdingTax: this._withholdingTax,
            criminalRecord: this._criminalRecord,
            otherEmployers: this._otherEmployers,
            additionalIncomeUnemploymentInsurance: this._additionalIncomeUnemploymentInsurance,
            disabilityAndSocialInsuranceDeductions: this._disabilityAndSocialInsuranceDeductions,
            socialSecurityNumber: this._socialSecurityNumber,
            bankAccountNumber: this._bankAccountNumber,
            employmentStartDate: this._employmentStartDate,
            employmentEndDate: this._employmentEndDate,
            notice: this._notice
        };
    }

    /**
     * @returns {UserInfoDetail}
     */
    clone() {
        return this._clone(new UserInfoDetail());
    }
}