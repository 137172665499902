import React from 'react';
import AbstractData from './AbstractData';
import PropTypes from 'prop-types';

export default class Data extends AbstractData {
    /**
     * @template T
     * @returns {(function(T): T)}
     */
    get formatter() {
        return this.props.formatter ?? (v => v);
    }

    renderData({field, form, meta}) {
        const value = field.value ? this.formatter(field.value) : undefined;
        return value ?? <span>&nbsp;</span>;
    }
}

Data.propTypes = {
    ...AbstractData.propTypes,
    formatter: PropTypes.func
}