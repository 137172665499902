import React from 'react';
import i18next from 'i18next';
import {Alert} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ApiService from '../../services/ApiService';
import keycloak from '../../keycloak';
import {formatBirthDate} from '../../util/helpers';
import Section from '../form/Section';
import Box from '../form/Box';
import BoxRow from '../form/BoxRow';
import EnumData from '../form/data/EnumData';
import Data from '../form/data/Data';
import FieldSet from '../form/Fieldset';
import SubSection from '../form/SubSection';
import Form from '../form/Form';
import Salutation from '../../enums/Salutation';
import Submit from '../form/Submit';
import Success from '../form/Success';
import Failed from '../form/Failed';
import Email from '../../models/Email';
import EmailInputField from '../form/EmailInputField';
import Spinner from '../../util/Spinner';

/** @typedef {import('formik').FormikValues} FormikValues */
/** @typedef {import('formik').FormikErrors} FormikErrors */

export default class DashboardEmail extends React.Component {
    constructor(props) {
        super(props);

        const token = this._getTokenByUrl();

        this.state = {
            token: token,
            tokenSubmissionInProgress: !!token,
            tokenSubmissionSucceeded: false
        };
    }

    /**
     * @returns {string|undefined}
     * @private
     */
    get _token() {
        return this.state.token;
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _tokenSubmissionInProgress() {
        return this.state.tokenSubmissionInProgress;
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _tokenSubmissionSucceeded() {
        return this.state.tokenSubmissionSucceeded;
    }

    /**
     * @param {boolean} tokenSubmissionSucceeded
     * @private
     */
    set _tokenSubmissionSucceeded(tokenSubmissionSucceeded) {
        this.setState({
            tokenSubmissionSucceeded: tokenSubmissionSucceeded
        });
    }

    /**
     * @param {boolean} tokenSubmissionInProgress
     * @private
     */
    set _tokenSubmissionInProgress(tokenSubmissionInProgress) {
        this.setState({
            tokenSubmissionInProgress: tokenSubmissionInProgress
        });
    }

    /**
     * @returns {UserInfo}
     * @private
     */
    get _userInfo() {
        return this.props.userInfo;
    }

    /**
     * @returns {{userInfo: UserInfo, email: string, emailRepeat: string, token: string|undefined}}
     * @private
     */
    get _initialValues() {
        return {
            userInfo: this._userInfo.clone(),
            email: '',
            emailRepeat: '',
            token: this._getTokenByUrl()
        };
    }

    componentDidMount() {
        if (!!this._token) {
            ApiService.sendEmailChangeToken(this._token, keycloak)
                .then(() => this._tokenSubmissionSucceeded = true)
                .catch(error => this._tokenSubmissionSucceeded = error)
                .finally(() => this._tokenSubmissionInProgress = false);
        }
    }

    render = () => !!this._token ? this._renderTokenSubmission() : this._renderForm();

    _renderTokenSubmission = () => this._tokenSubmissionInProgress ? <Spinner/> : this._renderTokenSubmissionResult();

    _renderTokenSubmissionResult = () => (
        <Alert show={true}
               variant={this._tokenSubmissionSucceeded === true ? "success" : "danger"}
               dismissible={true} onClose={this._abortTokenSubmission}>
            {this._tokenSubmissionSucceeded instanceof Error ? i18next.t('emailChangeFailed') : i18next.t('emailChangeCompletedSuccessful')}
        </Alert>
    )

    _renderForm = () => (
        <Form onLoad={this._onLoad}
              onSubmit={this._onSubmit}
              initialValues={this._initialValues}
              onValidate={this._onValidate}>
            {({t}) => (
                <>
                    <Section>{t('myProfile')} - {t('changeEmail')}</Section>
                    <FieldSet>
                        <Box>
                            <BoxRow><EnumData hidden={v => v === Salutation.OTHER.id}
                                              enum={Salutation}
                                              name="userInfo.gender"/></BoxRow>
                            <BoxRow><Data name="userInfo.firstName"/> <Data name="userInfo.lastName"/></BoxRow>
                            <BoxRow><Data name="userInfo.birthDate" formatter={formatBirthDate}/></BoxRow>
                        </Box>
                    </FieldSet>
                    <SubSection>{t('changeEmailAddress')}</SubSection>
                    <FieldSet>
                        <EmailInputField label={t('newEmail')} required={true} name="email"/>
                        <EmailInputField label={t('newEmailRepeat')} required={true} name="emailRepeat"/>
                    </FieldSet>
                    <Submit>{t('confirm')}</Submit>
                    <Success>{t('initEmailChangeSuccess')}</Success>
                    <Failed/>
                </>
            )}
        </Form>
    )

    /**
     * @param {FormikValues} values
     * @param {FormikErrors} existingErrors
     * @returns {{}}
     * @private
     */
    _onValidate = (values, existingErrors) => {
        const errors = {...existingErrors};

        if (values.email !== values.emailRepeat) {
            errors.emailRepeat = i18next.t('form.validation.invalidEmailRepeat');
        }

        return errors;
    }

    _onLoad = () => {
    }

    /**
     * @param {FormikValues} values
     * @returns {Promise<void>}
     * @private
     */
    _onSubmit = (values) => {
        const verifyUrl = `${window.location}?token={token}`;

        const email = new Email(values.email, verifyUrl)

        return ApiService.initEmailChange(email, keycloak)
    }

    /**
     * @returns {string|undefined}
     * @private
     */
    _getTokenByUrl = () => {
        const params = new URLSearchParams(window.location.search);

        return params.get('token');
    };

    _abortTokenSubmission = () => window.location.search = '';
}

DashboardEmail.propTypes = {
    userInfo: PropTypes.object.isRequired
};