/** @typedef {import('../models/ValidationResult').default} ValidationResult */

export default class APIValidationError extends Error {
    /**
     * @type {ValidationResult}
     * @private
     */
    _validationResult = undefined;

    /**
     * @param {ValidationResult} validationResult
     */
    constructor(validationResult) {
        super(validationResult.message);

        this._validationResult = validationResult;
    }

    /**
     * @returns {ValidationResult}
     */
    get validationResult() {
        return this._validationResult;
    }
}