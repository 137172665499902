import AbstractModel from "./AbstractModel";

export default class ClientScore extends AbstractModel {

    constructor(id, superCategory, description) {
        super();

        this._id = id;
        this._superCategory = superCategory;
        this._description = description;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get superCategory() {
        return this._superCategory;
    }

    set superCategory(value) {
        this._superCategory = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    toJSON() {
        return ({
            id: this._id,
            superCategory: this._superCategory ? this._superCategory.toJSON() : "",
            description: this._description
        })
    }

    /**
     * @returns {ClientScore}
     */
    clone() {
        return this._clone(new ClientScore());
    }
}
