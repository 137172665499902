import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import UserInfo from "../../models/UserInfo";
import ApiService from "../../services/ApiService";
import keycloak from "../../keycloak";
import {formatBirthDate} from "../../util/helpers";
import UploadedDocument from "../../models/UploadedDocument";
import Form from "../form/Form";
import Section from "../form/Section";
import FieldSet from "../form/Fieldset";
import Box from "../form/Box";
import BoxRow from "../form/BoxRow";
import EnumData from "../form/data/EnumData";
import Data from "../form/data/Data";
import SubSection from "../form/SubSection";
import Submit from "../form/Submit";
import Success from "../form/Success";
import Failed from "../form/Failed";
import Salutation from "../../enums/Salutation";
import DropdownField from "../form/DropdownField";
import DocumentType from "../../enums/DocumentType";
import FileInputField from "../form/FileInputField";

/** @typedef {import('formik').FormikValues} FormikValues */
/** @typedef {import('formik').FormikHelpers} FormikHelpers */
/** @typedef {import('i18next').TFunction} TFunction */

export default class DashboardUpload extends React.Component {
    state = {
        documents: [],
    }

    /**
     * @returns {UserInfo}
     * @private
     */
    get _userInfo() {
        return this.props.userInfo;
    }

    /**
     * @returns {UploadedDocument[]}
     * @private
     */
    get _documents() {
        return this.state.documents;
    }

    /**
     * @returns {{}}
     * @private
     */
    get _initialValues() {
        return {
            userInfo: this._userInfo,
            type: undefined,
            file: undefined,
            documents: this._documents
        };
    }

    render = () => (
        <Form onLoad={this._onLoad} onSubmit={this._onSubmit} initialValues={this._initialValues}>
            {({t}) => (
                <>
                    <Section>{t('myProfile')} - {t('uploadedDocuments')}</Section>
                    <FieldSet>
                        <FieldSet>
                            <Box>
                                <BoxRow><EnumData hidden={v => v === Salutation.OTHER.id}
                                                  enum={Salutation}
                                                  name="userInfo.gender"/></BoxRow>
                                <BoxRow><Data name="userInfo.firstName"/> <Data name="userInfo.lastName"/></BoxRow>
                                <BoxRow><Data name="userInfo.birthDate" formatter={formatBirthDate}/></BoxRow>
                            </Box>
                        </FieldSet>
                        <SubSection>{t('uploadDocuments')}</SubSection>
                        <FieldSet>
                            <DropdownField label={t('documentType')} name="type" enum={DocumentType} required={true}/>
                            <FileInputField label={t('document')} name="file" required={true}/>
                        </FieldSet>
                        <Submit>{t('confirm')}</Submit>
                        <Success>{t('documentUploadSuccess')}</Success>
                        <Failed/>
                    </FieldSet>
                    <SubSection>{t('existingUploadedDocuments')}</SubSection>
                    {this._renderUploadedDocuments(t)}
                </>
            )}
        </Form>
    )

    /**
     * @param {UploadedDocument} document
     * @param {number} index
     * @returns {JSX.Element}
     * @private
     */
    _renderUploadedDocument(document, index) {
        const name = document.fileName;
        const type = DocumentType.valueOf(document.type) ?? DocumentType.UNSPECIFIED;
        const date = document.createdAt.toLocaleDateString();

        const onClick = () => this._viewUploadedDocument(document.id);

        return (
            <Row key={index}>
                <Col xs={4}>
                    <div className="grayBoxStyle clickable" onClick={onClick}>
                        {name}
                    </div>
                </Col>
                <Col xs={4}>
                    <div className="grayBoxStyle clickable" onClick={onClick}>
                        {type.label}
                    </div>
                </Col>
                <Col>
                    <div className="whiteBoxStyle clickable" onClick={onClick}>
                        {date}
                    </div>
                </Col>
            </Row>
        );
    }

    /**
     * @param {TFunction} t
     * @returns {JSX.Element}
     * @private
     */
    _renderUploadedDocuments(t) {
        return (
            <div>
                {this._documents.length > 0 && this._documents.map(this._renderUploadedDocument.bind(this))}
                {this._documents.length === 0 && t('noDocumentsUploadedYet')}
            </div>
        );
    }

    _viewUploadedDocument = (documentId) => {
        const caregiverId = this._userInfo.relatedEntityId;

        ApiService.getCaregiverUploadedDocument(caregiverId, documentId, keycloak)
            .then(blob => URL.createObjectURL(blob))
            .then(url => window.open(url, documentId, null, true));
    };

    _onLoad = () => {
        const id = this._userInfo.relatedEntityId;

        return ApiService.getCaregiverUploadedDocuments(id, keycloak).then(this._appendDocuments);
    }

    /**
     * @param {FormikValues} values
     * @returns {Promise<any>}
     * @private
     */
    _onSubmit = (values) => {
        const uploadedDocument = new UploadedDocument();
        uploadedDocument.type = values.type;

        return ApiService.addCaregiverFiles(this._userInfo.relatedEntityId, [values.file], [uploadedDocument], keycloak)
            .then(this._appendDocuments);
    }

    /**
     * @param {UploadedDocument[]} uploadedDocuments
     * @private
     */
    _appendDocuments = (uploadedDocuments) => {
        return new Promise(resolve => {
            const existingDocuments = [...this._documents];
            existingDocuments.push(...uploadedDocuments);

            this.setState({
                documents: existingDocuments
            }, () => resolve());
        });
    }
}

DashboardUpload.propTypes = {
    userInfo: PropTypes.instanceOf(UserInfo).isRequired
}
