import AbstractModel from "./AbstractModel";

export default class LanguageSkill extends AbstractModel {
    constructor(id, language, level, certified) {
        super();

        this._id = id;
        this._language = language;
        this._level = level;
        this._certified = certified;
    }

    get id() {
        return this._id;
    }

    get language() {
        return this._language;
    }

    set language(value) {
        this._language = value;
    }

    get level() {
        return this._level;
    }

    set level(value) {
        this._level = value;
    }

    get certified() {
        return this._certified;
    }

    set certified(value) {
        this._certified = value;
    }

    toJSON() {
        return ({
            id: this._id,
            language: this._language,
            level: this._level,
            certified: this._certified
        })
    }

    /**
     * @returns {LanguageSkill}
     */
    clone() {
        return this._clone(new LanguageSkill());
    }
}
