import React from 'react';
import {Field} from 'formik';

export default function Submit({children}) {
    return (
        <Field>
            {({form}) =>
                <button type="submit" disabled={!form.dirty} className="grayBoxStyle">
                    {children}
                </button>
            }
        </Field>
    );
}