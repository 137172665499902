import AbstractModel from "./AbstractModel";

export default class UploadedDocument extends AbstractModel {
    /**
     * @type {number}
     * @private
     */
    _id = undefined;
    /**
     * @type {*}
     * @private
     */
    _fileName = undefined;
    /**
     * @type {Date}
     * @private
     */
    _createdAt = undefined;
    /**
     * @type {Date}
     * @private
     */
    _lastUpdatedAt = undefined;
    /**
     * @type {string}
     * @private
     */
    _type = undefined;

    /**
     * @param {number} [id]
     * @param {string} [fileName]
     * @param {Date} [createdAt]
     * @param {Date} [lastUpdatedAt]
     * @param {string} [type]
     */
    constructor(id, fileName, createdAt, lastUpdatedAt, type) {
        super();

        this._id = id;
        this._fileName = fileName;
        this._createdAt = createdAt;
        this._lastUpdatedAt = lastUpdatedAt;
        this._type = type;
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {number} id
     */
    set id(id) {
        this._id = id;
    }

    /**
     * @returns {string}
     */
    get fileName() {
        return this._fileName;
    }

    /**
     * @param {string} fileName
     */
    set fileName(fileName) {
        this._fileName = fileName;
    }

    /**
     * @returns {Date}
     */
    get createdAt() {
        return this._createdAt;
    }

    /**
     * @param {Date} createdAt
     */
    set createdAt(createdAt) {
        this._createdAt = createdAt;
    }

    /**
     * @returns {Date}
     */
    get lastUpdatedAt() {
        return this._lastUpdatedAt;
    }

    /**
     * @param {Date} lastUpdatedAt
     */
    set lastUpdatedAt(lastUpdatedAt) {
        this._lastUpdatedAt = lastUpdatedAt;
    }

    /**
     * @returns {string}
     */
    get type() {
        return this._type;
    }

    /**
     * @param {string} type
     */
    set type(type) {
        this._type = type;
    }

    toJSON() {
        return {
            id: this._id,
            fileName: this._fileName,
            createdAt: this._createdAt,
            lastUpdatedAt: this._lastUpdatedAt,
            type: this._type
        };
    }

    /**
     * @returns {UploadedDocument}
     */
    clone() {
        return this._clone(new UploadedDocument());
    }
}