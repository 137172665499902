/**
 * @deprecated
 * @type {string}
 */
export const NEW_USER = 'new_user';
/**
 * @deprecated
 * @type {string}
 */
export const CLIENT = 'client';
/**
 * @deprecated
 * @type {string}
 */
export const CLIENTOBSERVER = 'clientobserver';
/**
 * @deprecated
 * @type {string}
 */
export const CAREGIVER = 'caregiver';

/**
 * @deprecated
 * @returns {boolean}
 */
export const checkForRole = (checkRole, keycloak) => {
    for (let role of keycloak.realmAccess.roles) {
        if (role === checkRole)
            return true;
    }
    return false;
};
