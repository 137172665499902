import React from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import './LandingView.css';
import '../../App.css';
import landingPageImageCaregiver from '../../assets/images/landing_page_caregiver.jpg';
import landingPageImageClient from '../../assets/images/landing_page_client.jpg';
import landingPageImageLogin from '../../assets/images/landing_page_login.jpg';

class LandingView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    login = event => {
        event.preventDefault();
        /*
        ApiService.authenticate(this.state.email, this.state.password).then(response => {
            console.log(response);
            const keycloak = Keycloak('/keycloak.dev.json');
            keycloak.init({onLoad: 'check-sso', token: response['access_token'], idToken: response['id_token'], refreshToken: response['refresh_token']}).then(authenticated => {

                this.setState({ keycloak: keycloak, authenticated: authenticated });
                console.log(keycloak.token);
                console.log(authenticated);
            })
        });
        */
    };

    handleChange = event => {
        let fieldName = event.target.name;
        let fleldVal = event.target.value;
        this.setState({[fieldName]: fleldVal})
    };

    render() {
        return (
            <div className="d-flex justify-content-center">
                <div>
                    <h4 style={{margin: '40px 15px'}}>Herzlich Willkommen</h4>
                    <Container>
                        <Row>
                            <Col lg={4} style={{marginBottom: 40}}>
                                <Card className={'card'}>
                                    <Card.Img variant="top" style={{marginBottom: 10, borderRadius: 'unset'}}
                                              src={landingPageImageClient}/>
                                    <Card.Body style={{padding: 0, borderRadius: 'unset'}}>
                                        <Card.Title>Kunden</Card.Title>
                                        <Card.Text style={{height: "70px"}}>
                                            Ich möchte Leistungen für mich oder eine nahstehende Person beziehen.
                                        </Card.Text>
                                        <Button variant="primary" className={"button"}
                                                onClick={() => this.props.history.push("registration/client")}>Weiter</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} style={{marginBottom: 40}}>
                                <Card style={{maxWidth: '36rem', margin: 'auto'}} className={'card'}>
                                    <Card.Img variant="top" style={{marginBottom: 10, borderRadius: 'unset'}}
                                              src={landingPageImageCaregiver}/>
                                    <Card.Body style={{padding: 0, borderRadius: 'unset'}}>
                                        <Card.Title>Betreuungspersonen</Card.Title>
                                        <Card.Text style={{height: "70px"}}>
                                            Ich biete Betreuungsleistung.
                                        </Card.Text>
                                        <Button variant="primary" className={"button"}
                                                onClick={() => this.props.history.push("registration/caregiver")}>Weiter</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} style={{marginBottom: 40}}>
                                <Card style={{maxWidth: '36rem', margin: 'auto'}} className={'card'}>
                                    <Card.Img variant="top" style={{marginBottom: 10, borderRadius: 'unset'}}
                                              src={landingPageImageLogin}/>
                                    <Card.Body style={{padding: 0, borderRadius: 'unset'}}>
                                        <Card.Title>Login</Card.Title>
                                        <Card.Text style={{height: "70px"}}>
                                            Ich möchte mich mit meinem Login als Betreuungsperson auf der Plattform
                                            anmelden um meine Daten einzusehen und anzupassen.
                                        </Card.Text>
                                        <Button variant="primary" className={"button"}
                                                onClick={() => this.props.history.push("dashboard")}>Weiter</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default LandingView;
