import AbstractModel from "./AbstractModel";

export default class PartnerCompany extends AbstractModel {

    constructor(id, name, streetAndNr, zipcode, city, country, phoneNumber) {
        super();

        this._id = id;
        this._name = name;
        this._streetAndNr = streetAndNr;
        this._zipcode = zipcode;
        this._city = city;
        this._country = country;
        this._phoneNumber = phoneNumber;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get streetAndNr() {
        return this._streetAndNr;
    }

    set streetAndNr(value) {
        this._streetAndNr = value;
    }

    get zipcode() {
        return this._zipcode;
    }

    set zipcode(value) {
        this._zipcode = value;
    }

    get city() {
        return this._city;
    }

    set city(value) {
        this._city = value;
    }

    get country() {
        return this._country;
    }

    set country(value) {
        this._country = value;
    }

    get phoneNumber() {
        return this._phoneNumber;
    }

    set phoneNumber(value) {
        this._phoneNumber = value;
    }

    toJSON() {
        return {
            id: this._id,
            name: this._name,
            streetAndNr: this._streetAndNr,
            zipcode: this._zipcode,
            city: this._city,
            country: this._country,
            phoneNumber: this._phoneNumber
        }
    }

    /**
     * @returns {PartnerCompany}
     */
    clone() {
        return this._clone(new PartnerCompany());
    }
}
