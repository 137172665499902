import React from "react";
import {Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";

class MultiDropdownField extends React.Component {

    constructor(props) {
        super(props);

        this.addOption = this.addOption.bind(this);
        this.removeOption = this.removeOption.bind(this);
        this.setOption = this.setOption.bind(this);
    }

    setOption(index, answer) {
        let options = this.props.value;
        console.log(index, answer);

        options[index] = answer;
        console.log(options);

        this.props.setAnswer(this.props.question, options);
    }

    addOption() {
        let options = this.props.value;
        options.push(this.props.question.choices[this.props.value.length].key);
        console.log(options);
        this.props.setAnswer(this.props.question, options);
    }

    removeOption(index) {
        let options = this.props.value;
        options.splice(index, 1);
        this.props.setAnswer(this.props.question, options);
    }

    render() {
        return (
            <Form.Group ref={this.props.ref} controlId={this.props.question} {...this.props}>
                {this.props.question ? <Form.Label
                    style={{fontWeight: 'bold'}}>{this.props.question.question[0].text}{this.renderRequired()}</Form.Label> : ""}
                {this.renderDropdownInputs()}
                {this.renderAddOption()}
            </Form.Group>
        )
    }

    renderDropdownInputs() {
        let renderedInputs = [];

        this.props.value.map((elem, i) => {
            renderedInputs.push(
                <div className="d-flex" style={{alignItems: "center"}}>
                    {this.renderRemoveOption(i)}
                    <Form.Control name={"dropdown" + i} style={{width: 200, marginBottom: 10}}
                                  as="select"
                                  value={this.matchStringToIndex(elem)}
                                  isInvalid={this.props.invalid}
                                  onChange={(e) =>
                                      this.setOption(i, this.props.question.choices[e.target.value].key)
                                  }>
                        {this.renderOptions()}
                    </Form.Control>
                </div>
            )
        });

        return renderedInputs
    }

    renderOptions() {
        let renderedOptions = [];
        let choices = this.props.question.choices;
        for (let i in choices) {
            let choice = choices[i];
            renderedOptions.push(<option key={choice.key} value={i}>{choice.name}</option>)
        }
        return renderedOptions;
    }

    renderAddOption() {
        if (this.props.value.length < this.props.question.choices.length) {
            return (
                <FontAwesomeIcon icon={faPlusCircle}
                                 style={{fontSize: 16, marginBottom: 40, marginTop: 10}}
                                 onClick={this.addOption}/>
            )
        } else {
            return (
                <p style={{fontSize: 16, marginBottom: 40}}>Alle möglichen Sprachen wurden bereits ausgewählt.</p>
            )
        }
    }

    renderRemoveOption(index) {
        if (this.props.value.length > 1) {
            return <FontAwesomeIcon style={{fontSize: 16, marginRight: 10}}
                                    icon={faMinusCircle} onClick={() => this.removeOption(index)}/>
        }
    }

    matchStringToIndex(elem) {
        for (let i in this.props.question.choices) {
            let choice = this.props.question.choices[i];
            if (choice.key === elem) {
                return i;
            }
        }
        return 0;
    }

    renderRequired() {
        if (this.props.required) return '*';
        return '';
    }
}

export default MultiDropdownField;
