import React from "react";
import {Accordion, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import "../../views/DashboardView/DashboardView.css";


export default class DashboardMenuCaregiver extends React.Component {
    render() {
        return (
            <Accordion>
                <Card>
                    <Accordion.Toggle as="a" eventKey="0" style={{color: "black"}} href="#">
                        <Card.Header className="CategoryHeaderStyle" style={{marginBottom: "5px"}}>
                            Mein Profil
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="MenuItemStyle">
                            <div>
                                <Link to="/dashboard/profile">Adresse</Link>
                            </div>
                            <div>
                                {
                                    this.props.isCaregiver ?
                                        <Link to="/dashboard/staff-magazine">Personalblatt</Link> :
                                        <Link to="/dashboard/client-related">Angehörige Personen</Link>
                                }
                            </div>
                            <div>
                                {
                                    this.props.isCaregiver ?
                                        <Link to="/dashboard/languages">Sprachkompetenzen</Link> : null
                                }
                            </div>
                            <div>
                                <Link to="/dashboard/password">Passwort ändern</Link>
                            </div>
                            <div>
                                <Link to="/dashboard/email">E-Mail ändern</Link>
                            </div>
                            <div>
                                {
                                    this.props.isCaregiver ?
                                        <Link to="/dashboard/upload">Nachweise (Upload)</Link> :
                                        <Link to="/dashboard/self-declaration">Selbstdeklaration</Link>
                                }
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                {this.props.isCaregiver && (
                    <Card className="CategoryHeaderStyle">
                        <Link to="/dashboard/availability">
                            <Card.Header>
                                Verfügbarkeit
                            </Card.Header>
                        </Link>
                    </Card>
                )}
                {this.props.isCaregiver && (
                    <Card className="CategoryHeaderStyle">
                        <a href="https://www.iaha.ch/de/bildung-services/uebersicht"
                           target="_blank"
                           rel="noreferrer noopener">
                            <Card.Header>
                                Aus- und Weiterbildung
                            </Card.Header>
                        </a>
                    </Card>
                )}
                <Card className="CategoryHeaderStyle">
                    <a href="https://www.iaha.ch/de/community/mitglied-werden"
                       target="_blank"
                       rel="noreferrer noopener">
                        <Card.Header>
                            Community
                        </Card.Header>
                    </a>
                </Card>
            </Accordion>
        )
    }
}
