import {electronicFormatIBAN, friendlyFormatIBAN, isValidIBAN} from 'ibantools';

/**
 * @template T
 * @param {T} v
 * @returns {T}
 */
export const identity = v => v;

/**
 * @param {string} value
 * @returns {string}
 */
export const formatSocialSecurityNumber = (value) => {
    const ssn = value.trim().split('').filter(char => char !== '.').join('');

    switch (true) {
        case ssn.length === 16:
            return ssn;
        case ssn.length === 13 && ssn.indexOf('.') === -1:
            const group1 = ssn.substr(0, 3);
            const group2 = ssn.substr(3, 4);
            const group3 = ssn.substr(7, 4);
            const group4 = ssn.substr(11, 2);

            return group1 + '.' + group2 + '.' + group3 + '.' + group4;
        default:
            return value.trim();
    }
};

/**
 * @param {string} value
 * @returns {string}
 */
export const formatIBAN = (value) => {
    const electronicIBAN = electronicFormatIBAN(value)
    const friendlyIBAN = friendlyFormatIBAN(value);

    return isValidIBAN(electronicIBAN) ? friendlyIBAN.trim() : value;
};