import {jsonToLocal} from "../util/Mapper";
import AbstractModel from "./AbstractModel";

export default class ScoreCategory extends AbstractModel {

    constructor(id, description, superCategory) {
        super();

        this._id = id;
        this._description = description;
        this._superCategory = superCategory;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get superCategory() {
        return this._superCategory;
    }

    set superCategory(value) {
        this._superCategory = value;
    }

    toJSON() {
        let descriptions = [];
        for (let description of this._description) {
            descriptions.push(jsonToLocal(description));
        }

        return ({
            id: this._id,
            description: descriptions,
            scoreSuperCategory: this._superCategory ? this._superCategory.toJSON() : ""
        })
    }

    /**
     * @returns {ScoreCategory}
     */
    clone() {
        return this._clone(new ScoreCategory());
    }
}
