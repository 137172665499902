/** @typedef {import('./JsonStringifyInterface').default} JsonStringifyInterface */
/** @typedef {import('./CloneableInterface').default} CloneableInterface */

/**
 * @implements {JsonStringifyInterface}
 * @implements {CloneableInterface}
 * @abstract
 */
export default class AbstractModel {
    /**
     * Marshall the object to JSON. Will be invoked during JSON.stringify(obj).
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#tojson_behavior}
     * @abstract
     * @returns {{}}
     */
    toJSON() {
        throw new Error('not implemented');
    }

    /**
     * @abstract
     */
    clone() {
        throw new Error('not implemented');
    }

    /**
     * @template T
     * @param {T} targetObj
     * @returns {T}
     * @protected
     */
    _clone(targetObj) {
        for (const [key, value] of Object.entries(this)) {
            targetObj[key] = this._cloneValue(value);
        }

        return targetObj;
    }

    /**
     * @template T
     * @param {T} value
     * @returns {T}
     * @private
     */
    _cloneValue(value) {
        switch (true) {
            case typeof value?.clone === 'function':
                return value.clone();
            case value instanceof Set:
                return new Set([...value.values()]);
            case Array.isArray(value):
                return value.map(this._cloneValue.bind(this));
            default:
                return value;
        }
    }
}