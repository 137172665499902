import React from 'react';
import PropTypes from 'prop-types';

export default function Box({style, children}) {
    return (
        <div className={`${style ?? 'gray'}BoxStyle`}>
            {children}
        </div>
    );
}

Box.propTypes = {
    style: PropTypes.oneOf(['white', 'gray'])
};