import AbstractModel from "./AbstractModel";

export default class ClientObserver extends AbstractModel {

    constructor(id, userInfo, clients, client, languages) {
        super();

        this._id = id;
        this._userInfo = userInfo;
        this._clients = clients;
        this._client = client;
        this._languages = languages;
    }

    get id() {
        return this._id;
    }

    get userInfo() {
        return this._userInfo;
    }

    set userInfo(value) {
        this._userInfo = value;
    }

    get clients() {
        return this._clients;
    }

    set clients(value) {
        this._clients = value;
    }

    get client() {
        return this._client;
    }

    set client(value) {
        this._client = value;
    }

    set languages(value) {
        this._languages = value;
    }

    get languages() {
        return this._languages;
    }

    toJSON() {
        let clients = [];
        if (this._clients !== null) {
            for (let client of this._clients) {
                clients.push(client.toJSON());
            }
        }

        return {
            id: this._id,
            userInfo: this._userInfo ? this._userInfo.toJSON() : "",
            clients: clients,
            client: this._client ? this._client.toJSON() : "",
            languages: this._languages
        }
    }

    /**
     * @returns {ClientObserver}
     */
    clone() {
        return this._clone(new ClientObserver());
    }
}
