import AbstractField from './AbstractField';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import React from 'react';

/** @typedef {import('formik').FieldInputProps} FieldInputProps */
/** @typedef {import('formik').FieldMetaProps} FieldMetaProps */
/** @typedef {import('formik').FieldProps} FieldProps */
/** @typedef {import('formik').FormikProps} FormikProps */

export default class CheckboxGroupField extends AbstractField {
    /**
     * @returns {[[string,string]]}
     */
    get options() {
        return this.props.options;
    }

    renderField({field, form, meta}) {
        return (
            <div style={{height: "24px"}}>
                {this.options.map(option => this._renderOptionCheckbox(field, form, meta, option))}
            </div>
        );
    }

    /**
     * @param {FieldInputProps} field
     * @param {FormikProps} form
     * @param {FieldMetaProps} meta
     * @param {[string,string]}option
     * @returns {JSX.Element}
     * @private
     */
    _renderOptionCheckbox(field, form, meta, option) {
        const [id, label] = option;
        const checked = this._isChecked(id, field.value);
        const checkbox = (
            <Checkbox required={this.required}
                      checked={checked}
                      name={label}
                      onChange={() => this._onChange(field, form, id)}/>
        );

        return (
            <FormControlLabel key={id}
                              style={{marginTop: "-9px"}}
                              disabled={this.disabled(field.value)}
                              control={checkbox}
                              label={label}/>
        );
    }

    /**
     * @param {string} id
     * @param {[string]|undefined}value
     * @private
     */
    _isChecked(id, value) {
        return new Set(value ?? []).has(id);
    }

    /**
     * @param {FieldInputProps} field
     * @param {FormikProps} form
     * @param {string} id
     * @returns {boolean}
     * @private
     */
    _onChange(field, form, id) {
        /**
         * @type {[]|undefined}
         */
        const value = field.value;
        const valueSet = new Set(value ?? []);

        if (valueSet.has(id)) {
            valueSet.delete(id);
        } else {
            valueSet.add(id);
        }

        form.setFieldValue(field.name, [...valueSet.values()]);

        return valueSet.has(id);
    }
}

CheckboxGroupField.propTypes = {
    ...AbstractField.propTypes,
    options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired
}