import AbstractModel from "./AbstractModel";

/** @typedef {import('./ValidationResultItem').default} ValidationResultItem */

export default class ValidationResult extends AbstractModel {
    /**
     * @type {string}
     * @private
     */
    _message = undefined;
    /**
     * @type {ValidationResultItem[]}
     * @private
     */
    _items = undefined;

    /**
     * @param {string} [message]
     * @param {ValidationResultItem[]} [items]
     */
    constructor(message, items) {
        super();

        this._message = message;
        this._items = items;
    }

    /**
     * @returns {string}
     */
    get message() {
        return this._message;
    }

    /**
     * @param {string} message
     */
    set message(message) {
        this._message = message;
    }

    /**
     * @returns {ValidationResultItem[]}
     */
    get items() {
        return this._items;
    }

    /**
     * @param {ValidationResultItem[]} items
     */
    set items(items) {
        this._items = items;
    }

    toJSON() {
        return {
            message: this._message,
            items: this._items
        };
    }

    /**
     * @returns {ValidationResult}
     */
    clone() {
        return this._clone(new ValidationResult());
    }
}