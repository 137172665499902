import React from 'react';
import '../../App.css';
import './MeetingPicker.css';
import {Button} from "react-bootstrap";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import Meeting from "../../models/Meeting";

export default class MeetingPicker extends React.Component {

    constructor(props) {
        super(props);
        this.addDate = this.addDate.bind(this);
        this.removeDate = this.removeDate.bind(this);
        this.changeDate = this.changeDate.bind(this);
    }

    generatePossibleTimes() {
        let possibleTimes = [];
        for (let hour = 8; hour <= 17; hour++) {
            possibleTimes += hour + ':00';
        }
        return possibleTimes;
    }

    isWeekday(date) {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    addDate() {
        let meetings = this.props.meetings;
        meetings.push(new Meeting(null, null, null,
            null, null, null, null, null));
        this.props.setMeetings(meetings);
    }

    removeDate(index) {
        let meetings = this.props.meetings;
        meetings.splice(index, 1);
        this.props.setMeetings(meetings);
    }

    changeDate(date, index) {
        let meetings = this.props.meetings;
        meetings[index].date = date;
        this.props.setMeetings(meetings);
    }

    render() {
        let renderedTimes = [];

        for (let index in this.props.meetings) {
            renderedTimes.push(
                <div className="d-flex" style={{alignItems: "center", marginBottom: 5}}>
                    {this.renderDatePicker(index)}
                    {this.renderRemoveDateButton(index)}
                </div>
            )
        }

        return (
            <div>
                {renderedTimes}
                {this.renderAddDateButton()}
            </div>
        )
    }

    renderDatePicker(index) {
        return (
            <DatePicker
                selected={this.props.meetings[index].date}
                onChange={date => this.changeDate(date, index)}
                minDate={new Date().setDate(new Date().getDate() + 2)}
                filterDate={this.isWeekday}
                showTimeSelect
                minTime={new Date().setHours(8, 0, 0, 0)}
                maxTime={new Date().setHours(17, 0, 0, 0)}
                dateFormat="dd.MM, H:mm"
                timeFormat="H:mm"
                timeCaption="Uhrzeit"
                placeholderText="Wählen Sie ein Datum"
            />
        )
    }

    renderRemoveDateButton(index) {
        if (this.props.meetings.length > 1)
            return (
                <Button variant={""} className={"iconButton"} style={{marginLeft: 5}}
                        onClick={() => this.removeDate(index)}>
                    <FontAwesomeIcon style={{fontSize: 16}}
                                     icon={faMinusCircle}/>
                </Button>
            )
    }

    renderAddDateButton() {
        let disabled = !this.props.meetings[this.props.meetings.length - 1];

        return (
            <Button disabled={disabled} variant={""} className={"iconButton"} style={{marginBottom: 40}}
                    onClick={this.addDate}>
                <FontAwesomeIcon icon={faPlusCircle}
                                 style={{fontSize: 16}}/>
            </Button>
        )
    }
}
