import React from "react";
import {Button, Form} from "react-bootstrap";
import ApiService from "../../services/ApiService";
import QuestionTextField from "../questionFields/QuestionTextField";
import MultipleCheckboxField from "../questionFields/MultipleCheckboxField";
import RadioButtonField from "../questionFields/RadioButtonField";
import '../../App.css';
import {valNotEmpty} from "../../util/FormValidations";
import AnswerChoice from "../../models/AnswerChoice";
import QuestionChoice from "../../models/QuestionChoice";
import Local from "../../models/Local";
import PriorityField from "../questionFields/PriorityField";


class CaregiverRegistrationDeclarationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            //questionId: { answer: {}, question:{} }
            questionAnswerMap: {},
            //superCategory: [ quesitonId1, questionId2]
            scoreSuperCategoryQuestionIdMap: {}
        };

        this.setAnswer = this.setAnswer.bind(this);
        this.validate = this.validate.bind(this);
        this.goNext = this.goNext.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        this.props.setValidate(this.validate);

        if (this.props.declarationQuestionAnswerMap !== null) {
            let scoreSuperCategoryQuestionIdMap = {};
            for (let clientDeclarationQuestionAnswer of Object.values(this.props.declarationQuestionAnswerMap)) {
                this.orderByCategory(clientDeclarationQuestionAnswer.question, scoreSuperCategoryQuestionIdMap)
            }
            this.numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, this.props.declarationQuestionAnswerMap);
            this.setState({
                questionAnswerMap: this.props.declarationQuestionAnswerMap,
                scoreSuperCategoryQuestionIdMap: scoreSuperCategoryQuestionIdMap
            });
        } else {
            ApiService.getCaregiverDeclarationQuestions(this.props.keycloak).then(caregiverDeclarationQuestions => {
                let questionAnswerMap = {};
                let scoreSuperCategoryQuestionIdMap = {};
                for (let caregiverDeclarationQuestion of caregiverDeclarationQuestions) {
                    questionAnswerMap[caregiverDeclarationQuestion.id] = {
                        question: caregiverDeclarationQuestion,
                        answers: this.setInitialAnswers(caregiverDeclarationQuestion.answerType),
                        validator: valNotEmpty,
                        invalid: false,
                        ref: React.createRef()
                    };
                    this.orderByCategory(caregiverDeclarationQuestion, scoreSuperCategoryQuestionIdMap);
                }
                this.numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, questionAnswerMap);
                this.orderAnswersChoices(questionAnswerMap);

                this.setState({
                    questionAnswerMap: questionAnswerMap,
                    scoreSuperCategoryQuestionIdMap: scoreSuperCategoryQuestionIdMap
                });
            }).catch(error => console.error(error.message));
        }
    };

    setInitialAnswers(answerType) {
        if (answerType === "multiple-choice" || answerType === "priority-choice")
            return [];
        else
            return [new AnswerChoice(null, new QuestionChoice(null, [new Local(null, "", null)]))]
    }

    orderByCategory(clientDeclarationQuestion, scoreSuperCategoryQuestionIdMap) {
        let scoreSuperCategory = clientDeclarationQuestion.scoreCategory.superCategory.description[0].text;
        if (!(scoreSuperCategory in scoreSuperCategoryQuestionIdMap))
            scoreSuperCategoryQuestionIdMap[scoreSuperCategory] = [];
        scoreSuperCategoryQuestionIdMap[scoreSuperCategory].push(
            clientDeclarationQuestion.id);
    }

    numberScoreSuperCategoriesAndQuestions(scoreSuperCategoryQuestionIdMap, questionAnswerMap) {
        let scoreSuperCategoryQuestionIdMapTemp = {};
        let superScoreCategoryNumber = 1;
        for (let superScoreCategory of Object.keys(scoreSuperCategoryQuestionIdMap)) {
            let questionIds = scoreSuperCategoryQuestionIdMap[superScoreCategory];
            let questionNumber = 1;
            for (let questionId of questionIds) {
                let question = questionAnswerMap[questionId].question;
                questionAnswerMap[questionId].question.question[0].text =
                    superScoreCategoryNumber + '.' + questionNumber + '. ' + question.question[0].text;
                questionNumber++;
            }
            scoreSuperCategoryQuestionIdMapTemp[superScoreCategoryNumber + '. ' + superScoreCategory] =
                scoreSuperCategoryQuestionIdMap[superScoreCategoryNumber];

            superScoreCategoryNumber++;
        }
        scoreSuperCategoryQuestionIdMap = scoreSuperCategoryQuestionIdMapTemp;
    }

    orderAnswersChoices(questionAnswerMap) {
        for (let questionId of Object.keys(questionAnswerMap)) {
            questionAnswerMap[questionId].question.choices.sort((first, second) => {
                if (!first || !first.viewOrder) {
                    // first is null or undefined
                    return -1;
                }
                if (!second || !second.viewOrder) {
                    // second is null or undefined
                    return 1;
                }
                return first.viewOrder - second.viewOrder;
            });
        }
    }

    setAnswer(questionId, answer, questionType) {
        let questionAnswerMap = this.state.questionAnswerMap;
        let choice = null;

        if (questionType === "single-choice") {
            for (let choiceTemp of questionAnswerMap[questionId].question.choices) {
                if (choiceTemp.text[0].text === answer) {
                    choice = choiceTemp;
                    break;
                }
            }
            questionAnswerMap[questionId].answers[0].choice = choice;
        } else if (questionType === "multiple-choice") {
            questionAnswerMap[questionId].answers = answer;
        } else if (questionType === "priority-choice") {
            questionAnswerMap[questionId].answers = answer
        } else {
            questionAnswerMap[questionId].answers[0].choice.text[0].text = answer;
        }

        this.setState({questionAnswerMap: questionAnswerMap});
    }

    renderCaregiverRegistrationDeclarationForm() {

        if (Object.keys(this.state.questionAnswerMap).length > 0) {
            return (
                <Form>
                    {this.renderQuestions()}
                    <div style={{marginTop: 40}}>
                        <Button style={{marginRight: 20}} onClick={this.goBack} className={'button'}>
                            Zurück
                        </Button>
                        <Button className={'button'} onClick={() => {
                            if (this.validate()) {
                                this.goNext();
                            }
                        }}>
                            Weiter
                        </Button>
                    </div>
                </Form>
            )
        }
        return <p>No Assessment Questions available</p>;
    }

    validate() {
        let focusTo = null;
        let allValid = true;
        let questionAnswerMap = this.state.questionAnswerMap;
        for (let questionAnswerKey in questionAnswerMap) {
            let questionAnswer = questionAnswerMap[questionAnswerKey];
            let valid = false;
            if (questionAnswer.question.answerType === "priority-choice")
                //Every choice must be chosen
                valid = questionAnswer.answers.length === questionAnswer.question.choices.length;
            else
                valid = questionAnswer.answers.length > 0 && questionAnswer.validator(questionAnswer.answers[0].choice.text[0].text);
            questionAnswer.invalid = !valid;
            if (allValid && !valid) {
                focusTo = questionAnswer.ref;
                allValid = false;
            }
        }

        if (allValid) {
            return true;
        } else {
            focusTo.current.scrollIntoView();
            this.setState({
                questionAnswerMap: questionAnswerMap,
            });
            return false;
        }
    }

    goNext() {
        this.props.setDeclarationData(this.state.questionAnswerMap);
        this.props.setStep('declarationFiles');
    }

    goBack() {
        this.props.setStep('declarationIntro');
    }

    renderQuestions() {
        let renderedCategories = [];

        for (let superScoreCategory of Object.keys(this.state.scoreSuperCategoryQuestionIdMap)) {
            let renderedQuestions = [];
            let questionIds = this.state.scoreSuperCategoryQuestionIdMap[superScoreCategory];
            for (let questionId of questionIds) {
                let question = this.state.questionAnswerMap[questionId].question;
                renderedQuestions.push(
                    <div style={{marginBottom: 40}}>
                        {this.renderQuestionByType(question)}
                    </div>
                );
            }
            renderedCategories.push(
                <div style={{marginTop: 20, marginBottom: 40}}>
                    <h5 style={{marginBottom: 10, fontWeight: 'bold'}}>{superScoreCategory}</h5>
                    {renderedQuestions}
                </div>
            );
        }

        return renderedCategories;
    }

    renderQuestionByType(question) {
        switch (question.answerType) {
            default:
                return <QuestionTextField setAnswer={this.setAnswer}
                                          question={question}
                                          value={this.state.questionAnswerMap[question.id].answers[0].choice.text[0].text}
                                          invalid={this.state.questionAnswerMap[question.id].invalid}
                                          ref={this.state.questionAnswerMap[question.id].ref}
                                          required
                />;
            case 'single-choice':
                return <RadioButtonField setAnswer={this.setAnswer}
                                         question={question}
                                         value={this.state.questionAnswerMap[question.id].answers[0].choice.text[0].text}
                                         invalid={this.state.questionAnswerMap[question.id].invalid}
                                         ref={this.state.questionAnswerMap[question.id].ref}
                                         required
                />;
            case 'multiple-choice':
                return <MultipleCheckboxField setAnswer={this.setAnswer}
                                              question={question}
                                              value={this.state.questionAnswerMap[question.id].answers}
                                              invalid={this.state.questionAnswerMap[question.id].invalid}
                                              ref={this.state.questionAnswerMap[question.id].ref}
                                              required
                />;
            case 'priority-choice':
                return <PriorityField setAnswer={this.setAnswer}
                                      question={question}
                                      value={this.state.questionAnswerMap[question.id].answers}
                                      invalid={this.state.questionAnswerMap[question.id].invalid}
                                      ref={this.state.questionAnswerMap[question.id].ref}
                                      required/>;
        }
    }

    render() {
        return (
            <div>
                {this.renderCaregiverRegistrationDeclarationForm()}
            </div>
        );
    }
}

export default CaregiverRegistrationDeclarationForm;
