import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import ApiService from "../../services/ApiService";
import QuestionTextField from "../questionFields/QuestionTextField";
import DateField from "../questionFields/DateField";
import CaregiverDeclarationQuestion from "../../models/CaregiverDeclarationQuestion";
import QuestionChoice from "../../models/QuestionChoice";
import DropdownField from "../questionFields/DropdownField";
import '../../App.css';
import {
    convertToAPICountry,
    convertToAPIGender,
    convertToLocalizedCountry,
    convertToLocalizedGender
} from "../../util/FieldConverter";
import {valNotEmpty} from "../../util/FormValidations";
import {withKeycloak} from "@react-keycloak/web";
import LanguageSkillInput from "../inputs/LanguageSkillInput";
import Country from "../../enums/Country";


class CaregiverRegistrationProfileForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            validationFunctions: [],
            partnerCompany: {
                question: new CaregiverDeclarationQuestion(null, null, [{text: ""}],
                    null, [
                        new QuestionChoice(1, [{text: "Keine"}])
                    ]),
                answer: null,
                validator: valNotEmpty,
                invalid: false,
                ref: React.createRef()
            },
            questionAnswerMap: {
                firstName: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Vorname"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                lastName: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Nachname"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                streetAndNr: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Strasse"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                city: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Ort"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                zipcode: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "PLZ"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                country: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Land"}],
                        null, this.countries),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                nationality: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Nationalität"}],
                        null, this.countries),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                languageSkills: {
                    question: "Sprachkenntnisse",
                    answer: [],
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                birthDate: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Geburtsdatum"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                phoneNumber: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Telefon"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                mobileNumber: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Telefon Mobil"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                gender: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Anrede"}],
                        null, [
                            new QuestionChoice(1, [{text: "Herr"}]),
                            new QuestionChoice(2, [{text: "Frau"}]),
                            new QuestionChoice(3, [{text: "Andere"}])
                        ]),
                    answer: "male",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
            },
        };
        this.setAnswer = this.setAnswer.bind(this);
        this.validate = this.validate.bind(this);
        this.goNext = this.goNext.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        ApiService.getPartnerCompanies(this.props.keycloak).then(partnerCompanies => {
            let partnerCompanyState = this.state.partnerCompany;
            for (let partnerCompanyIndex in partnerCompanies) {
                let partnerCompany = partnerCompanies[partnerCompanyIndex];
                partnerCompanyState.question.choices.push(new QuestionChoice(partnerCompanyIndex, [{text: partnerCompany.id}]))
            }
            this.setState({partnerCompany: partnerCompanyState});
        });

        if (this.props.profileQuestionAnswerMap !== null) {
            this.setState({questionAnswerMap: this.props.profileQuestionAnswerMap});
        }

        this.props.setValidate(this.validate);
    }

    setAnswer(question, answer) {
        let questionAnswerMap = this.state.questionAnswerMap;
        questionAnswerMap[question].answer = answer;
        this.setState({questionAnswerMap: questionAnswerMap})
    }

    getQuestionByName(name) {
        return this.state.questionAnswerMap[name];
    }

    renderCaregiverRegistrationProfileForm() {
        return (
            <Form>
                <h5 style={{marginBottom: 0}}>Partnerfirma</h5>
                <p>Wenn Sie sich über eine Partnerfirma anmelden, dann wählen Sie bitte die entsprechende
                    Identifizierungsnummer aus. Falls Sie sich eigenständig anmelden, belassen Sie es mit "Keine".</p>
                <DropdownField setAnswer={(question, answer) => {
                    let partnerCompany = this.state.partnerCompany;
                    partnerCompany.answer = answer;
                    if (answer === this.state.partnerCompany.question.choices[0].text[0].text)
                        partnerCompany.answer = null;
                    this.setState({partnerCompany: partnerCompany});
                }}
                               question={this.state.partnerCompany.question}
                               value={this.state.partnerCompany.answer}
                />
                <h5 style={{marginBottom: 0, marginTop: 50}}>Kontakt</h5>
                <p style={{marginBottom: 20}}><small>Mit * gekennzeichnete Felder sind Pflichtfelder</small></p>
                {this.renderQuestions()}
                <div style={{marginTop: 40}}>
                    <Button style={{marginRight: 20}} onClick={this.goBack} className={'button'}>
                        Zurück
                    </Button>
                    <Button className={'button'} onClick={() => {
                        if (this.validate()) {
                            this.goNext();
                        }
                    }}>
                        Weiter
                    </Button>
                </div>
            </Form>
        )
    }

    validate() {
        let focusTo = null;
        let allValid = true;
        let questionAnswerMap = this.state.questionAnswerMap;
        for (let questionAnswerKey in questionAnswerMap) {
            let questionAnswer = questionAnswerMap[questionAnswerKey];
            let valid = questionAnswer.validator(questionAnswer.answer);
            questionAnswer.invalid = !valid;
            if (allValid && !valid) {
                focusTo = questionAnswer.ref;
                allValid = false;
            }
        }

        if (allValid) {
            return true;
        } else {
            focusTo.current.scrollIntoView();
            this.setState({
                questionAnswerMap: questionAnswerMap,
            });
            return false;
        }
    }

    goNext() {
        this.props.setProfileData(this.state.questionAnswerMap);
        this.props.setPartnerCompany(this.state.partnerCompany);
        this.props.setStep("declarationIntro");
    }

    goBack() {
        this.props.setStep('start');
    };

    renderQuestions() {
        let renderedQuestions = [];

        renderedQuestions.push(<DropdownField
            setAnswer={(question, answer) => this.setAnswer("gender", convertToAPIGender(answer))}
            question={this.state.questionAnswerMap["gender"].question}
            value={convertToLocalizedGender(this.state.questionAnswerMap["gender"].answer)}
            invalid={this.state.questionAnswerMap["gender"].invalid}
            ref={this.state.questionAnswerMap["gender"].ref}
            required
        />);
        renderedQuestions.push(<QuestionTextField setAnswer={(question, answer) => this.setAnswer("firstName", answer)}
                                                  question={this.state.questionAnswerMap["firstName"].question}
                                                  value={this.state.questionAnswerMap["firstName"].answer}
                                                  invalid={this.state.questionAnswerMap["firstName"].invalid}
                                                  ref={this.state.questionAnswerMap["firstName"].ref}
                                                  required
        />);
        renderedQuestions.push(<QuestionTextField setAnswer={(question, answer) => this.setAnswer("lastName", answer)}
                                                  question={this.state.questionAnswerMap["lastName"].question}
                                                  value={this.state.questionAnswerMap["lastName"].answer}
                                                  invalid={this.state.questionAnswerMap["lastName"].invalid}
                                                  ref={this.state.questionAnswerMap["lastName"].ref}
                                                  required
        />);
        renderedQuestions.push(<QuestionTextField
            setAnswer={(question, answer) => this.setAnswer("streetAndNr", answer)}
            question={this.state.questionAnswerMap["streetAndNr"].question}
            value={this.state.questionAnswerMap["streetAndNr"].answer}
            invalid={this.state.questionAnswerMap["streetAndNr"].invalid}
            ref={this.state.questionAnswerMap["streetAndNr"].ref}
            required
        />);
        renderedQuestions.push(
            <Row>
                <Col xs={3}>
                    <QuestionTextField setAnswer={(question, answer) => this.setAnswer("zipcode", answer)}
                                       question={this.state.questionAnswerMap["zipcode"].question}
                                       value={this.state.questionAnswerMap["zipcode"].answer}
                                       invalid={this.state.questionAnswerMap["zipcode"].invalid}
                                       ref={this.state.questionAnswerMap["zipcode"].ref}
                                       required
                    />
                </Col>
                <Col xs={5}>
                    <QuestionTextField setAnswer={(question, answer) => this.setAnswer("city", answer)}
                                       question={this.state.questionAnswerMap["city"].question}
                                       value={this.state.questionAnswerMap["city"].answer}
                                       invalid={this.state.questionAnswerMap["city"].invalid}
                                       ref={this.state.questionAnswerMap["city"].ref}
                                       required
                    />
                </Col>
            </Row>);
        renderedQuestions.push(<DropdownField
            setAnswer={(question, answer) => this.setAnswer("country", convertToAPICountry(answer))}
            question={this.state.questionAnswerMap["country"].question}
            value={convertToLocalizedCountry(this.state.questionAnswerMap["country"].answer)}
            invalid={this.state.questionAnswerMap["country"].invalid}
            ref={this.state.questionAnswerMap["country"].ref}
            required
        />);
        renderedQuestions.push(<DropdownField
            setAnswer={(question, answer) => this.setAnswer("nationality", convertToAPICountry(answer))}
            question={this.state.questionAnswerMap["nationality"].question}
            value={convertToLocalizedCountry(this.state.questionAnswerMap["nationality"].answer)}
            invalid={this.state.questionAnswerMap["nationality"].invalid}
            ref={this.state.questionAnswerMap["nationality"].ref}
            required
        />);
        renderedQuestions.push(<LanguageSkillInput
            setAnswer={(question, answer) => this.setAnswer("languageSkills", answer)}
            id="languageSkills"
            question={this.state.questionAnswerMap["languageSkills"].question}
            value={this.state.questionAnswerMap["languageSkills"].answer}
            invalid={this.state.questionAnswerMap["languageSkills"].invalid}
            ref={this.state.questionAnswerMap["languageSkills"].ref}
            required
        />);
        renderedQuestions.push(<DateField setAnswer={(question, answer) => this.setAnswer("birthDate", answer)}
                                          question={this.state.questionAnswerMap["birthDate"].question}
                                          value={this.state.questionAnswerMap["birthDate"].answer}
                                          invalid={this.state.questionAnswerMap["birthDate"].invalid}
                                          ref={this.state.questionAnswerMap["birthDate"].ref}
                                          required
        />);
        renderedQuestions.push(<QuestionTextField
            setAnswer={(question, answer) => this.setAnswer("phoneNumber", answer)}
            question={this.state.questionAnswerMap["phoneNumber"].question}
            value={this.state.questionAnswerMap["phoneNumber"].answer}
            invalid={this.state.questionAnswerMap["phoneNumber"].invalid}
            ref={this.state.questionAnswerMap["phoneNumber"].ref}
            required
        />);
        renderedQuestions.push(<QuestionTextField
            setAnswer={(question, answer) => this.setAnswer("mobileNumber", answer)}
            question={this.state.questionAnswerMap["mobileNumber"].question}
            value={this.state.questionAnswerMap["mobileNumber"].answer}
            invalid={this.state.questionAnswerMap["mobileNumber"].invalid}
            ref={this.state.questionAnswerMap["mobileNumber"].ref}
            required
        />);
        return renderedQuestions;
    }

    render() {
        return (
            <div>
                <div>
                    {this.renderCaregiverRegistrationProfileForm()}
                </div>
            </div>
        );
    }


    countries = this._getCountries();

    /**
     * @returns {QuestionChoice[]}
     * @private
     */
    _getCountries() {
        return Country.values().map((country, index) => new QuestionChoice(index + 1, [{text: country.label}]));
    }
}

export default withKeycloak(CaregiverRegistrationProfileForm);
