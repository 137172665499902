import React from 'react';
import {Alert} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {useFormikContext} from 'formik';
import AbstractForm from './AbstractForm';

export const SUCCESS = 'success';

export default function Feedback({type, children}) {
    const formik = useFormikContext();
    const variant = type === SUCCESS ? SUCCESS : 'danger';
    const show = (state) => variant === SUCCESS ? state === true : [true, undefined].indexOf(state) === -1;
    const state = formik[AbstractForm.FORM_SUBMIT_SUCCESS];
    const onClose = () => formik[AbstractForm.FORM_SUBMIT_SUCCESS_RESET]();
    const closeable = state !== true;

    return (
        <Alert show={show(state)} variant={variant} className="formFeedback" dismissible={closeable} onClose={onClose}>
            {children}
        </Alert>
    );
};

Feedback.propTypes = {
    type: PropTypes.oneOf(['success', 'error']).isRequired
}