import React from 'react';
import {Form, Formik} from 'formik';
import i18next from 'i18next';
import {getLoading, setLoading} from '../../App';
import APIValidationError from "../../services/APIValidationError";
import APIBaseError from "../../services/APIBaseError";

/** @typedef {import('formik').FormikHelpers} FormikHelpers */
/** @typedef {import('formik').FormikProps} FormikProps */
/** @typedef {import('formik').FormikValues} FormikValues */
/** @typedef {import('formik').FormikErrors} FormikErrors} */

/**
 * @abstract
 */
export default class AbstractForm extends React.Component {
    /**
     * @type {string}
     */
    static FORM_SUBMIT_SUCCESS = 'formSubmitSuccess';
    /**
     * @type {string}
     */
    static FORM_SUBMIT_SUCCESS_RESET = 'formSubmitSuccessReset';
    /**
     * @type {string}
     */
    static FORM_INITIAL_LOAD = 'formInitialLoad';
    /**
     * @type {number}
     */
    static FORM_FEEDBACK_DELAY = 30000;

    /**
     * @param {{}} props
     * @param {{}} [state]
     */
    constructor(props, state) {
        super(props);

        this.state = {
            ...state ?? {},
            _formSubmitSuccess: undefined,
            _formSubmitTimeout: undefined,
            _formInitialLoad: true
        }
    }

    /**
     * @returns {boolean}
     */
    get loading() {
        return getLoading();
    }

    /**
     * @param {boolean} loading
     */
    set loading(loading) {
        setLoading(loading);
    }

    /**
     * @abstract
     * @returns {{}}
     */
    get initialValues() {
        throw new Error('not implemented');
    }

    /**
     * @returns {boolean|Error|undefined}
     * @private
     */
    get _formSubmitSuccess() {
        return this.state._formSubmitSuccess;
    }

    /**
     * @param {boolean|Error|undefined} state
     * @private
     */
    set _formSubmitSuccess(state) {
        clearTimeout(this._formSubmitTimeout);

        this.setState({
            _formSubmitSuccess: state
        });

        if (state === true) {
            this._formSubmitTimeout = window.setTimeout(() => this._formSubmitSuccess = undefined, AbstractForm.FORM_FEEDBACK_DELAY);
        }
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _formInitialLoad() {
        return this.state._formInitialLoad;
    }

    /**
     * @param {boolean} state
     * @private
     */
    set _formInitialLoad(state) {
        this.setState({
            _formInitialLoad: !!state
        });
    }

    /**
     * @returns {number|undefined}
     * @private
     */
    get _formSubmitTimeout() {
        return this.state._formSubmitTimeout;
    }

    /**
     * @param {number} timeout
     * @private
     */
    set _formSubmitTimeout(timeout) {
        this.setState({
            _formSubmitTimeout: timeout
        });
    }

    componentDidMount() {
        this._onLoad();
    }

    componentWillUnmount() {
        clearTimeout(this._formSubmitTimeout);
    }

    /**
     * @params {...any} args
     * @returns {string}
     */
    t(...args) {
        // noinspection JSCheckFunctionSignatures
        return i18next.t(...args);
    }

    /**
     * @abstract
     * @param {FormikValues} values
     * @param {FormikErrors} errors
     * @returns {FormikErrors}
     */
    onValidate(values, errors) {
        throw new Error('not implemented');
    }

    /**
     * @abstract
     * @param {FormikValues} values
     * @returns {Promise<any>}
     */
    onSubmit(values) {
        throw new Error('not implemented');
    }

    /**
     * @abstract
     * @returns {Promise<any>}
     */
    onLoad() {
        throw new Error('not implemented');
    }

    /**
     * @param {Error} error
     * @param {FormikValues} values
     * @param {FormikHelpers} actions
     */
    onError(error, values, actions) {
        console.error(error);
    }

    /**
     * @param {APIValidationError} error
     * @param {FormikValues} values
     * @param {FormikHelpers} actions
     */
    onValidationError(error, values, actions) {
        const errors = {};
        const touched = {};

        error.validationResult.items.forEach(item => {
            errors[item.property] = item.message
            touched[item.property] = true;
        });

        actions.setErrors(errors);
        actions.setTouched(touched, false);
    }

    /**
     * @param {APIBaseError} error
     * @param {FormikValues} values
     * @param {FormikHelpers} actions
     */
    onBaseError(error, values, actions) {
        console.error(error);
    }

    /**
     * @abstract
     * @param {FormikProps} formik
     * @returns {JSX.Element}
     */
    renderFormContent(formik) {
        throw new Error('not implemented');
    }

    /**
     * @returns {JSX.Element}
     */
    render() {
        return (
            <>
                <Formik onSubmit={this._onSubmit.bind(this)}
                        validate={this._onValidate.bind(this)}
                        enableReinitialize={true}
                        initialValues={this.initialValues}>
                    {formik => (
                        <Form noValidate={true}>
                            {this._renderFormContent(formik)}
                        </Form>
                    )}
                </Formik>
            </>
        );
    }

    /**
     * @param {FormikProps} formik
     * @returns {JSX.Element}
     * @private
     */
    _renderFormContent(formik) {
        formik[AbstractForm.FORM_SUBMIT_SUCCESS] = this._formSubmitSuccess;
        formik[AbstractForm.FORM_SUBMIT_SUCCESS_RESET] = () => this._formSubmitSuccess = undefined;
        formik[AbstractForm.FORM_INITIAL_LOAD] = this._formInitialLoad;

        return this.renderFormContent(formik);
    }

    /**
     * @param {FormikValues} values
     * @param {FormikHelpers} actions
     */
    _onSubmit(values, actions) {
        actions.setSubmitting(true);

        this.loading = true;

        this.onSubmit(values)
            .then(() => {
                this._formSubmitSuccess = true;

                actions.resetForm(this.initialValues);
            })
            .catch(error => this._onError(error, values, actions))
            .finally(() => {
                actions.setSubmitting(false);

                this.loading = false;
            });
    }

    /**
     * @param {Error|APIValidationError|APIBaseError} error
     * @param {FormikValues} values
     * @param {FormikHelpers} actions
     * @private
     */
    _onError(error, values, actions) {
        this._formSubmitSuccess = error;

        switch (true) {
            case error instanceof APIValidationError:
                this.onValidationError(error, values, actions);
                break;
            case error instanceof APIBaseError:
                this.onBaseError(error, values, actions);
                break;
            default:
                this.onError(error, values, actions);
                break;
        }
    }

    /**
     * @param {FormikValues} values
     * @param {FormikErrors} errors
     * @returns {FormikErrors}
     * @private
     */
    _onValidate(values, errors) {
        return this.onValidate(values, errors);
    }

    /**
     * @private
     */
    _onLoad() {
        this.loading = true;

        this.onLoad()
            .catch(this._onError.bind(this))
            .finally(() => {
                this.loading = false
                this._formInitialLoad = false;
            });
    }
}