import React from 'react';
import AbstractField from './AbstractField';
import PropTypes from 'prop-types';

export default class DropdownField extends AbstractField {
    static DEFAULT_VALUE = '-1';

    /**
     * @returns {string}
     */
    get placeholder() {
        return this.props.placeholder ?? '';
    }

    /**
     * @returns {undefined|[string, string][]}
     */
    get options() {
        return this.props.options ?? this._getOptionsFromEnum();
    }

    /**
     * @returns {AbstractEnum|undefined}
     */
    get enum() {
        return this.props.enum;
    }

    renderField({field, form, meta}) {
        return (
            <select name={field.name}
                    id={this.id}
                    value={field.value ?? DropdownField.DEFAULT_VALUE}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    autoComplete="new-password"
                    disabled={this.disabled(field.value)}>
                <option disabled={true} hidden={true} value={DropdownField.DEFAULT_VALUE}>
                    {this.t('pleaseSelect')} ...
                </option>
                {this.options.map((option, index) => <option key={index} value={option[0]}>{option[1]}</option>)}
            </select>
        );
    }

    /**
     * @returns {[string, string][]}
     * @private
     */
    _getOptionsFromEnum() {
        return (this.enum?.values() ?? []).map(obj => [obj.id, obj.label]);
    }
}

DropdownField.propTypes = {
    ...AbstractField.propTypes,
    enum: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};