import React from "react";
import '../../App.css';
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons'

class ClientRegistrationDeclarationFiles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [null]
        };

        this.addFileCertificateUploader = this.addFileCertificateUploader.bind(this);
        this.removeFileCertificateUploader = this.removeFileCertificateUploader.bind(this);
        this.removeNullFromFiles = this.removeNullFromFiles.bind(this);
        this.goNext = this.goNext.bind(this);
    }

    componentDidMount() {
        this.props.setValidate(null);
        if (this.props.files !== null) {
            this.setState({files: this.props.files});
        }
    }

    renderFileUploaderCertificate() {
        let renderedFileUploader = [];

        for (let index in this.state.files) {
            let file = this.state.files[index];

            if (file === null) {
                renderedFileUploader.push(
                    <div className="d-flex" style={{alignItems: "center"}}>
                        {this.renderRemoveFileCertificateButton(index)}
                        <input type={"file"} id={"fileCertificate" + index} accept={"application/pdf"}
                               onChange={(e) => this.addFileCertificate(e.target.files[0], index)}
                               style={{marginBottom: 5}}/>
                    </div>
                );
            } else {
                renderedFileUploader.push(
                    <div className="d-flex" style={{alignItems: "center", marginBottom: 5}}>
                        {this.renderRemoveFileCertificateButton(index)}
                        {this.state.files[index].name}
                    </div>
                )
            }
        }
        return renderedFileUploader;
    }

    renderRemoveFileCertificateButton(index) {
        if (this.state.files.length !== 1 || this.state.files[0] !== null)
            return <FontAwesomeIcon style={{fontSize: 16, marginRight: 10}}
                                    icon={faMinusCircle} onClick={() => this.removeFileCertificateUploader(index)}/>
    }

    renderAddFileCertificateButton() {
        if (this.state.files.length < 20) {
            return (
                <FontAwesomeIcon icon={faPlusCircle}
                                 style={{fontSize: 16, marginBottom: 40}}
                                 onClick={this.addFileCertificateUploader}/>
            )
        } else {
            return (
                <p style={{fontSize: 16, marginBottom: 40}}>Sie haben die maximale Anzahl Dokumente erreicht.</p>
            )
        }
    }

    addFileCertificate(file, index) {
        let files = this.state.files;
        if (files.length <= 20) {
            files[index] = file;
            this.setState({files: files});
        }
    }

    removeFileCertificateUploader(index) {
        let files = this.state.files;
        if (files.length === 1) {
            files[0] = null;
        } else {
            files.splice(index, 1);
        }
        this.setState({files: files});
    }

    addFileCertificateUploader() {
        let files = this.state.files;
        files.push(null);
        this.setState({files: files});
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: 40}}>
                    Bitte laden Sie hier ihre Dokumente hoch. Diese dienen teilweise als Zeugnis für die Angaben
                    in der Selbstdeklaration.
                </div>
                <h5 style={{marginBottom: 10, fontWeight: 'bold'}}>Dokumente</h5>
                <Form>
                    <Form.Group>
                        {this.renderFileUploaderCertificate()}
                    </Form.Group>
                </Form>
                {this.renderAddFileCertificateButton()}
                <div>
                    <Button style={{marginRight: 20}} onClick={() => this.props.setStep('declaration')}
                            className={'button'}>
                        Zurück
                    </Button>
                    <Button className={'button'} onClick={this.goNext}>Weiter</Button>
                </div>
            </div>
        );
    }

    removeNullFromFiles() {
        if (this.state.files === 1 && this.state.files[0] === null)
            return null;

        let filteredList = [];
        for (let file of this.state.files) {
            if (file !== null)
                filteredList.push(file);
        }
        return filteredList;
    }

    goNext() {
        let files = this.removeNullFromFiles();
        this.props.setFiles(files);
        this.props.setStep('meetingTime');
    }
}

export default ClientRegistrationDeclarationFiles;
