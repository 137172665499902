/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class Language extends AbstractEnum {
    /**
     * @type{Map<String,Language>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{Language[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        Language._lookupMap.set(id, this);
        Language._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'Language';
    }

    /**
     * @param {string} id
     * @returns {Language|undefined}
     * @public
     */
    static valueOf(id) {
        return Language._lookupMap.get(id);
    }

    /**
     * @returns {Language[]}
     * @public
     */
    static values() {
        return [...Language._values];
    }

    /**
     * @type{Language}
     * @public
     */
    static GERMAN = new Language('language-de');
    /**
     * @type{Language}
     * @public
     */
    static ENGLISH = new Language('language-en');
    /**
     * @type{Language}
     * @public
     */
    static FRENCH = new Language('language-fr');
    /**
     * @type{Language}
     * @public
     */
    static ITALIAN = new Language('language-it');
    /**
     * @type{Language}
     * @public
     */
    static ALBANIAN = new Language('language-sq');
    /**
     * @type{Language}
     * @public
     */
    static BELARUSIAN = new Language('language-be');
    /**
     * @type{Language}
     * @public
     */
    static BOSNIAN = new Language('language-bs');
    /**
     * @type{Language}
     * @public
     */
    static BULGARIAN = new Language('language-bg');
    /**
     * @type{Language}
     * @public
     */
    static CROATIAN = new Language('language-hr');
    /**
     * @type{Language}
     * @public
     */
    static CZECH = new Language('language-cs');
    /**
     * @type{Language}
     * @public
     */
    static DANISH = new Language('language-da');
    /**
     * @type{Language}
     * @public
     */
    static DUTCH = new Language('language-nl');
    /**
     * @type{Language}
     * @public
     */
    static ESTONIAN = new Language('language-et');
    /**
     * @type{Language}
     * @public
     */
    static FINNISH = new Language('language-fi');
    /**
     * @type{Language}
     * @public
     */
    static GREEK = new Language('language-el');
    /**
     * @type{Language}
     * @public
     */
    static HUNGARIAN = new Language('language-hu');
    /**
     * @type{Language}
     * @public
     */
    static ICELANDIC = new Language('language-is');
    /**
     * @type{Language}
     * @public
     */
    static LATVIAN = new Language('language-lv');
    /**
     * @type{Language}
     * @public
     */
    static LITHUANIAN = new Language('language-lt');
    /**
     * @type{Language}
     * @public
     */
    static LUXEMBOURGISH = new Language('language-lb');
    /**
     * @type{Language}
     * @public
     */
    static MALTESE = new Language('language-mt');
    /**
     * @type{Language}
     * @public
     */
    static MONTENEGRIN = new Language('language-cnr');
    /**
     * @type{Language}
     * @public
     */
    static MACEDONIAN = new Language('language-mk');
    /**
     * @type{Language}
     * @public
     */
    static NORWEGIAN = new Language('language-no');
    /**
     * @type{Language}
     * @public
     */
    static POLISH = new Language('language-pl');
    /**
     * @type{Language}
     * @public
     */
    static PORTUGUESE = new Language('language-pt');
    /**
     * @type{Language}
     * @public
     */
    static ROMANIAN = new Language('language-ro');
    /**
     * @type{Language}
     * @public
     */
    static RUSSIAN = new Language('language-ru');
    /**
     * @type{Language}
     * @public
     */
    static SWEDISH = new Language('language-sv');
    /**
     * @type{Language}
     * @public
     */
    static SERBIAN = new Language('language-sr');
    /**
     * @type{Language}
     * @public
     */
    static SLOVAK = new Language('language-sk');
    /**
     * @type{Language}
     * @public
     */
    static SLOVENIAN = new Language('language-sl');
    /**
     * @type{Language}
     * @public
     */
    static SPANISH = new Language('language-es');
    /**
     * @type{Language}
     * @public
     */
    static TURKISH = new Language('language-tr');
    /**
     * @type{Language}
     * @public
     */
    static UKRAINIAN = new Language('language-uk');
}