import AbstractModel from "./AbstractModel";

export default class CaregiverDeclarationAnswer extends AbstractModel {

    constructor(id, question, questionId, answers) {
        super();

        this._id = id;
        this._question = question;
        this._questionId = questionId;
        this._answers = answers;
    }

    get id() {
        return this._id;
    }

    get question() {
        return this._question;
    }

    set question(value) {
        this._question = value;
    }

    get questionId() {
        return this._questionId;
    }

    set questionId(value) {
        this._questionId = value;
    }


    get answers() {
        return this._answers;
    }

    set answers(value) {
        this._answers = value;
    }

    toJSON() {
        let answers = [];
        if (this._answers) {
            for (let answer of this._answers) {
                answers.push(answer.toJSON());
            }
        }

        return {
            id: this._id,
            question: this._question ? this._question.toJSON() : "",
            questionId: this._questionId ? this.questionId : this._question.id,
            answers: answers,
        }
    }

    /**
     * @returns {CaregiverDeclarationAnswer}
     */
    clone() {
        return this._clone(new CaregiverDeclarationAnswer());
    }
}
