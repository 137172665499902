import React from "react";
import {Button} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import '../../App.css';

class AssessmentFinish extends React.Component {

    componentDidMount() {
        this.props.setValidate(null);
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: 40}}>
                    Vielen Dank für die selbstständige und wahrheitsgetreue Bearbeitung der Inhalte.
                    Sie erhalten von uns in den nächsten Tagen eine Auswertung mit einer Einschätzung
                    und Empfehlung zu Ihren Kompetenzen.
                </div>
                <Button className={'button'} onClick={() => this.props.setStep('meetingTime')}>Weiter</Button>
            </div>
        )
    }
}

export default withRouter(AssessmentFinish);
