import Caregiver from "../models/Caregiver";
import Client from "../models/Client";
import ScoreSuperCategory from "../models/ScoreSuperCategory";
import ScoreCategory from "../models/ScoreCategory";
import CaregiverDeclarationQuestion from "../models/CaregiverDeclarationQuestion";
import QuestionChoice from "../models/QuestionChoice";
import Questionnaire from "../models/Questionnaire";
import UserInfo from "../models/UserInfo";
import ClientDeclarationQuestion from "../models/ClientDeclarationQuestion";
import ClientDeclarationAnswer from "../models/ClientDeclarationAnswer";
import Local from "../models/Local";
import ClientScore from "../models/ClientScore";
import CaregiverDeclarationAnswer from "../models/CaregiverDeclarationAnswer";
import AnswerChoice from "../models/AnswerChoice";
import CaregiverAssessmentAnswer from "../models/CaregiverAssessmentAnswer";
import PartnerCompany from "../models/PartnerCompany";
import Meeting from "../models/Meeting";
import ClientObserver from "../models/ClientObserver";
import LanguageSkill from "../models/LanguageSkill";
import ValidationResult from "../models/ValidationResult";
import ValidationResultItem from "../models/ValidationResultItem";
import UserInfoChild from "../models/UserInfoChild";
import UserInfoDetail from "../models/UserInfoDetail";
import {mapWhenNotNullOrUndefined} from "./helpers";
import Availability from "../models/Availability";
import UploadedDocument from "../models/UploadedDocument";

export const jsonToClient = (clientJson) => {
    let declratationQuestions = [];
    if (clientJson.clientProfiles) {
        for (let declarationAnswer of clientJson.clientProfiles) {
            jsonToClientDeclarationAnswer(declarationAnswer);
        }
    }

    let clientScores = [];
    if (clientJson.clientScores) {
        for (let clientScore of clientJson.clientScores) {
            clientScores.push(jsonToClientScore(clientScore))
        }
    }

    return new Client(
        clientJson.id,
        jsonToUserInfo(clientJson.userInfo),
        declratationQuestions,
        clientScores,
        clientJson.languages
    );
};

export const jsonToClientObserver = (clientObserverJson) => {
    let clients = [];
    for (let clientJson of clientObserverJson.clients) {
        clients.push(jsonToClient(clientJson));
    }

    return new ClientObserver(
        clientObserverJson.id,
        jsonToUserInfo(clientObserverJson.userInfo),
        clients,
        null,
        clientObserverJson.languages
    )
};

export const jsonToCaregiver = (caregiverJson) => {
    let declratationQuestions = [];
    if (caregiverJson.caregiverProfiles) {
        for (let declarationAnswer of caregiverJson.caregiverProfiles) {
            declratationQuestions.push(jsonToCaregiverDeclarationAnswer(declarationAnswer));
        }
    }

    let clientScores = [];
    if (caregiverJson.caregiverScores) {
        for (let clientScore of caregiverJson.caregiverScores) {
            clientScores.push(jsonToCaregiverScore(clientScore))
        }
    }

    let selfAssessments = [];
    if (caregiverJson.caregiverSelfAssessments) {
        for (let selfAssessment of caregiverJson.caregiverSelfAssessments) {
            selfAssessment.push(jsonToCaregiverAssessmentQuestion(selfAssessment));
        }
    }

    const languageSkills = (caregiverJson.languageSkills ?? []).map(jsonToLanguageSkill);

    return new Caregiver(
        caregiverJson.id,
        jsonToUserInfo(caregiverJson.userInfo),
        mapWhenNotNullOrUndefined(caregiverJson.partnerCompany, jsonToPartnerCompany),
        caregiverJson.partnerCompanyId,
        caregiverJson.registrationCompleted,
        declratationQuestions,
        selfAssessments,
        clientScores,
        languageSkills,
        mapWhenNotNullOrUndefined(caregiverJson.availability, jsonToAvailability)
    );
};

export const jsonToAvailability = (availabilityJson) => {
    const workLoads = new Set(availabilityJson.workloads ?? []);
    const workSlots = new Set(availabilityJson.workslots ?? []);

    return new Availability(
        availabilityJson.id,
        workLoads,
        workSlots
    )
};


export const jsonToUserInfo = (userInfoJson) => {
    return new UserInfo(
        userInfoJson.id,
        userInfoJson.userId,
        userInfoJson.lastName,
        userInfoJson.firstName,
        userInfoJson.email,
        userInfoJson.streetAndNr,
        userInfoJson.zipcode,
        userInfoJson.city,
        userInfoJson.country,
        userInfoJson.gender,
        userInfoJson.birthDate, // TODO: init as date object - care about side effects in registration form
        userInfoJson.phoneNumber,
        userInfoJson.mobileNumber,
        userInfoJson.nationality,
        userInfoJson.verified,
        userInfoJson.role,
        userInfoJson.relatedEntityId,
        userInfoJson.details ? jsonToUserInfoDetail(userInfoJson.details) : null
    )
};

export const jsonToUserInfoDetail = userInfoDetailJson => {
    const children = (userInfoDetailJson.children ?? []).map(jsonToUserInfoChild);
    return new UserInfoDetail(
        userInfoDetailJson.id,
        children,
        userInfoDetailJson.residencePermit,
        userInfoDetailJson.civilStatus,
        userInfoDetailJson.withholdingTax,
        userInfoDetailJson.criminalRecord,
        userInfoDetailJson.otherEmployers,
        userInfoDetailJson.additionalIncomeUnemploymentInsurance,
        userInfoDetailJson.disabilityAndSocialInsuranceDeductions,
        userInfoDetailJson.socialSecurityNumber,
        userInfoDetailJson.bankAccountNumber,
        mapWhenNotNullOrUndefined(userInfoDetailJson.employmentStartDate, startDate => new Date(startDate)),
        mapWhenNotNullOrUndefined(userInfoDetailJson.employmentEndDate, endDate => new Date(endDate)),
        userInfoDetailJson.notice
    );
}

export const jsonToUserInfoChild = userInfoChildJson => new UserInfoChild(
    userInfoChildJson.id,
    userInfoChildJson.firstName,
    userInfoChildJson.lastName,
    mapWhenNotNullOrUndefined(userInfoChildJson.birthDate, birthDate => new Date(birthDate))
);

//CAREGIVER

export const jsonToCaregiverDeclarationQuestion = (caregiverDeclarationQuestionJson) => {
    let questionChoices = [];
    for (let questionChoiceJson of caregiverDeclarationQuestionJson.choices) {
        questionChoices.push(jsonToQuestionChoice(questionChoiceJson));
    }

    let questionLocals = [];
    for (let questionLocal of caregiverDeclarationQuestionJson.question) {
        questionLocals.push(jsonToLocal(questionLocal))
    }

    return new CaregiverDeclarationQuestion(
        caregiverDeclarationQuestionJson.id,
        jsonToScoreCategory(caregiverDeclarationQuestionJson.scoreCategory),
        questionLocals,
        caregiverDeclarationQuestionJson.answerType,
        questionChoices
    )
};

export const jsonToCaregiverDeclarationAnswer = (caregiverDeclarationAnswer) => {
    let question = caregiverDeclarationAnswer.question ?
        jsonToCaregiverDeclarationQuestion(caregiverDeclarationAnswer.question)
        : null;

    let answers = [];
    if (caregiverDeclarationAnswer.answers) {
        for (let answer of caregiverDeclarationAnswer.answers) {
            answers.push(jsonToAnswerChoice(answer));
        }
    }

    return new CaregiverDeclarationAnswer(
        caregiverDeclarationAnswer.id,
        question,
        caregiverDeclarationAnswer.questionId,
        answers)
};

export const jsonToCaregiverAssessmentQuestion = (caregiverAssessmentQuestionJson) => {
    let questionChoices = [];
    for (let questionChoiceJson of caregiverAssessmentQuestionJson.choices) {
        questionChoices.push(jsonToQuestionChoice(questionChoiceJson));
    }

    let questionLocals = [];
    for (let questionLocal of caregiverAssessmentQuestionJson.question) {
        questionLocals.push(jsonToLocal(questionLocal))
    }

    return new ClientDeclarationQuestion(
        caregiverAssessmentQuestionJson.id,
        jsonToScoreCategory(caregiverAssessmentQuestionJson.scoreCategory),
        questionLocals,
        caregiverAssessmentQuestionJson.answerType,
        questionChoices
    )
};

export const jsonToCaregiverAssessmentAnswer = (caregiverAssessmentAnswer) => {
    let question = caregiverAssessmentAnswer.question ?
        jsonToCaregiverDeclarationQuestion(caregiverAssessmentAnswer.question)
        : null;

    let answers = [];
    if (caregiverAssessmentAnswer.answers) {
        for (let answer of caregiverAssessmentAnswer.answers) {
            answers.push(jsonToAnswerChoice(answer));
        }
    }

    return new CaregiverAssessmentAnswer(
        caregiverAssessmentAnswer.id,
        question,
        caregiverAssessmentAnswer.questionId,
        answers)
};

export const jsonToCaregiverScore = (caregiverScore) => {
    return new ClientScore(
        caregiverScore.id,
        jsonToScoreSuperCategory(caregiverScore.superCategory),
        caregiverScore.description
    );
};

/**
 * @param {{}} documentJson
 * @returns {UploadedDocument}
 */
export const jsonToUploadedDocument = (documentJson) => {
    return new UploadedDocument(
        documentJson.id,
        documentJson.fileName,
        mapWhenNotNullOrUndefined(documentJson.createdAt, v => new Date(v)),
        mapWhenNotNullOrUndefined(documentJson.lastUpdatedAt, v => new Date(v)),
        documentJson.type
    );
};

//CLIENT

export const jsonToClientDeclarationQuestion = (clientDeclarationQuestionJson) => {
    let questionChoices = [];
    for (let questionChoiceJson of clientDeclarationQuestionJson.choices) {
        questionChoices.push(jsonToQuestionChoice(questionChoiceJson));
    }

    let questionLocals = [];
    for (let questionLocal of clientDeclarationQuestionJson.question) {
        questionLocals.push(jsonToLocal(questionLocal))
    }

    return new ClientDeclarationQuestion(
        clientDeclarationQuestionJson.id,
        jsonToScoreCategory(clientDeclarationQuestionJson.scoreCategory),
        questionLocals,
        clientDeclarationQuestionJson.answerType,
        questionChoices
    )
};

export const jsonToClientDeclarationAnswer = (clientDeclarationAnswer) => {
    let question = clientDeclarationAnswer.question ?
        jsonToClientDeclarationQuestion(clientDeclarationAnswer.question)
        : null;

    let answers = [];
    if (clientDeclarationAnswer.answers) {
        for (let answer of clientDeclarationAnswer.answers) {
            answers.push(jsonToAnswerChoice(answer));
        }
    }

    return new ClientDeclarationAnswer(
        clientDeclarationAnswer.id,
        question,
        clientDeclarationAnswer.questionId,
        answers)
};

export const jsonToClientScore = (clientScore) => {
    return new ClientScore(
        clientScore.id,
        jsonToScoreSuperCategory(clientScore.superCategory),
        clientScore.description
    );
};

export const jsonToQuestionnaire = (quetionnaireJson) => {
    let caregiverAssessmentQuestions = [];
    for (let caregiverAssessmentQuestion of quetionnaireJson.caregiverAssessmentQuestion)
        caregiverAssessmentQuestions.push(jsonToCaregiverAssessmentQuestion(caregiverAssessmentQuestion));
    return new Questionnaire(quetionnaireJson.id, quetionnaireJson.description, quetionnaireJson.creationDate,
        quetionnaireJson.lastUpdated, caregiverAssessmentQuestions);
};

export const jsonToQuestionChoice = (questionChoiceJson) => {
    let texts = [];
    for (let text of questionChoiceJson.text) {
        texts.push(jsonToLocal(text));
    }
    return new QuestionChoice(questionChoiceJson.id, texts, questionChoiceJson.viewOrder);
};

export const jsonToScoreCategory = (scoreCategoryJson) => {
    let descriptions = [];
    for (let description of scoreCategoryJson.description) {
        descriptions.push(jsonToLocal(description));
    }
    return new ScoreCategory(scoreCategoryJson.id, descriptions,
        jsonToScoreSuperCategory(scoreCategoryJson.superCategory));
};

export const jsonToScoreSuperCategory = (scoreSuperCategoryJson) => {
    let descriptions = [];
    for (let description of scoreSuperCategoryJson.description) {
        descriptions.push(jsonToLocal(description));
    }
    return new ScoreSuperCategory(scoreSuperCategoryJson.id, descriptions);
};

export const jsonToPartnerCompany = (partnerCompanyJson) => {
    return new PartnerCompany(partnerCompanyJson.id, partnerCompanyJson.name, partnerCompanyJson.streetAndNr,
        partnerCompanyJson.zipcode, partnerCompanyJson.city, partnerCompanyJson.country, partnerCompanyJson.phoneNumber);
};

export const jsonToMeeting = (meetingJson) => {
    return new Meeting(meetingJson.id, meetingJson.date, meetingJson.creationDate, meetingJson.status,
        meetingJson.userInfo, meetingJson.userInfo.id);
};

export const jsonToAnswerChoice = (answerChoiceJson) => {
    return new AnswerChoice(answerChoiceJson.id, jsonToQuestionChoice(answerChoiceJson.choice), answerChoiceJson.priorityQuestionOrder);
};

export const jsonToLocal = (localJson) => {
    return new Local(localJson.id, localJson.text, localJson.localization);
};

export const jsonToLanguageSkill = languageSkillJson => {
    return new LanguageSkill(
        languageSkillJson.id,
        languageSkillJson.language,
        languageSkillJson.level,
        languageSkillJson.certified);
};

// Validation result

export const jsonToValidationResult = validationResultJson => {
    const message = validationResultJson.message;
    const items = (validationResultJson.items ?? []).map(jsonToValidationResultItem);

    return new ValidationResult(message, items)
}

export const jsonToValidationResultItem = validationResultItemJson => new ValidationResultItem(
    validationResultItemJson.property,
    validationResultItemJson.invalidValue,
    validationResultItemJson.message
);