import AbstractModel from "./AbstractModel";

export default class Password extends AbstractModel {
    /**
     * @type {string}
     * @private
     */
    _password = undefined;

    /**
     * @param {string} password
     */
    constructor(password) {
        super();

        this._password = password;
    }

    /**
     * @returns {string}
     */
    get password() {
        return this._password;
    }

    /**
     * @param {string} value
     */
    set password(value) {
        this._password = value;
    }

    toJSON() {
        return {
            password: this._password,
        }
    }

    /**
     * @returns {Password}
     */
    clone() {
        return this._clone(new Password());
    }
}
