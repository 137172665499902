import React from "react";
import keycloak from "../../keycloak";
import ApiService from "../../services/ApiService";
import PropTypes from 'prop-types';
import {FieldArray} from 'formik';
import LanguageSkill from "../../models/LanguageSkill";
import Form from "../form/Form";
import Section from "../form/Section";
import DropdownField from "../form/DropdownField";
import FieldSet from "../form/Fieldset";
import Caregiver from "../../models/Caregiver";
import Language from "../../enums/Language";
import LanguageLevel from "../../enums/LanguageLevel";
import Button from "../form/Button";
import Submit from "../form/Submit";
import Success from "../form/Success";
import Failed from "../form/Failed";

/** @typedef {import('formik').ArrayHelpers} ArrayHelpers */
/** @typedef {import('i18next').TFunction} TFunction */

export default class DashboardLanguages extends React.Component {
    state = {
        caregiver: null,
    };

    /**
     * @returns {number}
     * @private
     */
    get _userId() {
        return this.props.userId;
    }

    /**
     * @returns {Caregiver|null}
     * @private
     */
    get _caregiver() {
        return this.state.caregiver;
    }

    /**
     * @returns {Caregiver}
     * @private
     */
    get _initialValues() {
        const caregiver = this._caregiver?.clone() ?? new Caregiver();

        caregiver.languageSkills = caregiver.languageSkills ?? [];

        return caregiver.toJSON();
    }

    /**
     * @param {Caregiver} caregiver
     * @private
     */
    _storeCaregiver = (caregiver) => {
        this.setState({
            caregiver: caregiver
        });
    }

    /**
     * @returns {Promise<void>}
     * @private
     */
    _onLoad = () => {
        return ApiService.getCaregiver(this._userId, keycloak).then(this._storeCaregiver);
    }

    /**
     * @param {FormikValues} values
     * @private
     */
    _onSubmit = (values) => {
        return ApiService.updateCaregiver(values, keycloak)
            .then(this._storeCaregiver);
    }

    /**
     * @param {ArrayHelpers} arrayHelpers
     * @param {LanguageSkill} languageSkill
     * @param {TFunction} t
     * @param {number} index
     * @private
     */
    _renderLanguageSkill = (arrayHelpers, languageSkill, t, index) => (
        <FieldSet key={index}>
            <DropdownField label={t('language')}
                           name={`languageSkills[${index}].language`}
                           required={true}
                           enum={Language}/>
            <DropdownField label={t('level')}
                           name={`languageSkills[${index}].level`}
                           required={true}
                           enum={LanguageLevel}/>
            <Button onClick={() => arrayHelpers.remove(index)}>{t('remove')}</Button>
        </FieldSet>
    )

    render = () => (
        <Form initialValues={this._initialValues} onLoad={this._onLoad} onSubmit={this._onSubmit}>
            {({t, formik}) => (
                <>
                    <Section>{t('myProfile')} - {t('languageSkills')}</Section>
                    <FieldSet>
                        <FieldArray name="languageSkills">
                            {arrayHelpers => (
                                <>
                                    {formik.values.languageSkills.map((child, index) => this._renderLanguageSkill(arrayHelpers, child, t, index))}
                                    <Button onClick={() => arrayHelpers.push(new LanguageSkill().toJSON())}>
                                        {t('addLanguageSkill')}
                                    </Button>
                                </>
                            )}
                        </FieldArray>
                    </FieldSet>
                    <Submit>{t('confirm')}</Submit>
                    <Success/>
                    <Failed/>
                </>
            )}
        </Form>
    )
}

DashboardLanguages.propTypes = {
    userId: PropTypes.number.isRequired,
    isClient: PropTypes.bool.isRequired
};
