import React from 'react';
import PropTypes from 'prop-types';
import {v5 as uuid} from 'uuid';
import AbstractInputField from './AbstractInputField';

export default class FileInputField extends AbstractInputField {
    /**
     * @type {string}
     */
    static LABEL_NAMESPACE_UUID = '2a9ffb02-0a36-480c-9533-7fabdfc4e0b8';

    get type() {
        return 'file';
    }

    /**
     * @returns {string}
     */
    get accept() {
        return this.props.accept ?? '';
    }

    /**
     * @returns {string}
     */
    get uploadLabel() {
        return this.props.uploadLabel ?? this.t('pleaseSelectDocumentToUpload');
    }

    /**
     * @returns {string}
     * @private
     */
    get _labelId() {
        return uuid(this.id, FileInputField.LABEL_NAMESPACE_UUID);
    }

    renderField({field, form, meta}) {
        /**
         * @type {File|null}
         */
        const value = field.value;

        /**
         * @param {HTMLInputElement} element
         */
        const fileInputRef = element => {
            const file = element?.files?.item(0);

            if (!!file && file !== value && value !== undefined) {
                form.setFieldValue(field.name, file);
            }

            if (element && value === undefined) {
                form.setFieldValue(field.name, null);
                element.value = null;
            }
        };

        return (
            <div className="position-relative overflow-hidden">
                <label htmlFor={this._labelId} className="m-0 w-100 px-1">
                    {value?.name ?? this.uploadLabel}
                </label>
                <input aria-labelledby={this._labelId}
                       id={this.id}
                       style={{
                           opacity: 0,
                           inset: 0,
                           position: 'absolute',
                           zIndex: 1000,
                           cursor: 'pointer',
                           paddingTop: '24px',
                           border: 0
                       }}
                       type={this.type}
                       name={field.name}
                       accept={this.accept}
                       onChange={form.handleChange}
                       ref={fileInputRef}
                       onBlur={form.handleBlur}/>
            </div>
        );
    }
}

FileInputField.propTypes = {
    ...AbstractInputField.propTypes,
    accept: PropTypes.string,
    uploadLabel: PropTypes.string
};