import React from "react";
import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

class DateField extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.value || this.props.value === "") {
            let date = new Date("1990-01-01");
            props.setAnswer(props.question.id, this.formatDate(date));
        }
    }

    render() {
        return (
            <Form.Group>
                <Form.Label
                    style={{fontWeight: 'bold'}}>{this.props.question.question[0].text}{this.renderRequired()}</Form.Label>
                <div>
                    <DatePicker selected={this.props.value !== "" ? new Date(this.props.value) : new Date("1990-01-01")}
                                onChange={date => this.props.setAnswer(this.props.question.id, this.formatDate(date))}
                                dateFormat="dd.MM.yyyy"
                                placeholderText='Wählen Sie ein Datum'/>
                </div>
            </Form.Group>
        );
    }

    formatDate(date) {
        let month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    renderRequired() {
        if (this.props.required) return '*';
        return '';
    }
}

export default DateField;
