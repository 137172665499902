import React from 'react';
import PropTypes from 'prop-types';
import AbstractForm from './AbstractForm';

/** @typedef {import('formik').FormikProps} FormikProps */

export default class Form extends AbstractForm {
    get initialValues() {
        return this.props.initialValues;
    }

    onValidate(values, errors) {
        const validationFunc = this.props.onValidate ?? ((values, errors) => errors);

        return validationFunc(values, errors);
    }

    onLoad() {
        return Promise.resolve().then(this.props.onLoad);
    }

    onSubmit(values) {
        return Promise.resolve(values).then(this.props.onSubmit);
    }

    onError(error, values, actions) {
        console.error(error);
    }

    renderFormContent(formik) {
        return (
            <>
                {this._renderChildren(formik)}
            </>
        )
    }

    /**
     * @param {FormikProps} formik
     * @returns {[]}
     * @private
     */
    _renderChildren(formik) {
        const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
        const props = {
            t: this.t,
            formik: formik
        };

        return children.map((child, index) => this._renderChild(child, index, props));
    }

    /**
     * @param {JSX.Element|function({}):JSX.Element} child
     * @param {number} index
     * @param {{}} props
     * @returns {JSX.Element}
     * @private
     */
    _renderChild(child, index, props) {
        if (typeof child === 'function') {
            return this._renderChild(child({...props}), index, props);
        } else {
            return React.cloneElement(child, {key: index, ...child.props});
        }
    }
}

Form.propTypes = {
    initialValues: PropTypes.object.isRequired,
    onLoad: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValidate: PropTypes.func
};
