/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class Workload extends AbstractEnum {
    /**
     * @type{Map<String,Workload>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{Workload[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        Workload._lookupMap.set(id, this);
        Workload._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'Workload';
    }

    /**
     * @param {string} id
     * @returns {Workload|undefined}
     * @public
     */
    static valueOf(id) {
        return Workload._lookupMap.get(id);
    }

    /**
     * @returns {Workload[]}
     * @public
     */
    static values() {
        return [...Workload._values];
    }

    /**
     * @type{Workload}
     * @public
     */
    static HALF_DAY = new Workload('halfDay');
    /**
     * @type{Workload}
     * @public
     */
    static HALF_DAY_1 = new Workload('halfDay-1');
    /**
     * @type{Workload}
     * @public
     */
    static HALF_DAY_2 = new Workload('halfDay-2');
    /**
     * @type{Workload}
     * @public
     */
    static HALF_DAY_3 = new Workload('halfDay-3');
    /**
     * @type{Workload}
     * @public
     */
    static HALF_DAY_4 = new Workload('halfDay-4');
    /**
     * @type{Workload}
     * @public
     */
    static HALF_DAY_5 = new Workload('halfDay-5');
    /**
     * @type{Workload}
     * @public
     */
    static HALF_DAY_6 = new Workload('halfDay-6');
    /**
     * @type{Workload}
     * @public
     */
    static HALF_DAY_7 = new Workload('halfDay-7');
    /**
     * @type{Workload}
     * @public
     */
    static FULL_DAY = new Workload('fullDay');
    /**
     * @type{Workload}
     * @public
     */
    static FULL_DAY_1 = new Workload('fullDay-1');
    /**
     * @type{Workload}
     * @public
     */
    static FULL_DAY_2 = new Workload('fullDay-2');
    /**
     * @type{Workload}
     * @public
     */
    static FULL_DAY_3 = new Workload('fullDay-3');
    /**
     * @type{Workload}
     * @public
     */
    static FULL_DAY_4 = new Workload('fullDay-4');
    /**
     * @type{Workload}
     * @public
     */
    static FULL_DAY_5 = new Workload('fullDay-5');
    /**
     * @type{Workload}
     * @public
     */
    static FULL_DAY_6 = new Workload('fullDay-6');
    /**
     * @type{Workload}
     * @public
     */
    static FULL_DAY_7 = new Workload('fullDay-7');
    /**
     * @type{Workload}
     * @public
     */
    static NIGHTS = new Workload('nights');
    /**
     * @type{Workload}
     * @public
     */
    static NIGHTS_1 = new Workload('nights-1');
    /**
     * @type{Workload}
     * @public
     */
    static NIGHTS_2 = new Workload('nights-2');
    /**
     * @type{Workload}
     * @public
     */
    static NIGHTS_3 = new Workload('nights-3');
    /**
     * @type{Workload}
     * @public
     */
    static NIGHTS_4 = new Workload('nights-4');
    /**
     * @type{Workload}
     * @public
     */
    static NIGHTS_5 = new Workload('nights-5');
    /**
     * @type{Workload}
     * @public
     */
    static NIGHTS_6 = new Workload('nights-6');
    /**
     * @type{Workload}
     * @public
     */
    static NIGHTS_7 = new Workload('nights-7');
}