import React from "react";
import {Button} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import '../../App.css';

class CaregiverRegistrationSubmission extends React.Component {
    constructor(props) {
        super(props);
        this.submitData = this.submitData.bind(this);
    }

    componentDidMount() {
        this.props.setValidate(null);
    }

    render() {
        return (
            <div>
                <div style={{fontWeight: 'bold'}}>Vielen Dank für Ihre Anmeldung</div>
                <div>
                    Ihre Angaben wurden erfolgreich erfasst. Sie können sich jederzeit mit der angegebenen
                    Email Adresse und Passwort einloggen.
                </div>
                <div style={{marginTop: 40}}>
                    <Button style={{marginRight: 20}} onClick={() => this.props.setStep('meetingTime')}
                            className={'button'}>
                        Zurück
                    </Button>
                    <Button className={'button'} onClick={this.submitData}>Zum Dashboard</Button>
                </div>
            </div>
        );
    }

    submitData() {
        this.props.submitData().then(() => {
            this.props.history.push('/');
        })
    }
}

export default withRouter(CaregiverRegistrationSubmission);
