/**
 * The code in this file is generated using the JavaScriptEnumGenerator.java
 * No manual changes recommended, change the enum in the backend instead.
 */
import AbstractEnum from './AbstractEnum';

export default class ResidencePermit extends AbstractEnum {
    /**
     * @type{Map<String,ResidencePermit>}
     * @private
     */
    static _lookupMap = new Map();

    /**
     * @type{ResidencePermit[]}
     * @private
     */
    static _values = [];

    /**
     * @hideconstructor
     * @param {string} id
     * @private
     */
    constructor(id) {
        super(id);

        ResidencePermit._lookupMap.set(id, this);
        ResidencePermit._values.push(this);
    }

    /**
     * @returns {string}
     * @protected
     */
    get _name() {
        return 'ResidencePermit';
    }

    /**
     * @param {string} id
     * @returns {ResidencePermit|undefined}
     * @public
     */
    static valueOf(id) {
        return ResidencePermit._lookupMap.get(id);
    }

    /**
     * @returns {ResidencePermit[]}
     * @public
     */
    static values() {
        return [...ResidencePermit._values];
    }

    /**
     * @type{ResidencePermit}
     * @public
     */
    static NONE = new ResidencePermit('none');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static B = new ResidencePermit('b');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static B_EU_EFTA = new ResidencePermit('b-eu-efta');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static C = new ResidencePermit('c');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static C_EU_EFTA = new ResidencePermit('c-eu-efta');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static C_I = new ResidencePermit('ci');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static C_I_EU_EFTA = new ResidencePermit('ci-eu-efta');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static G = new ResidencePermit('g');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static G_EU_EFTA = new ResidencePermit('g-eu-efta');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static L = new ResidencePermit('l');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static F = new ResidencePermit('f');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static N = new ResidencePermit('n');
    /**
     * @type{ResidencePermit}
     * @public
     */
    static S = new ResidencePermit('s');
}