import AbstractModel from "./AbstractModel";

export default class Questionnaire extends AbstractModel {

    constructor(id, description, creationDate, lastUpdated, caregiverAssessmentQuestion) {
        super();

        this._id = id;
        this._description = description;
        this._creationDate = creationDate;
        this._lastUpdated = lastUpdated;
        this._caregiverAssessmentQuestion = caregiverAssessmentQuestion;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get creationDate() {
        return this._creationDate;
    }

    set creationDate(value) {
        this._creationDate = value;
    }

    get lastUpdated() {
        return this._lastUpdated;
    }

    set lastUpdated(value) {
        this._lastUpdated = value;
    }

    get caregiverAssessmentQuestion() {
        return this._caregiverAssessmentQuestion;
    }

    set caregiverAssessmentQuestion(value) {
        this._caregiverAssessmentQuestion = value;
    }

    toJSON() {
        throw new Error('not implemented');
    }

    /**
     * @returns {Questionnaire}
     */
    clone() {
        return this._clone(new Questionnaire());
    }
}
