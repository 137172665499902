import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import QuestionTextField from "../questionFields/QuestionTextField";
import DateField from "../questionFields/DateField";
import CaregiverDeclarationQuestion from "../../models/CaregiverDeclarationQuestion";
import QuestionChoice from "../../models/QuestionChoice";
import DropdownField from "../questionFields/DropdownField";
import '../../App.css';
import {
    convertToAPICountry,
    convertToAPIGender,
    convertToLocalizedCountry,
    convertToLocalizedGender
} from "../../util/FieldConverter";
import {ValidationHandler} from "../../util/ValidationHandler";
import {valNotEmpty, valNumeric} from "../../util/FormValidations";
import {languages} from "../../util/Languages";
import MultiDropdownField from "../inputs/MultiDropdownInput";
import Country from "../../enums/Country";


class ClientRegistrationProfileForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validationHandler: new ValidationHandler(),
            questionAnswerMapCaretakenPerson: {
                firstName: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Vorname"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                lastName: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Nachname"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                streetAndNr: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Strasse"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                city: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Ort"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                zipcode: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "PLZ"}],
                        null, null),
                    answer: "",
                    validator: valNumeric,
                    invalid: false,
                    ref: React.createRef()
                },
                country: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Land"}],
                        null, this.countries),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                nationality: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Nationalität"}],
                        null, this.countries),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                languages: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Sprachen"}],
                        null, languages),
                    answer: ["language-de"],
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                birthDate: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Geburtsdatum"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                phoneNumber: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Telefon"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                mobileNumber: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Telefon Mobil"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                gender: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Anrede"}],
                        null, [
                            new QuestionChoice(1, [{text: "Herr"}]),
                            new QuestionChoice(2, [{text: "Frau"}]),
                            new QuestionChoice(3, [{text: "Andere"}])
                        ]),
                    answer: "male",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                }
            },
            questionAnswerMapContactPerson: {
                firstName: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Vorname"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                lastName: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Nachname"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                streetAndNr: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Strasse"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                city: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Ort"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                zipcode: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "PLZ"}],
                        null, null),
                    answer: "",
                    validator: valNumeric,
                    invalid: false,
                    ref: React.createRef()
                },
                country: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Land"}],
                        null, this.countries),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                nationality: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Nationalität"}],
                        null, this.countries),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                languages: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Sprachen"}],
                        null, languages),
                    answer: ["language-de"],
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                birthDate: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Geburtsdatum"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                phoneNumber: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Telefon"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                mobileNumber: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Telefon Mobil"}],
                        null, null),
                    answer: "",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                },
                gender: {
                    question: new CaregiverDeclarationQuestion(null, null, [{text: "Anrede"}],
                        null, [
                            new QuestionChoice(1, [{text: "Herr"}]),
                            new QuestionChoice(2, [{text: "Frau"}]),
                            new QuestionChoice(3, [{text: "Andere"}])
                        ]),
                    answer: "male",
                    validator: valNotEmpty,
                    invalid: false,
                    ref: React.createRef()
                }
            },
        };
        this.setAnswerCaretakenPerson = this.setAnswerCaretakenPerson.bind(this);
        this.validate = this.validate.bind(this);
        this.goNext = this.goNext.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        this.props.setValidate(this.validate);
        if (this.props.profileQuestionAnswerMap !== null) {
            this.setState({questionAnswerMapCaretakenPerson: this.props.profileQuestionAnswerMap});
        }
        if (this.props.profileQuestionAnswerMapContactPerson !== null) {
            this.setState({questionAnswerMapContactPerson: this.props.profileQuestionAnswerMapContactPerson});
        }
    }

    setAnswerCaretakenPerson(question, answer) {
        let questionAnswerMapCaretakenPerson = this.state.questionAnswerMapCaretakenPerson;
        questionAnswerMapCaretakenPerson[question].answer = answer;
        this.setState({questionAnswerMapCaretakenPerson: questionAnswerMapCaretakenPerson})
    }

    setAnswerContactPerson(question, answer) {
        let questionAnswerMapContactPerson = this.state.questionAnswerMapContactPerson;
        questionAnswerMapContactPerson[question].answer = answer;
        this.setState({questionAnswerMapContactPerson: questionAnswerMapContactPerson})
    }

    validate() {
        let focusTo = null;
        let allValid = true;
        let questionAnswerMapCaretakenPerson = this.state.questionAnswerMapCaretakenPerson;
        for (let questionAnswerKey in questionAnswerMapCaretakenPerson) {
            let questionAnswer = questionAnswerMapCaretakenPerson[questionAnswerKey];
            let valid = questionAnswer.validator(questionAnswer.answer);
            questionAnswer.invalid = !valid;
            if (allValid && !valid) {
                focusTo = questionAnswer.ref;
                allValid = false;
            }
        }

        let questionAnswerMapContactPerson = this.state.questionAnswerMapContactPerson;
        if (this.props.registeringOther) {
            for (let questionAnswerKey in questionAnswerMapContactPerson) {
                let questionAnswer = questionAnswerMapContactPerson[questionAnswerKey];
                let valid = questionAnswer.validator(questionAnswer.answer);
                questionAnswer.invalid = !valid;
                if (allValid && !valid) {
                    focusTo = questionAnswer.ref;
                    allValid = false;
                }
            }
        }

        if (allValid) {
            return true;
        } else {
            focusTo.current.scrollIntoView();
            this.setState({
                questionAnswerMapCaretakenPerson: questionAnswerMapCaretakenPerson,
                questionAnswerMapContactPerson: questionAnswerMapContactPerson
            });
            return false;
        }
    }

    goNext() {
        this.props.setProfileData(this.state.questionAnswerMapCaretakenPerson);
        this.props.setProfileContactPersonData(this.state.questionAnswerMapContactPerson);
        this.props.setStep("declarationIntro");
    }

    goBack() {
        this.props.setStep("start");
    }

    renderCaregiverRegistrationProfileForm() {
        return (
            <Form>
                {this.renderRegistrationOptionForm()}
                {this.renderQuestionsLoginPerson()}
                {this.renderQuestionsCaretakenPerson()}
                <div style={{marginTop: 40}}>
                    <Button style={{marginRight: 20}} onClick={this.goBack} className={'button'}>
                        Zurück
                    </Button>
                    <Button className={'button'} onClick={() => {
                        if (this.validate()) {
                            this.goNext();
                        }
                    }}>
                        Weiter
                    </Button>
                </div>
            </Form>
        )
    }

    renderRegistrationOptionForm() {
        return (
            <div style={{marginBottom: 40}}>
                <h5 style={{marginBottom: 5}}>Für welche Person suchen Sie Betreuungsleistungen?</h5>
                <div onClick={() => this.props.setRegisteringOther(false)}
                     style={{padding: '0px 5px'}}>
                    <Form.Check id="0"
                                type="radio"
                                checked={!this.props.registeringOther}
                                label="Ich suche Betreuungsleistungen für mich."
                                required={this.props.required}
                    />
                </div>
                <div onClick={() => this.props.setRegisteringOther(true)}
                     style={{padding: '0px 5px'}}>
                    <Form.Check id="1"
                                type="radio"
                                checked={this.props.registeringOther}
                                label="Ich suche Betreuungsleistungen für eine nahestehende Person."
                                required={this.props.required}
                    />
                </div>
            </div>
        )
    }

    renderQuestionsLoginPerson() {
        let renderedQuestions = [];

        renderedQuestions.push(
            <DropdownField
                name={"gender"}
                setAnswer={(question, answer) => this.setAnswerCaretakenPerson("gender", convertToAPIGender(answer))}
                question={this.state.questionAnswerMapCaretakenPerson["gender"].question}
                value={convertToLocalizedGender(this.state.questionAnswerMapCaretakenPerson["gender"].answer)}
                invalid={this.state.questionAnswerMapCaretakenPerson["gender"].invalid}
                ref={this.state.questionAnswerMapCaretakenPerson["gender"].ref}
                required
            />
        );
        renderedQuestions.push(
            <QuestionTextField
                name={"firstName"}
                setAnswer={(question, answer) => this.setAnswerCaretakenPerson("firstName", answer)}
                question={this.state.questionAnswerMapCaretakenPerson["firstName"].question}
                value={this.state.questionAnswerMapCaretakenPerson["firstName"].answer}
                invalid={this.state.questionAnswerMapCaretakenPerson["firstName"].invalid}
                ref={this.state.questionAnswerMapCaretakenPerson["firstName"].ref}
                required
            />
        );
        renderedQuestions.push(
            <QuestionTextField name={"lastName"}
                               setAnswer={(question, answer) => this.setAnswerCaretakenPerson("lastName", answer)}
                               question={this.state.questionAnswerMapCaretakenPerson["lastName"].question}
                               value={this.state.questionAnswerMapCaretakenPerson["lastName"].answer}
                               invalid={this.state.questionAnswerMapCaretakenPerson["lastName"].invalid}
                               ref={this.state.questionAnswerMapCaretakenPerson["lastName"].ref}
                               required
            />
        );
        renderedQuestions.push(
            <QuestionTextField name={"streetAndNr"}
                               setAnswer={(quesiton, answer) => this.setAnswerCaretakenPerson("streetAndNr", answer)}
                               question={this.state.questionAnswerMapCaretakenPerson["streetAndNr"].question}
                               value={this.state.questionAnswerMapCaretakenPerson["streetAndNr"].answer}
                               invalid={this.state.questionAnswerMapCaretakenPerson["streetAndNr"].invalid}
                               ref={this.state.questionAnswerMapCaretakenPerson["streetAndNr"].ref}
                               required
            />
        );
        renderedQuestions.push(
            <Row>
                <Col xs={3}>
                    <QuestionTextField name={"zipcode"}
                                       setAnswer={(quesiton, answer) => this.setAnswerCaretakenPerson("zipcode", answer)}
                                       question={this.state.questionAnswerMapCaretakenPerson["zipcode"].question}
                                       value={this.state.questionAnswerMapCaretakenPerson["zipcode"].answer}
                                       invalid={this.state.questionAnswerMapCaretakenPerson["zipcode"].invalid}
                                       ref={this.state.questionAnswerMapCaretakenPerson["zipcode"].ref}
                                       required
                    />
                </Col>
                <Col xs={5}>
                    <QuestionTextField name={"city"}
                                       setAnswer={(quesiton, answer) => this.setAnswerCaretakenPerson("city", answer)}
                                       question={this.state.questionAnswerMapCaretakenPerson["city"].question}
                                       value={this.state.questionAnswerMapCaretakenPerson["city"].answer}
                                       invalid={this.state.questionAnswerMapCaretakenPerson["city"].invalid}
                                       ref={this.state.questionAnswerMapCaretakenPerson["city"].ref}
                                       required
                    />
                </Col>
            </Row>);
        renderedQuestions.push(
            <DropdownField
                name={"country"}
                setAnswer={(quesiton, answer) => this.setAnswerCaretakenPerson("country", convertToAPICountry(answer))}
                question={this.state.questionAnswerMapCaretakenPerson["country"].question}
                value={convertToLocalizedCountry(this.state.questionAnswerMapCaretakenPerson["country"].answer)}
                invalid={this.state.questionAnswerMapCaretakenPerson["country"].invalid}
                ref={this.state.questionAnswerMapCaretakenPerson["country"].ref}
                required
            />
        );
        renderedQuestions.push(<DropdownField
                name={"nationality"}
                setAnswer={(question, answer) => this.setAnswerCaretakenPerson("nationality", convertToAPICountry(answer))}
                question={this.state.questionAnswerMapCaretakenPerson["nationality"].question}
                value={convertToLocalizedCountry(this.state.questionAnswerMapCaretakenPerson["nationality"].answer)}
                invalid={this.state.questionAnswerMapCaretakenPerson["nationality"].invalid}
                ref={this.state.questionAnswerMapCaretakenPerson["nationality"].ref}
                required
            />
        );
        renderedQuestions.push(<MultiDropdownField name={"languages"}
                                                   setAnswer={(question, answer) => this.setAnswerCaretakenPerson("languages", answer)}
                                                   question={this.state.questionAnswerMapCaretakenPerson["languages"].question}
                                                   value={this.state.questionAnswerMapCaretakenPerson["languages"].answer}
                                                   invalid={this.state.questionAnswerMapCaretakenPerson["languages"].invalid}
                                                   ref={this.state.questionAnswerMapCaretakenPerson["languages"].ref}
                                                   required
            />
        );
        renderedQuestions.push(
            <DateField name={"birthDate"}
                       setAnswer={(quesiton, answer) => this.setAnswerCaretakenPerson("birthDate", answer)}
                       question={this.state.questionAnswerMapCaretakenPerson["birthDate"].question}
                       value={this.state.questionAnswerMapCaretakenPerson["birthDate"].answer}
                       invalid={this.state.questionAnswerMapCaretakenPerson["birthDate"].invalid}
                       ref={this.state.questionAnswerMapCaretakenPerson["birthDate"].ref}
            />
        );
        renderedQuestions.push(
            <QuestionTextField name={"phoneNumber"}
                               setAnswer={(quesiton, answer) => this.setAnswerCaretakenPerson("phoneNumber", answer)}
                               question={this.state.questionAnswerMapCaretakenPerson["phoneNumber"].question}
                               value={this.state.questionAnswerMapCaretakenPerson["phoneNumber"].answer}
                               invalid={this.state.questionAnswerMapCaretakenPerson["phoneNumber"].invalid}
                               ref={this.state.questionAnswerMapCaretakenPerson["phoneNumber"].ref}
                               required
            />
        );
        renderedQuestions.push(
            <QuestionTextField name={"mobileNumber"}
                               setAnswer={(quesiton, answer) => this.setAnswerCaretakenPerson("mobileNumber", answer)}
                               question={this.state.questionAnswerMapCaretakenPerson["mobileNumber"].question}
                               value={this.state.questionAnswerMapCaretakenPerson["mobileNumber"].answer}
                               invalid={this.state.questionAnswerMapCaretakenPerson["mobileNumber"].invalid}
                               ref={this.state.questionAnswerMapCaretakenPerson["mobileNumber"].ref}
                               required
            />
        );

        return (
            <div style={{marginBottom: 60}}>
                <h5 style={{marginBottom: 0}}>Zu betreuende Person</h5>
                <p style={{marginBottom: 20}}><small>Mit * gekennzeichnete Felder sind Pflichtfelder</small></p>
                {renderedQuestions}
            </div>
        );
    }

    renderQuestionsCaretakenPerson() {
        if (this.props.registeringOther) {
            let renderedQuestions = [];

            renderedQuestions.push(
                <DropdownField
                    name={"gender"}
                    setAnswer={(question, answer) => this.setAnswerContactPerson("gender", convertToAPIGender(answer))}
                    question={this.state.questionAnswerMapContactPerson["gender"].question}
                    value={convertToLocalizedGender(this.state.questionAnswerMapContactPerson["gender"].answer)}
                    invalid={this.state.questionAnswerMapContactPerson["gender"].invalid}
                    ref={this.state.questionAnswerMapContactPerson["gender"].ref}
                    required
                />
            );
            renderedQuestions.push(
                <QuestionTextField
                    name={"firstName"}
                    setAnswer={(question, answer) => this.setAnswerContactPerson("firstName", answer)}
                    question={this.state.questionAnswerMapContactPerson["firstName"].question}
                    value={this.state.questionAnswerMapContactPerson["firstName"].answer}
                    invalid={this.state.questionAnswerMapContactPerson["firstName"].invalid}
                    ref={this.state.questionAnswerMapContactPerson["firstName"].ref}
                    required
                />
            );
            renderedQuestions.push(
                <QuestionTextField name={"lastName"}
                                   setAnswer={(question, answer) => this.setAnswerContactPerson("lastName", answer)}
                                   question={this.state.questionAnswerMapContactPerson["lastName"].question}
                                   value={this.state.questionAnswerMapContactPerson["lastName"].answer}
                                   invalid={this.state.questionAnswerMapContactPerson["lastName"].invalid}
                                   ref={this.state.questionAnswerMapContactPerson["lastName"].ref}
                                   required
                />
            );
            renderedQuestions.push(
                <QuestionTextField name={"streetAndNr"}
                                   setAnswer={(quesiton, answer) => this.setAnswerContactPerson("streetAndNr", answer)}
                                   question={this.state.questionAnswerMapContactPerson["streetAndNr"].question}
                                   value={this.state.questionAnswerMapContactPerson["streetAndNr"].answer}
                                   invalid={this.state.questionAnswerMapContactPerson["streetAndNr"].invalid}
                                   ref={this.state.questionAnswerMapContactPerson["streetAndNr"].ref}
                                   required
                />
            );
            renderedQuestions.push(
                <Row>
                    <Col xs={3}>
                        <QuestionTextField name={"zipcode"}
                                           setAnswer={(quesiton, answer) => this.setAnswerContactPerson("zipcode", answer)}
                                           question={this.state.questionAnswerMapContactPerson["zipcode"].question}
                                           value={this.state.questionAnswerMapContactPerson["zipcode"].answer}
                                           invalid={this.state.questionAnswerMapContactPerson["zipcode"].invalid}
                                           ref={this.state.questionAnswerMapContactPerson["zipcode"].ref}
                                           required
                        />
                    </Col>
                    <Col xs={5}>
                        <QuestionTextField name={"city"}
                                           setAnswer={(quesiton, answer) => this.setAnswerContactPerson("city", answer)}
                                           question={this.state.questionAnswerMapContactPerson["city"].question}
                                           value={this.state.questionAnswerMapContactPerson["city"].answer}
                                           invalid={this.state.questionAnswerMapContactPerson["city"].invalid}
                                           ref={this.state.questionAnswerMapContactPerson["city"].ref}
                                           required
                        />
                    </Col>
                </Row>);
            renderedQuestions.push(
                <DropdownField name={"country"}
                               setAnswer={(quesiton, answer) => this.setAnswerContactPerson("country", convertToAPICountry(answer))}
                               question={this.state.questionAnswerMapContactPerson["country"].question}
                               value={convertToLocalizedCountry(this.state.questionAnswerMapContactPerson["country"].answer)}
                               invalid={this.state.questionAnswerMapContactPerson["country"].invalid}
                               ref={this.state.questionAnswerMapContactPerson["country"].ref}
                               required
                />
            );
            renderedQuestions.push(<DropdownField name={"nationality"}
                                                  setAnswer={(question, answer) => this.setAnswerContactPerson("nationality", convertToAPICountry(answer))}
                                                  question={this.state.questionAnswerMapContactPerson["nationality"].question}
                                                  value={convertToLocalizedCountry(this.state.questionAnswerMapContactPerson["nationality"].answer)}
                                                  invalid={this.state.questionAnswerMapContactPerson["nationality"].invalid}
                                                  ref={this.state.questionAnswerMapContactPerson["nationality"].ref}
                                                  required
                />
            );
            renderedQuestions.push(<MultiDropdownField name={"languages"}
                                                       setAnswer={(question, answer) => this.setAnswerContactPerson("languages", answer)}
                                                       question={this.state.questionAnswerMapContactPerson["languages"].question}
                                                       value={this.state.questionAnswerMapContactPerson["languages"].answer}
                                                       invalid={this.state.questionAnswerMapContactPerson["languages"].invalid}
                                                       ref={this.state.questionAnswerMapContactPerson["languages"].ref}
                                                       required
            />);
            renderedQuestions.push(
                <DateField name={"birthDate"}
                           setAnswer={(quesiton, answer) => this.setAnswerContactPerson("birthDate", answer)}
                           question={this.state.questionAnswerMapContactPerson["birthDate"].question}
                           value={this.state.questionAnswerMapContactPerson["birthDate"].answer}
                           invalid={this.state.questionAnswerMapContactPerson["birthDate"].invalid}
                           ref={this.state.questionAnswerMapContactPerson["birthDate"].ref}
                />
            );
            renderedQuestions.push(
                <QuestionTextField name={"phoneNumber"}
                                   setAnswer={(quesiton, answer) => this.setAnswerContactPerson("phoneNumber", answer)}
                                   question={this.state.questionAnswerMapContactPerson["phoneNumber"].question}
                                   value={this.state.questionAnswerMapContactPerson["phoneNumber"].answer}
                                   invalid={this.state.questionAnswerMapContactPerson["phoneNumber"].invalid}
                                   ref={this.state.questionAnswerMapContactPerson["phoneNumber"].ref}
                                   required
                />
            );
            renderedQuestions.push(
                <QuestionTextField name={"mobileNumber"}
                                   setAnswer={(quesiton, answer) => this.setAnswerContactPerson("mobileNumber", answer)}
                                   question={this.state.questionAnswerMapContactPerson["mobileNumber"].question}
                                   value={this.state.questionAnswerMapContactPerson["mobileNumber"].answer}
                                   invalid={this.state.questionAnswerMapContactPerson["mobileNumber"].invalid}
                                   ref={this.state.questionAnswerMapContactPerson["mobileNumber"].ref}
                                   required
                />
            );

            return (
                <div style={{marginBottom: 60}}>
                    <h5 style={{marginBottom: 0}}>Kontaktperson</h5>
                    <p style={{marginBottom: 20}}><small>Mit * gekennzeichnete Felder sind Pflichtfelder</small></p>
                    {renderedQuestions}
                </div>
            );
        }

        return ("");
    }

    render() {
        return (
            <div>
                {this.renderCaregiverRegistrationProfileForm()}
            </div>
        );
    }

    countries = this._getCountries();

    /**
     * @returns {QuestionChoice[]}
     * @private
     */
    _getCountries() {
        return Country.values().map((country, index) => new QuestionChoice(index + 1, [{text: country.label}]));
    }
}

export default ClientRegistrationProfileForm;
