import React from 'react';
import PropTypes from 'prop-types';

export default function BoxRow({children, hidden}) {
    return (
        <div className="m-0" hidden={!!hidden}>{children || <div>&nbsp;</div>}</div>
    );
}

BoxRow.propTypes = {
    hidden: PropTypes.bool
}