import AbstractModel from "./AbstractModel";

export default class Local extends AbstractModel {
    constructor(id, text, localization) {
        super();

        this._id = id;
        this._text = text;
        this._localization = localization
    }

    get id() {
        return this._id;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    get localization() {
        return this._localization;
    }

    set localization(value) {
        this._localization = value;
    }

    toJSON() {
        return ({
            id: this._id,
            text: this._text,
            localization: this._localization
        })
    }

    /**
     * @returns {Local}
     */
    clone() {
        return this._clone(new Local());
    }
}
