import AbstractModel from "./AbstractModel";

export default class Client extends AbstractModel {

    constructor(id, userInfo, clientProfiles, clientScores, clientContacts, languages) {
        super();

        this._id = id;
        this._userInfo = userInfo;
        this._clientProfiles = clientProfiles;
        this._clientScores = clientScores;
        this._clientContacts = clientContacts;
        this._languages = languages;
    }

    get id() {
        return this._id;
    }

    get userInfo() {
        return this._userInfo;
    }

    set userInfo(value) {
        this._userInfo = value;
    }

    get clientProfiles() {
        return this._clientProfiles;
    }

    set clientProfiles(value) {
        this._clientProfiles = value;
    }

    get clientScores() {
        return this._clientScores;
    }

    set clientScores(value) {
        this._clientScores = value;
    }

    get clientContacts() {
        return this._clientContacts;
    }

    set clientContacts(value) {
        this._clientContacts = value;
    }

    set languages(value) {
        this._languages = value;
    }

    get languages() {
        return this._languages;
    }

    toJSON() {
        let clientProfiles = [];
        if (this._clientProfiles !== null) {
            for (let declarationAnswer of this._clientProfiles) {
                clientProfiles.push(declarationAnswer.toJSON());
            }
        }

        let clientScores = [];
        if (this._clientScores !== null) {
            for (let clientScore of this._clientScores) {
                clientScores.push(clientScore.toJSON());
            }
        }

        return {
            id: this._id,
            userInfo: this._userInfo ? this._userInfo.toJSON() : "",
            clientProfiles: clientProfiles,
            clientScores: clientScores,
            clientContacts: this._clientContacts,
            languages: this._languages
        }
    }

    /**
     * @returns {Client}
     */
    clone() {
        return this._clone(new Client());
    }
}
