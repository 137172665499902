import {FormControlLabel, Radio, RadioGroup, TextareaAutosize} from "@material-ui/core";
import {v5 as uuid} from "uuid";
import React from "react";
import AbstractField from "./AbstractField";
import PropTypes from "prop-types";

export default class BooleanField extends AbstractField {
    /**
     * @type {string}
     */
    static GROUP_NAMESPACE_UUID = 'c641529d-a541-4a8f-831f-95f9843b69f3';

    /**
     * @returns {boolean}
     */
    get optionalTextField() {
        return !!this.props.optionalTextField;
    }

    /**
     * @returns {string}
     */
    get group() {
        return uuid(this.name, BooleanField.GROUP_NAMESPACE_UUID)
    }

    /**
     * @returns {string}
     */
    get yes() {
        return this.props.yes ?? this.t('yes');
    }

    /**
     * @returns {string}
     */
    get no() {
        return this.props.no ?? this.t('no');
    }

    /**
     * @returns {string}
     */
    get placeholder() {
        // noinspection JSValidateTypes
        return this.props.placeholder ?? this.t('pleaseEnterText');
    }

    renderField({field, form, meta}) {
        const handleChange = (event, value) => {
            const trueValue = !!this.optionalTextField ? '' : true;
            const falseValue = !!this.optionalTextField ? null : false;

            form.setFieldValue(this.name, JSON.parse(value) ? trueValue : falseValue);
            form.setFieldTouched(this.name, true, true);
        };

        const value = field.value;
        const boolValue = !!this.optionalTextField ? (value !== null && value !== undefined) : !!value;

        const yes = (<Radio className="py-0"/>);
        const no = (<Radio className="py-0"/>);

        return (
            <>
                <RadioGroup id={this.id} name={this.group} value={boolValue} onChange={handleChange} row={true}>
                    <FormControlLabel value={true}
                                      control={yes}
                                      label={this.yes}
                                      className="my-0"
                                      disabled={this.disabled(field.value)}/>
                    <FormControlLabel value={false}
                                      control={no}
                                      label={this.no}
                                      className="my-0"
                                      disabled={this.disabled(field.value)}/>
                </RadioGroup>
                {this.optionalTextField && boolValue && (
                    <TextareaAutosize name={this.name}
                                      autoComplete="new-password"
                                      placeholder={this.placeholder}
                                      onBlur={form.handleBlur}
                                      onChange={form.handleChange}
                                      value={value}
                                      minRows={3}
                                      className="mt-3 w-100"
                                      disabled={this.disabled(field.value)}/>
                )}
            </>
        )
    }
}

BooleanField.propTypes = {
    ...AbstractField.propTypes,
    optionalTextField: PropTypes.bool,
    yes: PropTypes.string,
    no: PropTypes.string,
    placeholder: PropTypes.string
};