import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import ApiService from '../../services/ApiService';
import keycloak from '../../keycloak';
import Password from '../../models/Password';
import {formatBirthDate} from '../../util/helpers';
import Section from '../form/Section';
import Box from '../form/Box';
import BoxRow from '../form/BoxRow';
import EnumData from '../form/data/EnumData';
import Data from '../form/data/Data';
import FieldSet from '../form/Fieldset';
import SubSection from '../form/SubSection';
import Form from '../form/Form';
import Salutation from '../../enums/Salutation';
import PasswordInputField from '../form/PasswordInputField';
import Submit from '../form/Submit';
import Success from '../form/Success';
import Failed from '../form/Failed';

/** @typedef {import('formik').FormikValues} FormikValues */
/** @typedef {import('formik').FormikErrors} FormikErrors */

export default class DashboardPassword extends React.Component {
    /**
     * @type {number}
     */
    static PASSWORD_MIN_LENGTH = 4;
    /**
     * @type {RegExp}
     */
    static PASSWORD_POLICY_REGEX = new RegExp(`^.{${DashboardPassword.PASSWORD_MIN_LENGTH},}$`); // Be aware, that you have to change this policy in the backend and keycloak as well.

    /**
     * @returns {UserInfo}
     * @private
     */
    get _userInfo() {
        return this.props.userInfo;
    }

    /**
     * @returns {{userInfo: UserInfo, password: string, passwordRepeat: string}}
     * @private
     */
    get _initialValues() {
        return {
            userInfo: this._userInfo.clone(),
            password: '',
            passwordRepeat: ''
        };
    }

    render = () => (
        <Form onLoad={this._onLoad}
              onSubmit={this._onSubmit}
              initialValues={this._initialValues}
              onValidate={this._onValidate}>
            {({t}) => (
                <>
                    <Section>{t('myProfile')} - {t('changePassword')}</Section>
                    <FieldSet>
                        <Box>
                            <BoxRow><EnumData hidden={v => v === Salutation.OTHER.id}
                                              enum={Salutation}
                                              name="userInfo.gender"/></BoxRow>
                            <BoxRow><Data name="userInfo.firstName"/> <Data name="userInfo.lastName"/></BoxRow>
                            <BoxRow><Data name="userInfo.birthDate" formatter={formatBirthDate}/></BoxRow>
                        </Box>
                    </FieldSet>
                    <SubSection>{t('setNewPassword')}</SubSection>
                    <FieldSet>
                        <PasswordInputField label={t('newPassword')} name="password"/>
                        <PasswordInputField label={t('newPasswordRepeat')} name="passwordRepeat"/>
                    </FieldSet>
                    <Submit>{t('confirm')}</Submit>
                    <Success>{t('passwordChangeSuccess')}</Success>
                    <Failed/>
                </>
            )}
        </Form>
    )

    /**
     * @param {FormikValues} values
     * @param {FormikErrors} existingErrors
     * @returns {{}}
     * @private
     */
    _onValidate = (values, existingErrors) => {
        const errors = {...existingErrors};

        // TODO: may perform password check in backend using POST /user/password
        if (!DashboardPassword.PASSWORD_POLICY_REGEX.test(values.password)) {
            errors.password = i18next.t('form.validation.invalidPasswordMinLength', {minLength: DashboardPassword.PASSWORD_MIN_LENGTH});
        }

        if (values.password !== values.passwordRepeat) {
            errors.passwordRepeat = i18next.t('form.validation.invalidPasswordRepeat');
        }

        return errors;
    }

    _onLoad = () => {
    }

    /**
     * @param {FormikValues} values
     * @returns {Promise<void>}
     * @private
     */
    _onSubmit = (values) => {
        const password = new Password(values.password)

        return ApiService.changePassword(password, keycloak)
    }
}

DashboardPassword.propTypes = {
    userInfo: PropTypes.object.isRequired
};