import React from 'react';
import keycloak from '../../keycloak';
import ApiService from '../../services/ApiService';
import PropTypes from 'prop-types';
import {formatBirthDate} from '../../util/helpers';
import Form from '../form/Form';
import TextInputField from '../form/TextInputField';
import Submit from '../form/Submit';
import Section from '../form/Section';
import Box from '../form/Box';
import BoxRow from '../form/BoxRow';
import Salutation from '../../enums/Salutation';
import DropdownField from '../form/DropdownField';
import Country from '../../enums/Country';
import Success from '../form/Success';
import Failed from '../form/Failed';
import PhoneNumberField from "../form/PhoneNumberField";
import EnumData from "../form/data/EnumData";
import Data from "../form/data/Data";

/** @typedef {import('../../models/Client').default} Client */
/** @typedef {import('../../models/Caregiver').default} Caregiver */

export default class DashboardProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
        };
    }

    /**
     * @returns {number}
     * @private
     */
    get _userId() {
        return this.props.userId;
    }

    /**
     * @returns {boolean}
     * @private
     */
    get _isClient() {
        return this.props.isClient;
    }

    /**
     * @returns {Client|Caregiver|null}
     * @private
     */
    get _user() {
        return this.state.user;
    }

    get _initialValues() {
        return this._user?.userInfo.clone().toJSON() ?? {};
    }

    /**
     * @param {Client|Caregiver} user
     * @private
     */
    _storeUserState = (user) => {
        this.setState({
            user: user
        });
    }

    _onLoad = () => {
        const getFunc = this._isClient ? ApiService.getClientFromId : ApiService.getCaregiver;

        return getFunc(this._userId, keycloak).then(this._storeUserState);
    }

    /**
     * @param {UserInfo} userInfo
     * @private
     */
    _onSubmit = (userInfo) => {
        const updateFunc = this._isClient ? ApiService.updateClient : ApiService.updateCaregiver;
        const tempUser = this._user.clone().toJSON();

        tempUser.userInfo = userInfo;

        return updateFunc(tempUser, keycloak).then(this._storeUserState);
    }

    render() {
        const salutation = Salutation.valueOf(this._user?.userInfo.gender);

        return (
            <Form onLoad={this._onLoad} onSubmit={this._onSubmit} initialValues={this._initialValues}>
                {({t}) => (
                    <>
                        <Section>{t('myProfile')} - {t('address')}</Section>
                        <Box>
                            <BoxRow hidden={salutation === Salutation.OTHER}><EnumData enum={Salutation} name="gender"/></BoxRow>
                            <BoxRow><Data name="firstName"/> <Data name="lastName"/></BoxRow>
                            <BoxRow><Data name="birthDate" formatter={formatBirthDate}/></BoxRow>
                        </Box>
                        <TextInputField label={t('street')} name="streetAndNr" required={true}/>
                        <TextInputField label={t('zip')} name="zipcode" required={true}/>
                        <TextInputField label={t('city')} name="city" required={true}/>
                        <DropdownField label={t('country')} name="country" required={true} enum={Country}/>
                        <PhoneNumberField label={t('phoneNumber')} name="phoneNumber" required={true}/>
                        <PhoneNumberField label={t('mobileNumber')} name="mobileNumber" required={true}/>
                        <Submit>{t('confirm')}</Submit>
                        <Success/>
                        <Failed/>
                    </>
                )}
            </Form>
        );
    }
}

DashboardProfile.propTypes = {
    userId: PropTypes.number.isRequired,
    isClient: PropTypes.bool.isRequired
};
