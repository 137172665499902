/**
 * The code in this file is generated using the JavaScriptModelGenerator.java
 * No manual changes recommended, change the model in the backend instead.
 */
import AbstractModel from './AbstractModel';

export default class Email extends AbstractModel {
    /**
     * @type{string}
     * @private
     */
    _email = undefined;
    /**
     * @type{string}
     * @private
     */
    _verifyBaseUrl = undefined;

    /**
     * @param {string} [email]
     * @param {string} [verifyBaseUrl]
     */
    constructor(
        email,
        verifyBaseUrl
    ) {
        super();

        this._email = email;
        this._verifyBaseUrl = verifyBaseUrl;
    }

    /**
     * @returns {string}
     */
    get email() {
        return this._email;
    }

    /**
     * @param {string} email
     */
    set email(email) {
        this._email = email;
    }

    /**
     * @returns {string}
     */
    get verifyBaseUrl() {
        return this._verifyBaseUrl;
    }

    /**
     * @param {string} verifyBaseUrl
     */
    set verifyBaseUrl(verifyBaseUrl) {
        this._verifyBaseUrl = verifyBaseUrl;
    }

    toJSON() {
        return {
            email: this._email,
            verifyBaseUrl: this._verifyBaseUrl
        };
    }

    /**
     * @returns {Email}
     */
    clone() {
        return this._clone(new Email());
    }

    /**
     * @returns {Email}
     */
    static fromJSON(json) {
        return json ? new Email(
            json?.email,
            json?.verifyBaseUrl
        ) : undefined;
    }
}