import React from "react";
import "../../views/DashboardView/DashboardView.css";
import keycloak from "../../keycloak";
import ApiService from "../../services/ApiService";
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import Salutation from "../../enums/Salutation";
import {formatBirthDate} from "../../util/helpers";
import UserInfoDetail from "../../models/UserInfoDetail";
import UserInfo from "../../models/UserInfo";
import DateField from "../form/DateField";
import Form from "../form/Form";
import Submit from "../form/Submit";
import Success from "../form/Success";
import Failed from "../form/Failed";
import TextInputField from "../form/TextInputField";
import Country from "../../enums/Country";
import DropdownField from "../form/DropdownField";
import PhoneNumberField from "../form/PhoneNumberField";
import Section from "../form/Section";
import Box from "../form/Box";
import BoxRow from "../form/BoxRow";
import ResidencePermit from "../../enums/ResidencePermit";
import CivilStatus from "../../enums/CivilStatus";
import FieldSet from "../form/Fieldset";
import SubSection from "../form/SubSection";
import {FieldArray} from "formik";
import UserInfoChild from "../../models/UserInfoChild";
import Button from "../form/Button";
import BooleanField from "../form/BooleanField";
import TextareaField from "../form/TextareaField";
import Data from "../form/data/Data";
import EnumData from "../form/data/EnumData";
import EmailInputField from "../form/EmailInputField";
import SocialSecurityNumberField from "../form/SocialSecurityNumberField";
import BankNumberField from "../form/BankNumberField";

/** @typedef {import('i18next').TFunction} TFunction */
/** @typedef {import('formik').FormikProps} FormikProps */
/** @typedef {import('formik').FormikValues} FormikValues */
/** @typedef {import('formik').FormikHelpers} FormikHelpers */

/** @typedef {import('formik').ArrayHelpers} ArrayHelpers */

/** @typedef {import('../../models/Caregiver').default} Caregiver */

class DashboardMagazine extends React.Component {
    state = {
        caregiver: null,
        tempCaregiver: null,
        currentEmail: null
    };

    /**
     * @returns {number}
     * @private
     */
    get _userId() {
        return this.props.userId;
    }

    /**
     * @returns {Caregiver|null}
     * @private
     */
    get _caregiver() {
        return this.state.caregiver;
    }

    /**
     * @returns {UserInfo|null}
     * @private
     */
    get _userInfo() {
        return this._caregiver?.userInfo;
    }

    /**
     * @returns {string}
     * @private
     */
    get _currentEmail() {
        return this.state.currentEmail;
    }

    /**
     * @returns {{}}
     * @private
     */
    get _initialValues() {
        /**
         * @type {UserInfo}
         */
        const userInfo = this._userInfo?.clone() ?? new UserInfo();

        userInfo.details = userInfo.details ?? new UserInfoDetail();
        userInfo.birthDate = userInfo.birthDate ? new Date(userInfo.birthDate) : undefined; // FIXME: remove when userInfo.birthDate returns date object

        return {
            clone: userInfo.toJSON(),
            ...userInfo.toJSON()
        };
    }

    /**
     * @param {UserInfo} userInfo
     * @private
     */
    _updateCaregiver = (userInfo) => {
        if (userInfo.email !== this._currentEmail) {
            window.location.reload();
            return;
        }

        const tempCaregiver = this._caregiver.clone();

        tempCaregiver.userInfo = userInfo;

        this.setState({
            caregiver: tempCaregiver,
            currentEmail: userInfo.email
        });
    }

    /**
     * @param {Caregiver} caregiver
     * @private
     */
    _storeCaregiver = (caregiver) => {
        this.setState({
            loading: false,
            caregiver: caregiver,
            currentEmail: caregiver.userInfo.email
        });
    }

    /**
     * @param {UserInfo} userInfo
     * @returns {Promise<void>}
     * @private
     */
    _onSubmit = (userInfo) => {
        return ApiService.updateProfile(userInfo, keycloak)
            .then(this._updateCaregiver)
    };

    /**
     * @private
     */
    _onLoad = () => {
        return ApiService.getCaregiver(this._userId, keycloak)
            .then(this._storeCaregiver.bind(this));
    };

    /**
     * @param {ArrayHelpers} arrayHelpers
     * @param {UserInfoChild} child
     * @param {number} index
     * @param {TFunction} t
     * @private
     */
    _renderChildForm = (arrayHelpers, child, index, t) => {
        const namePrefix = `details.children[${index}]`;

        return (
            <div key={index} className={index > 0 ? 'mt-3' : ''}>
                <TextInputField label={t('lastName')} name={`${namePrefix}.lastName`} required={true}/>
                <TextInputField label={t('firstName')} name={`${namePrefix}.firstName`} required={true}/>
                <DateField label={t('birthDate')} name={`${namePrefix}.birthDate`} required={true}/>
                <Button onClick={() => arrayHelpers.remove(index)}>{t('remove')}</Button>
            </div>
        );
    }

    /**
     * @returns {JSX.Element}
     * @private
     */
    render = () => (
        <Form onLoad={this._onLoad} onSubmit={this._onSubmit} initialValues={this._initialValues}>
            {({formik, t}) => (
                <>
                    <Section>{t('myProfile')} - {t('personalData')}</Section>
                    <Box>
                        <BoxRow><EnumData hidden={v => v === Salutation.OTHER.id}
                                          enum={Salutation}
                                          name="clone.gender"/></BoxRow>
                        <BoxRow><Data name="clone.firstName"/> <Data name="clone.lastName"/></BoxRow>
                        <BoxRow><Data name="clone.birthDate" formatter={formatBirthDate}/></BoxRow>
                    </Box>
                    <FieldSet>
                        <DateField label={t('employmentStartDate')}
                                   name="details.employmentStartDate"
                                   disabled={true}
                                   hidden={v => !v}/>
                        <TextInputField label={t('lastName')} name="lastName" required={true}/>
                        <TextInputField label={t('firstName')} name="firstName" required={true}/>
                        <TextInputField label={t('street')} name="streetAndNr" required={true}/>
                        <TextInputField label={t('zip')} name="zipcode" required={true}/>
                        <TextInputField label={t('city')} name="city" required={true}/>
                        <DropdownField label={t('country')} name="country" required={true} enum={Country}/>
                        <DateField label={t('birthDate')} name="birthDate" required={true}/>
                        <DropdownField label={t('nationality')} name="nationality" required={true} enum={Country}/>
                        <DropdownField label={t('residencePermit')}
                                       name="details.residencePermit"
                                       required={true}
                                       enum={ResidencePermit}/>
                        <DropdownField label={t('civilStatus')}
                                       name="details.civilStatus"
                                       required={true}
                                       enum={CivilStatus}/>
                        <PhoneNumberField label={t('phoneNumber')} name="phoneNumber" required={true}/>
                        <PhoneNumberField label={t('mobileNumber')} name="mobileNumber" required={true}/>
                        <EmailInputField label={t('email')} name="email" required={true} disabled={true}/>
                        <SocialSecurityNumberField label={t('socialSecurityNumber')}
                                                   name="details.socialSecurityNumber"
                                                   required={false}/>
                        <BankNumberField label={t('bankAccountNumber')}
                                         name="details.bankAccountNumber"
                                         required={false}/>
                    </FieldSet>
                    <SubSection>{t('childBenefits')}</SubSection>
                    <FieldSet>
                        <FieldArray name="details.children">
                            {arrayHelpers => (
                                <>
                                    {formik.values.details.children.map((child, index) => this._renderChildForm(arrayHelpers, child, index, t))}
                                    <Button onClick={() => arrayHelpers.push(new UserInfoChild().toJSON())}>
                                        {t('addChild')}
                                    </Button>
                                </>
                            )}
                        </FieldArray>
                    </FieldSet>
                    <SubSection>{t('additionalInformation')}</SubSection>
                    <FieldSet>
                        <BooleanField label={t('withholdingTax')} name="details.withholdingTax"/>
                        <BooleanField label={t('otherEmployers')}
                                      name="details.otherEmployers"
                                      optionalTextField={true}/>
                        <BooleanField label={t('additionalIncomeUnemploymentInsurance')}
                                      name="details.additionalIncomeUnemploymentInsurance"/>
                        <BooleanField label={t('disabilityAndSocialInsuranceDeductions')}
                                      name="details.disabilityAndSocialInsuranceDeductions"/>
                        <DateField label={t('employmentEndDate')}
                                   name="details.employmentEndDate"
                                   disabled={true}
                                   hidden={v => !v}/>
                        <TextareaField label={t('notice')} name="details.notice"/>
                    </FieldSet>
                    <Submit>{t('confirm')}</Submit>
                    <Success/>
                    <Failed/>
                </>
            )}
        </Form>
    )
}

DashboardMagazine.propTypes = {
    userId: PropTypes.number.isRequired
};

export default withTranslation()(DashboardMagazine);