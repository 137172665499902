export default class APIResponseError extends Error {
    /**
     * @type {Response}
     * @private
     */
    _response = undefined;

    /**
     * @param {Response} response
     */
    constructor(response) {
        super(`${response.status} ${response.statusText}`);

        this._response = response;
    }

    /**
     * @returns {Response}
     */
    get response() {
        return this._response;
    }
}